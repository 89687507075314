import React, { FunctionComponent, ReactNode } from "react";
import classNames from "classnames";
import { mapToCssModules } from "./utils";
import { InnerRef, CSSModule, TagPropType } from "./types";

export interface AccordionItemPropTypes {
  children?: ReactNode;
  /** To add custom class */
  className?: string;
  /** Change existing base class name with a new class name */
  cssModule?: CSSModule;
  innerRef?: InnerRef;
  /** Set a custom element for this component */
  tag?: TagPropType;

  testId?: string;

  [key: string]: any;
}

export const AccordionItem: FunctionComponent<AccordionItemPropTypes> = (
  props
) => {
  const {
    className,
    cssModule,
    tag: Tag = "div",
    innerRef,
    testId = props["data-testid"] || props.id,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(className, "accordion-item"),
    cssModule
  );

  return (
    <>
      {Tag == "li" ? (
        <li data-testid={testId} {...attributes} className={classes} />
      ) : (
        <div data-testid={testId} {...attributes} className={classes} />
      )}
    </>
  );
};

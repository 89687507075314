import React, { FunctionComponent, ReactNode } from "react";
import { JSONDiff, JSONDiffWrapper, useJSONDiff } from "../../JSONDiff";
import { getByDottedDiff } from "../../../common/utils/jsonDiff";
import { reportTypeCodeMap } from "../../../common/constants";
import { isEmptyHTML } from "../../../common/utils/IsEmptyHTML";
import getByDot from "lodash/get";
import { format } from "date-fns";
import _ from "lodash";

export interface DiffList {
  [section: string]: {
    display: string;
    type?: "section";
    hide?: boolean;
    items: {
      path?: string;
      paths?: string[];
      display: string;
      hide?: boolean;
      seq?: number;
      transform?: (
        diffData?: any,
        prevData?: any,
        currData?: any
      ) => {
        __new: any;
        __old: any;
      };
      wrapper?: FunctionComponent<{ children: ReactNode }>;
    }[];
  };
}

function transformBoolMapper(diffData: any, path: string) {
  const { __new: newData, __old: oldData } = getByDottedDiff(diffData, path);

  const boolMap = {
    true: "Yes",
    false: "No",
  };

  return {
    // @ts-ignore
    __new: newData != null ? boolMap[String(newData)] : null,
    // @ts-ignore
    __old: oldData != null ? boolMap[String(oldData)] : null,
  };
}

const diffList: DiffList = {
  schedulerDetails: {
    display: "Scheduler Details",
    type: "section",
    items: [
      {
        path: "title",
        display: "Title",
        seq: 1,
      },
      {
        path: "startDate",
        display: "Start Date",
        seq: 2,
      },
      {
        path: "endDate",
        display: "End Date",
        seq: 3,
      },
      {
        paths: ["runTime", "startDate"],
        display: "Run Time",
        seq: 4,
        transform(diffData, prevData, currData) {
          const [runTimePath, startDatePath] = this.paths!;

          // start date
          let { __new: startDate__new, __old: startDate__old } =
            getByDottedDiff(diffData, startDatePath);

          startDate__new =
            startDate__new || getByDot(currData, startDatePath) || "N/A";
          startDate__old =
            startDate__old || getByDot(prevData, startDatePath) || "N/A";

          let { __new: runTime__new, __old: runTime__old } = getByDottedDiff(
            diffData,
            runTimePath
          );

          const startDateTime__old =
            startDate__old == null || runTime__old == null
              ? undefined
              : new Date(`${startDate__old}T${runTime__old}Z`);

          const startDateTime__new =
            startDate__new == null || runTime__new == null
              ? undefined
              : new Date(`${startDate__new}T${runTime__new}Z`);

          return {
            __new:
              startDateTime__new == null
                ? "N/A"
                : format(startDateTime__new, "HH:mm"),
            __old:
              startDateTime__old == null
                ? "N/A"
                : format(startDateTime__old, "HH:mm"),
          };
        },
      },
      {
        path: "reportPeriod.FREQ",
        display: "Report Period",
        seq: 5,
      },
      {
        path: "omitReport",
        display: "Omit If No Record",
        seq: 6,
        transform(diffData) {
          const path = this.path!;
          return transformBoolMapper(diffData, path);
        },
      },
    ],
  },
  customReportDetails: {
    display: "Custom Report Details",
    type: "section",
    items: [
      {
        display: "Frequency",
        seq: 1,
        path: "reportDetails.filters.frequency",
      },
      {
        path: "reportDetails.filters.vendors",
        display: "Vendor",
        seq: 4,
      },
      {
        seq: 5,
        display: "Date Range",
        paths: [
          "reportDetails.filters.startDate",
          "reportDetails.filters.endDate",
        ],
        transform(diffData, prevData, currData) {
          const [startDatePath, endDatePath] = this.paths!;

          const NA = "N/A";
          let isDateChanged = false;

          // start date
          let { __new: startDate__new, __old: startDate__old } =
            getByDottedDiff(diffData, startDatePath);

          // If start date has changed
          if (startDate__new || startDate__old) {
            isDateChanged = true;
          }

          startDate__new =
            startDate__new || getByDot(currData, startDatePath) || NA;
          startDate__old =
            startDate__old || getByDot(prevData, startDatePath) || NA;

          // end date
          let { __new: endDate__new, __old: endDate__old } = getByDottedDiff(
            diffData,
            endDatePath
          );

          // If end date has changed
          if (endDate__new || endDate__old) {
            isDateChanged = true;
          }

          endDate__new = endDate__new || getByDot(currData, endDatePath) || NA;
          endDate__old = endDate__old || getByDot(prevData, endDatePath) || NA;

          if (!isDateChanged) {
            return {
              __new: undefined,
              __old: undefined,
            };
          }

          return {
            __new:
              startDate__new === NA && startDate__new === endDate__new
                ? NA
                : `${startDate__new} ~ ${endDate__new}`,
            __old:
              startDate__old === NA && startDate__old === endDate__old
                ? NA
                : `${startDate__old} ~ ${endDate__old}`,
          };
        },
      },
      {
        path: "reportDetails.filters.classifications",
        display: "Classification",
        seq: 6,
      },
      {
        path: "reportDetails.filters.accreditations",
        display: "Accreditation",
        seq: 7,
      },
      {
        path: "reportDetails.filters.programs",
        display: "Program",
        seq: 9,
      },
      {
        path: "reportDetails.filters.certificates",
        display: "Certificate",
        seq: 10,
      },
      {
        path: "reportDetails.filters.errorType",
        display: "Error Type",
        seq: 11,
      },
      {
        path: "reportDetails.filters.errorFields",
        display: "Error Sub Type",
        seq: 12,
      },
    ],
  },
  reportDetails: {
    display: "Report Details",
    type: "section",
    items: [
      {
        seq: 1,
        display: "Report Name",
        path: "reportDetails.reportName",
        transform(diffData) {
          const path = this.path!;

          const { __new: newValue = "N/A", __old: oldValue = "N/A" } =
            getByDottedDiff(diffData, path);

          const __new = reportTypeCodeMap[newValue] ?? newValue;
          const __old = reportTypeCodeMap[oldValue] ?? oldValue;

          return {
            __new,
            __old,
          };
        },
      },
      {
        seq: 2,
        display: "Frequency",
        path: "reportDetails.frequency",
      },
      {
        seq: 3,
        display: "Date Range",
        paths: ["reportDetails.startDate", "reportDetails.endDate"],
        transform(diffData, prevData, currData) {
          const [startDatePath, endDatePath] = this.paths!;

          const NA = "N/A";
          let isDateChanged = false;

          // start date
          let { __new: startDate__new, __old: startDate__old } =
            getByDottedDiff(diffData, startDatePath);

          // If start date has changed
          if (startDate__new || startDate__old) {
            isDateChanged = true;
          }

          startDate__new =
            startDate__new || getByDot(currData, startDatePath) || NA;
          startDate__old =
            startDate__old || getByDot(prevData, startDatePath) || NA;

          // end date
          let { __new: endDate__new, __old: endDate__old } = getByDottedDiff(
            diffData,
            endDatePath
          );

          // If end date has changed
          if (endDate__new || endDate__old) {
            isDateChanged = true;
          }

          endDate__new = endDate__new || getByDot(currData, endDatePath) || NA;
          endDate__old = endDate__old || getByDot(prevData, endDatePath) || NA;

          if (!isDateChanged) {
            return {
              __new: undefined,
              __old: undefined,
            };
          }

          return {
            __new:
              startDate__new === NA && startDate__new === endDate__new
                ? NA
                : `${startDate__new} ~ ${endDate__new}`,
            __old:
              startDate__old === NA && startDate__old === endDate__old
                ? NA
                : `${startDate__old} ~ ${endDate__old}`,
          };
        },
      },
      {
        seq: 4,
        display: "Vendor",
        path: "reportDetails.vendor",
      },
      {
        seq: 5,
        display: "Accreditation",
        path: "reportDetails.accreditation",
      },
      {
        seq: 6,
        display: "Program",
        path: "reportDetails.program",
      },
    ],
  },
  otherDetails: {
    display: "",
    type: "section",
    items: [
      {
        display: "Filter Name",
        path: "name",
      },
      {
        display: "filters.reportId",
        path: "reportId",
      },
    ],
  },
  filtersDetails: {
    display: "Filter Details",
    type: "section",
    items: [
      {
        path: "filters.filters.classifications",
        display: "Classification",
        seq: 1,
      },
      {
        path: "filters.filters.accreditations",
        display: "Accreditation",
        seq: 2,
      },
      {
        path: "filters.filters.certificates",
        display: "Certificate",
        seq: 3,
      },
      {
        path: "filters.filters.vendors",
        display: "Vendor",
        seq: 4,
      },
      {
        path: "filters.filters.programs",
        display: "Program",
        seq: 1,
      },
      {
        path: "filters.filters.errorType",
        display: "Error Type",
        seq: 6,
      },
      {
        path: "filters.filters.errorFields",
        display: "Error Sub Type",
        seq: 7,
      },
      {
        path: "filters.filters.frequency",
        display: "Frequency",
        seq: 2,
      },
      {
        seq: 5,
        display: "Date Range",
        paths: ["filters.filters.startDate", "filters.filters.endDate"],
        transform(diffData, prevData, currData) {
          const [startDatePath, endDatePath] = this.paths!;

          const NA = "N/A";
          let isDateChanged = false;

          // start date
          let { __new: startDate__new, __old: startDate__old } =
            getByDottedDiff(diffData, startDatePath);

          // If start date has changed
          if (startDate__new || startDate__old) {
            isDateChanged = true;
          }

          startDate__new =
            startDate__new || getByDot(currData, startDatePath) || NA;
          startDate__old =
            startDate__old || getByDot(prevData, startDatePath) || NA;

          // end date
          let { __new: endDate__new, __old: endDate__old } = getByDottedDiff(
            diffData,
            endDatePath
          );

          // If end date has changed
          if (endDate__new || endDate__old) {
            isDateChanged = true;
          }

          endDate__new = endDate__new || getByDot(currData, endDatePath) || NA;
          endDate__old = endDate__old || getByDot(prevData, endDatePath) || NA;

          if (!isDateChanged) {
            return {
              __new: undefined,
              __old: undefined,
            };
          }

          return {
            __new:
              startDate__new === NA && startDate__new === endDate__new
                ? NA
                : `${startDate__new} ~ ${endDate__new}`,
            __old:
              startDate__old === NA && startDate__old === endDate__old
                ? NA
                : `${startDate__old} ~ ${endDate__old}`,
          };
        },
      },
      {
        path: "filters.filters.dateType",
        display: "Date Type",
        seq: 10,
        transform(diffData) {
          const path = this.path;

          const { __old: oldValue = "NA", __new: newValue = "NA" } =
            getByDottedDiff(diffData, path!);
          const __old = _.startCase(oldValue);
          const __new = _.startCase(newValue);

          return {
            __old,
            __new,
          };
        },
      },
      {
        path: "filters.filters.programType",
        display: "Program Mode",
        seq: 1,
      },
      {
        path: "filters.filters.programSubType",
        display: "Program Subtype",
        seq: 1,
      },
      {
        path: "filters.filters.subCategories",
        display: "Sub Categories",
        seq: 1,
        transform(diffData) {
          const path = this.path;

          const { __old: oldValue = "NA", __new: newValue = "NA" } =
            getByDottedDiff(diffData, path!);
          const __old = _.startCase(oldValue).split(" ").join(",");
          const __new = _.startCase(newValue).split(" ").join(",");

          return {
            __old,
            __new,
          };
        },
      },
      {
        path: "filters.filters.categories",
        display: "Categories",
        seq: 1,
        transform(diffData) {
          const path = this.path;

          const { __old: oldValue = "NA", __new: newValue = "NA" } =
            getByDottedDiff(diffData, path!);
          let __old;
          let __new;
          if (
            oldValue == "successActivities" ||
            newValue == "successActivities"
          ) {
            __old = _.startCase(oldValue);
            __new = _.startCase(newValue);
          } else {
            __old = _.startCase(oldValue).split(" ").join(",");
            __new = _.startCase(newValue).split(" ").join(",");
          }

          return {
            __old,
            __new,
          };
        },
      },
    ],
  },
  schedulerCustomFieldsDetails: {
    display: "Component Fields",
    type: "section",
    items: [
      {
        path: "reportDetails.customField.vendors",
        display: "Vendor",
        seq: 1,
      },
      {
        path: "reportDetails.customField.programs",
        display: "Program",
        seq: 2,
      },
      {
        path: "reportDetails.customField.programAccreditations",
        display: "Program Accreditation Detail",
        seq: 3,
      },
      {
        path: "reportDetails.customField.learnerDetails",
        display: "Learner Detail",
        seq: 4,
      },
      {
        path: "reportDetails.customField.accreditations",
        display: "Accreditation",
        seq: 5,
      },
      {
        path: "reportDetails.customField.classifications",
        display: "Classification",
        seq: 6,
      },

      {
        path: "reportDetails.customField.certificates",
        display: "Certificate",
        seq: 7,
      },
    ],
  },
  reportFilterCustomFieldsDetails: {
    display: "Component Fields",
    type: "section",
    items: [
      {
        path: "filters.customField.vendors",
        display: "Vendor",
        seq: 1,
      },
      {
        path: "filters.customField.programs",
        display: "Program",
        seq: 2,
      },
      {
        path: "filters.customField.programAccreditations",
        display: "Program Accreditation Detail",
        seq: 3,
      },
      {
        path: "filters.customField.learnerDetails",
        display: "Learner Detail",
        seq: 4,
      },
      {
        path: "filters.customField.accreditations",
        display: "Accreditation",
        seq: 5,
      },
      {
        path: "filters.customField.classifications",
        display: "Classification",
        seq: 6,
      },

      {
        path: "filters.customField.certificates",
        display: "Certificate",
        seq: 7,
      },
    ],
  },
  schedulerSuccessActivitiesDetails: {
    display: "Success Activity Fields",
    type: "section",
    items: [
      {
        path: "reportDetails.customField.successActivities.vendorDetails",
        display: "Vendor Details",
        seq: 9,
      },
      {
        path: "reportDetails.customField.successActivities.programDetails",
        display: "Program Details",
        seq: 10,
      },
      {
        path: "reportDetails.customField.successActivities.learnerDetails",
        display: "Learner Details",
        seq: 11,
      },
      {
        path: "reportDetails.customField.successActivities.learnerStatus",
        display: "Learner Status",
        seq: 12,
      },
      {
        path: "reportDetails.customField.successActivities.creditDetails",
        display: "Credit Details",
        seq: 13,
      },
      {
        path: "reportDetails.customField.successActivities.miscellaneous",
        display: "Miscellaneous",
        seq: 14,
      },
      {
        path: "reportDetails.customField.successActivities.capceDetails",
        display: "CAPCE Details",
        seq: 15,
      },
      {
        path: "reportDetails.customField.successActivities.errorDetails",
        display: "Error Details",
        seq: 16,
      },
    ],
  },
  schedulerErrorActivitiesDetails: {
    display: "Error Activity Fields",
    type: "section",
    items: [
      {
        path: "reportDetails.customField.errorActivities.vendorDetails",
        display: "Vendor Details",
        seq: 9,
      },
      {
        path: "reportDetails.customField.errorActivities.programDetails",
        display: "Program Details",
        seq: 10,
      },
      {
        path: "reportDetails.customField.errorActivities.learnerDetails",
        display: "Learner Details",
        seq: 11,
      },
      {
        path: "reportDetails.customField.errorActivities.learnerStatus",
        display: "Learner Status",
        seq: 12,
      },
      {
        path: "reportDetails.customField.errorActivities.creditDetails",
        display: "Credit Details",
        seq: 13,
      },
      {
        path: "reportDetails.customField.errorActivities.miscellaneous",
        display: "Miscellaneous",
        seq: 14,
      },
      {
        path: "reportDetails.customField.errorActivities.capceDetails",
        display: "CAPCE Details",
        seq: 15,
      },
      {
        path: "reportDetails.customField.errorActivities.errorDetails",
        display: "Error Details",
        seq: 16,
      },
    ],
  },
  filterSuccessActivitiesDetails: {
    display: "Success Activity Fields",
    type: "section",
    items: [
      {
        path: "filters.customField.successActivities.vendorDetails",
        display: "Vendor Details",
        seq: 9,
      },
      {
        path: "filters.customField.successActivities.programDetails",
        display: "Program Details",
        seq: 10,
      },
      {
        path: "filters.customField.successActivities.learnerDetails",
        display: "Learner Details",
        seq: 11,
      },
      {
        path: "filters.customField.successActivities.learnerStatus",
        display: "Learner Status",
        seq: 12,
      },
      {
        path: "filters.customField.successActivities.creditDetails",
        display: "Credit Details",
        seq: 13,
      },
      {
        path: "filters.customField.successActivities.miscellaneous",
        display: "Miscellaneous",
        seq: 14,
      },
      {
        path: "filters.customField.successActivities.capceDetails",
        display: "CAPCE Details",
        seq: 15,
      },
      {
        path: "filters.customField.successActivities.errorDetails",
        display: "Error Details",
        seq: 16,
      },
    ],
  },
  filterErrorActivitiesDetails: {
    display: "Error Activity Fields",
    type: "section",
    items: [
      {
        path: "filters.customField.errorActivities.vendorDetails",
        display: "Vendor Details",
        seq: 9,
      },
      {
        path: "filters.customField.errorActivities.programDetails",
        display: "Program Details",
        seq: 10,
      },
      {
        path: "filters.customField.errorActivities.learnerDetails",
        display: "Learner Details",
        seq: 11,
      },
      {
        path: "filters.customField.errorActivities.learnerStatus",
        display: "Learner Status",
        seq: 12,
      },
      {
        path: "filters.customField.errorActivities.creditDetails",
        display: "Credit Details",
        seq: 13,
      },
      {
        path: "filters.customField.errorActivities.miscellaneous",
        display: "Miscellaneous",
        seq: 14,
      },
      {
        path: "filters.customField.errorActivities.capceDetails",
        display: "CAPCE Details",
        seq: 15,
      },
      {
        path: "filters.customField.errorActivities.errorDetails",
        display: "Error Details",
        seq: 16,
      },
    ],
  },
  notificationDetails: {
    display: "Notification Details",
    type: "section",
    items: [
      {
        display: "Email",
        seq: 1,
        path: "notificationDetails.email",
      },
      {
        display: "Subject",
        seq: 2,
        path: "notificationDetails.subject",
      },
      {
        display: "Sender",
        seq: 3,
        path: "notificationDetails.sender",
      },
      {
        display: "Message",
        seq: 4,
        path: "notificationDetails.message",
        transform(diffData) {
          const path = this.path;

          const { __old, __new } = getByDottedDiff(diffData, path!);

          return {
            __old: isEmptyHTML(__old) ? "N/A" : __old,
            __new: isEmptyHTML(__new) ? "N/A" : __new,
          };
        },
        wrapper: ({ children }) => {
          if (isEmptyHTML(children as string)) {
            return <>N/A</>;
          }

          return (
            <div
              dangerouslySetInnerHTML={{
                __html: children as string,
              }}
            />
          );
        },
      },
      {
        display: "Format",
        seq: 5,
        path: "notificationDetails.format",
      },
      {
        display: "Notify Me",
        seq: 7,
        path: "notificationDetails.notifyMe",
        transform(diffData) {
          const path = this.path!;
          return transformBoolMapper(diffData, path);
        },
      },
    ],
  },
};

const ReportLogDiffSection: FunctionComponent<{ sectionCode: string }> = ({
  sectionCode,
}) => {
  const section = diffList[sectionCode];
  const fields = section.items;

  const { diffData } = useJSONDiff();

  const RenderFields = fields.map((field: any) => {
    return field.hide ? null : (
      <JSONDiff.Row
        key={`${section.display}_${field.path}`}
        path={field.path}
        label={field.display}
        transform={field?.transform?.bind?.(field)}
        wrapper={field?.wrapper?.bind?.(field)}
      />
    );
  });

  const sectionHide =
    Boolean(section.hide) ||
    !fields.some((f) => {
      let diff: any;
      if (f.path) {
        diff = getByDottedDiff(diffData, f.path);
      }

      return (
        diff?.__old != null ||
        diff?.__new != null ||
        f.paths?.some((p) => {
          const dDiff = getByDottedDiff(diffData, p);
          return dDiff?.__old != null || dDiff?.__new != null;
        })
      );
    });

  return sectionHide ? null : (
    <JSONDiff.Section title={section.display}>{RenderFields}</JSONDiff.Section>
  );
};

export const ReportLogDiffChildren: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  return <>{children}</>;
};

export interface ReportLogDiffProps {
  children?: ReactNode;
  previousData?: any;
  currentData?: any;
  delta?: boolean;
}

export const ReportLogDiff: FunctionComponent<ReportLogDiffProps> = ({
  previousData,
  currentData,
  delta = true,
}) => {
  const children: ReactNode = Object.keys(diffList).map((sectionCode) => (
    <ReportLogDiffSection key={sectionCode} sectionCode={sectionCode} />
  ));

  return (
    <JSONDiffWrapper newData={currentData} oldData={previousData} delta={delta}>
      <ReportLogDiffChildren>{children}</ReportLogDiffChildren>
    </JSONDiffWrapper>
  );
};

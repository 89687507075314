 // Returns true if two lists contains same elements in any order.
 export const listEquals = (list1: any[], list2: any[]): boolean => {
    if (
      !Array.isArray(list1) ||
      !Array.isArray(list2) ||
      list1.length !== list2.length
    ) {
      return false;
    }

    for (const element of list1) {
      if(!list2.includes(element)){
        return false;
      }
    }

    return true;
  }
import React from "react";
import { useTranslation } from "react-i18next";
import NoRecordFoundWrapper from "./styled";

type PageNotFoundProps = {
  heading ?: string;
  description ?: string;
}

export const PageNotFound : React.FunctionComponent<PageNotFoundProps> = (
  {
    heading,
    description
  }
) => {
  const { t } = useTranslation();

  return (
    <NoRecordFoundWrapper>
      <aui-globalerror
        pageerror={404}
        errortype="secondary"
        heading={heading ?? t<string>("404Heading")}
      >
        <p className="aui-not-found-subtitle">
          {description ?? t<string>("404Description")}
        </p>
      </aui-globalerror>
    </NoRecordFoundWrapper>
  );
};
export default PageNotFound;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListCustomReportSavedFilters } from "services/api/report.api";
import _ from "lodash";
import { CONSTANTS } from "common/constants";
import { CategoryOption } from "common/constants/customReports";

interface DropdownState {
  vendorList: Array<any>;
  accreditationList: Array<any>;
  programList: Array<any>;
  certificateList: Array<any>;
  classificationList: Array<any>;
  programTypeSubTypeList:Array<any>;
  programSubTypeList:Array<any>;
  programTypeList:Array<any>
}

const initialState: DropdownState = {
  vendorList: [],
  accreditationList: [],
  programList: [],
  certificateList: [],
  classificationList: [],
  programTypeSubTypeList:[],
  programSubTypeList:[],
  programTypeList:[]

};

const dropdownOptionsData = createSlice({
  name: "dropdownData",
  initialState,
  reducers: {
    setVendorsList: (state, action) => {
      state.vendorList = action.payload;
    },
    setAccreditationsList: (state, action) => {
      state.accreditationList = action.payload;
    },
    setProgramsList: (state, action) => {
      state.programList = action.payload;
    },
    setCertificatesList: (state, action) => {
      state.certificateList = action.payload;
    },
    setClassificationsList: (state, action) => {
      state.classificationList = action.payload;
    },
    setProgramTypeSubTypeList: (state, action) => {
      state.programTypeSubTypeList = action.payload;
    },
    setProgramSubTypeList: (state, action) => {
      state.programSubTypeList = action.payload;
    },
    setProgramTypeList: (state, action) => {
      state.programTypeList = action.payload;
    },
  },
});
const { actions, reducer } = dropdownOptionsData;

export const {
  setVendorsList,
  setAccreditationsList,
  setProgramsList,
  setCertificatesList,
  setClassificationsList,
  setProgramTypeSubTypeList,
  setProgramSubTypeList,
  setProgramTypeList
} = actions;

export default reducer;

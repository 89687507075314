export const Routes = {
  HOME_PAGE: "/",
  INVALID_USER_PAGE: "/invalidUser",

  VENDOR_LIST_PAGE: "/vendors",
  VENDOR_ADD_PAGE: "/vendors/add",
  VENDOR_EDIT_PAGE: "/vendors/edit/:id",
  VENDOR_VIEW_PAGE: "/vendors/:id",

  ACTIVITY_LIST_PAGE: "/activities",
  ACTIVITY_VIEW_EDIT_PAGE: "/activities/:id",
  ACTIVITY_ERROR_VIEW_EDIT_PAGE: "/activity-errors/:id",
  ACTIVITY_ERROR_LIST_PAGE: "/activity-errors",

  ARCHIVE_LEARNER_LIST_PAGE: "/archived-learners",
  
  SCHEDULER_PAGE: "/schedulers",
  SCHEDULER_ADD_PAGE: "/schedulers/add",
  AUDIT_LOG_PAGE: "/auditLogs",
  REPORT_LOG_PAGE: "/reportLogs",
  CUSTOM_REPORT_LOG_PAGE: "/customReportLogs",
  VENDOR_ACTIVITY_LOG_PAGE: "/vendorActivityLogs",

  ACTIVITY_LOG_LIST_PAGE: "/activityLogs",
  ACTIVITY_LOG_VIEW_PAGE: "/activityLogs/:id",
  CUSTOM_REPORTS_HOME_PAGE :"/custom-reports",
  CUSTOM_REPORTS_SELECT_FIELDS: "/custom-reports/select-fields",

  LEARNER_LIST_PAGE: "/learners",
  LEARNER_ADD_PAGE: "/learners/add",

  PROGRAM_ADD_PAGE: "/programs/add",

  CUSTOM_REPORT_ADD_SCHEDULER: "/schedulers/custom/add",
  CUSTOM_REPORT_PREVIEW: "/schedulers/custom/preview-report",
  CUSTOM_REPORT_EDIT_SCHEDULER: "/schedulers/custom/edit/:id",

  ADMIN_CUSTOM_REPORT: "/admin/customReport",
  ACTIVITY_CUSTOM_REPORT: "/activity/customReport"
} as const;

export const APIRoutes = {
  TOKEN_REFRESH: "/tokenRefresh",
  SSO_LOGIN: "/ssoLogin",
} as const
export const dateType = {
  Programs: [
    {
      name: "Program Start Date",
      code: "programStartDate",
    },
    {
      name: "Program End Date",
      code: "programEndDate",
    },
    {
      name: "Program Expiration Date",
      code: "programExpirationDate",
    },
  ],
  Accreditations: [
    {
      name: "Accreditation Release Date",
      code: "accreditationReleaseDate",
    },
    {
      name: "Accreditation Expiration Date",
      code: "accreditationExpirationDate",
    },
  ],
};

import React, { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { components as comp } from "common/constants/customReports";

import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import {
  SaveCustomReportByFilters,
  UpdateCustomReportFilterById,
} from "services/api/report.api";
import { setPageLoadingStatus } from "components/Loader/loader.slice";

import { useCustomReportContext } from "../CustomReportContext/CustomReportContext";

import store from "app/store";
import { showToast } from "components/Toast/toast.slice";

import { listCustomReportFiltersApiCall, setDisableSaveReportFilter, setInitialSavedFilterData } from "../CustomReportSlice";
import { useParams } from "react-router-dom";
import { setSavedFilterflag } from "components/AdminLeftPanel/LeftPanelSlice";

export const ChangeReportModalBody = (props: any) => {
  const {
    validateDateRangeField,
    fetchFilters,
    reportName,
    saveReportNameRef,
    reportNameErrorClass,
    showError,
    setShowError,
    setReportNameErrorClass,
  } = props;

  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const cancelButtonRef = useRef<HTMLInputElement>(null);
  const [filterName, setFilterName] = useState<string>("sameName");
  const [filterValue, setFilterValue] = useState<string>("");

  const { t } = useTranslation();
  const routeParams: any = useParams();

  const handleFieldChange = (e: React.FormEvent<HTMLFieldSetElement>) => {
    setFilterName((e.target as HTMLInputElement).name);
    setFilterValue((e.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setFilterValue(reportName);
  }, [reportName]);

  const dispatch = useDispatch();
  const validateReportName = (value: any) => {
    if (_.isEmpty(value)) {
      setReportNameErrorClass(true);
      setShowError("Please enter a name for Report Filter!");
      return false;
    } else if (value.length < 2) {
      setReportNameErrorClass(true);
      setShowError("Report Filter Name must have more than 2 characters");
      return false;
    } else if (value.length > 255) {
      setReportNameErrorClass(true);
      setShowError("Report Filter Name must not exceed 255 characters");
      return false;
    } else {
      setReportNameErrorClass(false);
      setShowError("");
      return true;
    }
  };

  const handleChangeFilter = async () => {
    if (
      validateReportName(saveReportNameRef?.current?.value) ||
      !_.isEmpty(filterValue)
    ) {
      let filterReportName = filterValue !== "" ? filterValue : "";
      let dataReportName =
        saveReportNameRef?.current?.value !== ""
          ? saveReportNameRef?.current?.value
          : "";

      let changeReportName = _.isEmpty(dataReportName)
        ? filterReportName
        : dataReportName;

      let saveFilterApiReqBody = {
        filters: {
          ...customReportingState.customReportApiReqBody,
          filters: {
            ...customReportingState.customReportApiReqBody.filters,
            notifyMe: customReportingState.notificationDetails?.notifyMe ?? false,
          }
        },
        name: changeReportName,
      };

      let dateRange;

      if (
        customReportingState.customReportApiReqBody?.filters?.startDate !== "" &&
        customReportingState.customReportApiReqBody?.filters?.endDate !== ""
      ) {
        dateRange = [
          new Date(customReportingState.customReportApiReqBody?.filters?.startDate),
          new Date(customReportingState.customReportApiReqBody?.filters?.endDate),
        ];
      }

      if (
        Object.keys(customReportingState.customReportApiReqBody).length !== 0 &&
        (validateDateRangeField(dateRange) ||
          customReportingState.customReportApiReqBody?.filters?.frequency !== "custom_range")
      ) {
        dispatch(setPageLoadingStatus({ isPageLoading: true }));
        try {

          if (filterName === "sameName") {
            let response = await UpdateCustomReportFilterById(
              routeParams.id,
              saveFilterApiReqBody
            );

            if (response?.data?.status === 200) {
              dispatch(setSavedFilterflag(true));
              dispatch(setInitialSavedFilterData({
                ...customReportingState.initialSavedFilterData,
                ...customReportingState.reportDetails,
              }));
              setShowError("");
              store.dispatch(
                showToast({
                  type: "success",
                  title: "CustomReport",
                  message: "Report Filter Updated!",
                })
              );
              // fetchFilters(routeParams.id);
              cancelButtonRef.current?.click();
            }
          }
          if (filterName === "newName") {
            let response = await SaveCustomReportByFilters(
              saveFilterApiReqBody
            );
            if (response?.data?.status === 200) {
              dispatch(setSavedFilterflag(true));
              setShowError("");
              store.dispatch(
                showToast({
                  type: "success",
                  title: "CustomReport",
                  message: "Report Filter Saved!",
                })
              );
              cancelButtonRef.current?.click();
            }
          }
        } catch (error: any) {
          if (error.response?.status === 400) {
            if (
              error?.response?.data?.error?.message?.startsWith(
                "Custom Report Filter "
              )
            ) {
              setReportNameErrorClass(true);
              setShowError(
                "Report Filter Name already exists. Please provide a different name"
              );
            }

            if (
              !(
                showError.startsWith("name") ||
                error?.response?.data?.error?.message?.startsWith(
                  "Custom Report Filter "
                )
              )
            ) {
              store.dispatch(
                showToast({
                  title: "CustomReport",
                  message:
                    error.response?.data?.error?.details?.[0]?.message ??
                    error.response?.data?.error?.message,
                })
              );
            }
          }
        }
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      }
    }
  };

  return (
    <div className="save-report-modal-container mt-3">
      <form>
        <div className="change-modal-body">
          <div className="change-modal-body-title">
            <p className="text-left">{t<string>("saveChangesTo")}</p>
          </div>
          <div className="radio-buttons-section">
            <fieldset
              id="group1"
              className="radio-fieldset"
              onChange={(e) => handleFieldChange(e)}
            >
              <div className="d-flex align-items-start mb-3">
                <input
                  type="radio"
                  id="reportName"
                  value={reportName}
                  name="sameName"
                  data-testid="sameName_radio"
                  checked={filterName === "sameName" && true}
                />
                <label htmlFor="reportName" className="pl-2 mb-0 text-left">
                  {reportName}
                </label>
              </div>
              <div className="d-flex align-items-start">
                <input
                  type="radio"
                  id="newName"
                  value=""
                  name="newName"
                  data-testid="newName_radio"
                  checked={filterName === "newName" && true}
                />
                <label htmlFor="newName" className="pl-2 mb-0 text-left">
                  {t<string>("newFilter")}
                </label>
              </div>
            </fieldset>
          </div>
        </div>
        {filterName === "newName" && (
          <div className="form-group input-section required">
            <label className="input-section-label" htmlFor="name">
              {t<string>("name")}
            </label>
            <input
              className={`input-section-text form-control ${reportNameErrorClass ? "required-field" : ""
                }`}
              type="text"
              id="name"
              ref={saveReportNameRef}
              required
            />
            <span
              className={`danger-text ${showError?.length > 0 ? "block" : "d-none"
                }`}
            >
              {showError.charAt(0).toUpperCase() + showError.slice(1)}
            </span>
          </div>
        )}

        <div className="button-section">
          <aui-button
            buttontitle={t<string>("cancel")}
            data-testid="cancel_button"
            variant="secondary"
            data-dismiss="modal"
            //@ts-ignore
            ref={cancelButtonRef}
            onClick={() => {
              setReportNameErrorClass(false);
              setShowError("");
              if (saveReportNameRef.current) {
                saveReportNameRef.current.value = "";
              }
            }}
            size="medium"
          ></aui-button>
          <div className="ml-2">
            <aui-button
              variant="primary"
              buttonclass="w-100"
              onClick={() => {
                handleChangeFilter();
              }}
              data-testid="save_button"
              buttontitle={t<string>("save")}
              size="medium"
            ></aui-button>
          </div>
        </div>
      </form>
    </div>
  );
};

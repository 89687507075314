import styled from "styled-components";

export const ToastWrapper = styled.div`
  aui-alert {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1051;
    top: 0;
    width: 84%;
    @media (max-width: 991px){
      width: 95%;
    }
  }
`;

export const AnotherWrapper = styled.div``;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListCustomReportSavedFilters } from "services/api/report.api";
import _ from "lodash";
import { CONSTANTS } from "common/constants";
import { CategoryOption } from "common/constants/customReports";

export interface IPagination {
  pageNumber: number;
  pageOffset: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isFirst: number;
  isLast: number;
}
export interface SchedulerDetails {
  title: string;
  startDate: string;
  endDate: string;
  runTime: string;
  reportPeriod: any;
  omitReport: boolean;
  status: string;
}
export interface ReportDetails {
  categories: Array<CategoryOption>;
  vendors: string;
  accreditations: string;
  programs: string;
  classifications: string;
  certificates: string;
  frequency: string;
  startDate: string;
  endDate: string;
  mismatchedErrors: string;
  rejectedErrors: string;
  errorType: string;
  dateType: string;
  programMode?: string;
  programSubType?: Array<String>;
}
export interface NotificationDetails {
  email: string;
  subject: string;
  sender?: string;
  message: string;
  format: string;
  notifyMe: boolean;
}

export interface InitialSavedFilterDetailsType {
  categories: Array<CategoryOption>;
  vendors: string;
  accreditations: string;
  programs: string;
  classifications: string;
  certificates: string;
  frequency: string;
  startDate: string;
  endDate: string;
  mismatchedErrors: string;
  rejectedErrors: string;
  errorType: string;
  dateType: string;
  programMode?: string;
  programSubType?: Array<String>;
  isSavedFilterSelected: boolean;
}

export type ManagementState = {
  loader: boolean;
  customReportFilters: [];
  schedulerDetails: SchedulerDetails;
  reportDetails: ReportDetails;
  notificationDetails: NotificationDetails;
  selectedFields: Array<string>;
  tableColumns: Array<any>;
  previewData: any;
  customReportApiReqBody: any;
  filters: any;
  initialSchedulerData: any;
  initialSavedFilterData: InitialSavedFilterDetailsType;
  initialSelectedFields: Array<string>;
  isInvalidId: boolean;
  reportName: string;
  reportID: string;
  errorMessage: String;
  pagination: IPagination;
  showViewReport: boolean;
  isAdminReport: boolean;
  isActivityReport: boolean;
  isSuccessActivity: boolean;
  isErrorActivity: boolean;
  isExportReportDisable: boolean;
  savedReportData: any;
  lastItemDeleted:boolean
  disableSaveReportFilter:boolean;

};

const defaultPaginationValues: IPagination = {
  pageNumber: 0,
  pageOffset: 0,
  pageSize: 0,
  totalCount: 0,
  totalPages: 0,
  isFirst: 0,
  isLast: 0,
};

export const initialState: ManagementState = {
  loader: false,
  customReportFilters: [],
  schedulerDetails: {
    title: "",
    startDate: "",
    endDate: "",
    runTime: "",
    reportPeriod: {},
    omitReport: true,
    status: "",
  },
  reportDetails: {
    categories: [],
    vendors: "",
    programs: "",
    accreditations: "",
    classifications: "",
    certificates: "",
    frequency: "",
    startDate: "",
    endDate: "",
    mismatchedErrors: "",
    rejectedErrors: "",
    errorType: "",
    dateType: "",
    programMode: "",
    programSubType: [],
  },
  notificationDetails: {
    email: "",
    subject: "",
    sender: CONSTANTS.REPORT_SENDER_EMAIL,
    message: "",
    format: "xlsx",
    notifyMe: false,
  },
  selectedFields: [],
  tableColumns: [],
  customReportApiReqBody: {},
  previewData: {},
  showViewReport: false,
  filters: {},
  pagination: defaultPaginationValues,
  initialSavedFilterData: {
    categories: [],
    vendors: "",
    programs: "",
    accreditations: "",
    classifications: "",
    certificates: "",
    frequency: "",
    startDate: "",
    endDate: "",
    mismatchedErrors: "",
    rejectedErrors: "",
    errorType: "",
    dateType: "",
    programMode: "",
    programSubType: [],
    isSavedFilterSelected: true,
  },
  initialSchedulerData: {},
  initialSelectedFields: [],
  isInvalidId: false,
  errorMessage: "",
  reportName: "",
  reportID: "",
  isAdminReport: true,
  isActivityReport: false,
  isSuccessActivity: false,
  isErrorActivity: false,
  isExportReportDisable: false,
  savedReportData: {},
  lastItemDeleted:false,
  disableSaveReportFilter:true,
};

export const listCustomReportFiltersApiCall = createAsyncThunk(
  "/listCustomReportFilters",
  async (search: string, { dispatch }) => {
    try {
      const response = await ListCustomReportSavedFilters(search);
      return response?.data;
    } catch (error: any) {
      return {};
    }
  }
);

const managementSlice = createSlice({
  name: "customReport",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearCustomReportState: (state) => {
      return {
        ...initialState,
        customReportFilters: state.customReportFilters,
      };
    },
    customReportFilters: (state, action: PayloadAction<number>) => {
      return state;
    },
    setSchedulerDates: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setReportPeriod: (state, action: PayloadAction<SchedulerDetails>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        reportPeriod: action.payload.reportPeriod,
      };
    },
    setLastItemDeleted: (state, action: PayloadAction<any>) => {
      state.lastItemDeleted= action.payload
    },
    setSchedulerState: (state, action: PayloadAction<any>) => {
      state.schedulerDetails = {
        ...state.schedulerDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    
    setNotificationState: (state, action: PayloadAction<any>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    setCategory: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        categories: action.payload.categories,
      };
    },
    setVendor: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        vendors: action.payload.vendors,
      };
    },
    setProgram: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        programs: action.payload.programs,
      };
    },
    setAccreditation: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        accreditations: action.payload.accreditations,
      };
    },
    setClassification: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        classifications: action.payload.classifications,
      };
    },
    setExportReportButtonDisable:(state,action:PayloadAction<{ data: boolean }>)=>{
      state.isExportReportDisable = action?.payload?.data
    },
    setCertificate: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        certificates: action.payload.certificates,
      };
    },
    setMisMatchedErrors: (state, action: PayloadAction<ReportDetails>) => {
      let errorType = _.isEmpty(action.payload?.mismatchedErrors)
        ? ""
        : action.payload?.errorType;
      state.reportDetails = {
        ...state.reportDetails,
        mismatchedErrors: action.payload?.mismatchedErrors,
        errorType: errorType,
      };
    },
    setRejectedErrors: (state, action: PayloadAction<ReportDetails>) => {
      let errorType = _.isEmpty(action.payload?.rejectedErrors)
        ? ""
        : action.payload?.errorType;
      state.reportDetails = {
        ...state.reportDetails,
        rejectedErrors: action.payload.rejectedErrors,
        errorType: errorType,
      };
    },
    setFrequency: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        frequency: action.payload.frequency,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setDateRange: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    setMessage: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        message: action.payload.message,
      };
    },
    setNotifyMe: (state, action: PayloadAction<NotificationDetails>) => {
      state.notificationDetails = {
        ...state.notificationDetails,
        notifyMe: action.payload.notifyMe,
      };
    },
    setSelectedFields: (state, action: PayloadAction<ManagementState>) => {
      state.selectedFields = action.payload.selectedFields;
    },
    setTableColumns: (state, action: PayloadAction<ManagementState>) => {
      state.tableColumns = action.payload.tableColumns;
    },
    setPreviewData: (state, action: PayloadAction<ManagementState>) => {
      let reportData = [];
      try {
        const previewData = action.payload?.previewData;
        const data = previewData?.data;
        reportData = JSON.parse(JSON.stringify(data?.customReport)) || [];

        reportData.map((ele: any, index: any) => {
          if (ele.hasOwnProperty("classification")) {
            ele["classification"] = ele["classification"]?.replace(/,/g, ", ");
          }
          if (ele.hasOwnProperty("specialties")) {
            ele["specialties"] = ele["specialties"]?.replace(/,/g, ", ");
          }
          if (ele.hasOwnProperty("qualifications")) {
            ele["qualifications"] = ele["qualifications"]?.replace(/,/g, ", ");
          }
        });

        state.previewData = {
          ...action.payload.previewData,
          data: {
            ...action.payload.previewData.data,
            customReport: reportData,
          },
        };
      } catch (error) {}
    },
    setFilters: (state, action: PayloadAction<ManagementState>) => {
      let customField =
        !_.isEmpty(action.payload.filters?.customField) &&
        JSON.parse(JSON.stringify(action.payload.filters?.customField));

      for (const [key, value] of Object.entries(customField)) {
        if (_.isEmpty(value)) {
          customField[key] = [];
        }
      }

      state.filters = {
        ...action.payload.filters,
        customField,
      };
    },
    setReportDetails: (state, action: PayloadAction<any>) => {
      state.reportDetails = {
        ...state.reportDetails,
        [action.payload.key]: action.payload.value,
      };
    },
    setInitialSavedFilterData: (
      state,
      action: PayloadAction<any>
    ) => {
      state.initialSavedFilterData = action.payload;
    },
    setInitialSchedulerData: (
      state,
      action: PayloadAction<ManagementState>
    ) => {
      state.initialSchedulerData = action.payload.initialSchedulerData;
    },
    setInitialSelectedFields: (
      state,
      action: PayloadAction<ManagementState>
    ) => {
      state.initialSelectedFields = action.payload.initialSelectedFields;
    },
    setIsInvalidId: (state, action: PayloadAction<ManagementState>) => {
      state.isInvalidId = action.payload.isInvalidId;
    },
    setErrorMessage: (state, action: PayloadAction<ManagementState>) => {
      state.errorMessage = action.payload.errorMessage;
    },
    setDateType: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        dateType: action.payload.dateType,
      };
    },
    setProgramMode: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        programMode: action.payload.programMode,
      };
    },
    setProgramSubType: (state, action: PayloadAction<ReportDetails>) => {
      state.reportDetails = {
        ...state.reportDetails,
        programSubType: action.payload.programSubType,
      };
    },
    setReportName: (state, action: PayloadAction<ManagementState>) => {
      state.reportName = action.payload.reportName;
    },
    setReportId: (state, action: PayloadAction<ManagementState>) => {
      state.reportID = action.payload.reportID;
    },
    setCustomReportAPIReqBody: (
      state,
      action: PayloadAction<ManagementState>
    ) => {
      state.customReportApiReqBody = action.payload.customReportApiReqBody;
    },
    setPaginationData: (state, action: PayloadAction<ManagementState>) => {
      state.pagination = action.payload.pagination || defaultPaginationValues;
    },
    setShowViewReport: (state, action: PayloadAction<ManagementState>) => {
      state.showViewReport = action.payload.showViewReport;
    },
    setReportType: (state, action: PayloadAction<ManagementState>) => {
      state.isAdminReport = action.payload?.isAdminReport;
      state.isActivityReport = action.payload?.isActivityReport;
      state.isSuccessActivity = action.payload?.isSuccessActivity;
      state.isErrorActivity = action.payload?.isErrorActivity;
    },
    updateReportDetail:(state, action: PayloadAction<object>) => {
      state.reportDetails = { ...state.reportDetails,...action.payload}
    },
    updateSaveReportState: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setDisableSaveReportFilter:(state, action: PayloadAction<boolean>) => {
      state.disableSaveReportFilter = action.payload
    },
    setSavedReportData: (state, action: PayloadAction<any>) => {
      state.savedReportData = action.payload
    },
    clearReportDetails: (state) => {
      state.reportDetails = initialState.reportDetails;
      state.previewData = initialState.previewData;
      state.tableColumns = initialState.tableColumns;
      state.customReportApiReqBody = initialState.customReportApiReqBody;
      state.selectedFields = initialState.selectedFields;
      state.showViewReport = initialState.showViewReport;
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(
      listCustomReportFiltersApiCall.fulfilled,
      (state, action) => {
        state.customReportFilters = action.payload;
      }
    );
  },
});
const { actions, reducer } = managementSlice;

export const {
  setLoader,
  clearCustomReportState,
  customReportFilters,
  setSchedulerDates,
  setCategory,
  setVendor,
  setProgram,
  setAccreditation,
  setClassification,
  setCertificate,
  setMisMatchedErrors,
  setRejectedErrors,
  setFrequency,
  setDateRange,
  setMessage,
  setNotifyMe,
  setSchedulerState,
  setNotificationState,
  setSelectedFields,
  setReportPeriod,
  setTableColumns,
  setPreviewData,
  setFilters,
  setReportDetails,
  setInitialSchedulerData,
  setInitialSelectedFields,
  setInitialSavedFilterData,
  setIsInvalidId,
  setErrorMessage,
  setDateType,
  setReportName,
  setReportId,
  setCustomReportAPIReqBody,
  setPaginationData,
  setShowViewReport,
  setProgramMode,
  setProgramSubType,
  setReportType,
  clearReportDetails,
  setExportReportButtonDisable,
  setSavedReportData,
  setLastItemDeleted,
  updateReportDetail,
  setDisableSaveReportFilter,
  updateSaveReportState,
} = actions;
export default reducer;

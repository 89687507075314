import styled from "styled-components";

export const InputTextStyle = styled.div`
  .input--readonly {
    white-space: pre-wrap !important;
    max-width: 200px;
    height: auto !important;
    min-height: 40px;
    overflow-wrap: break-word;
  }
  .multi {
    .list__control {
      &:hover {
        border-color: #777979;
      }
    }
    .list__menu {
      border: 1px solid rgb(234, 234, 234);
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      box-shadow: none;
      outline: none;
      .list__menu-list {
        padding: 0;
        border: 0;
        margin: 0;
        .list__option {
          overflow: hidden;
          color: rgb(34, 35, 40);
          font-size: 14px;
          white-space: wrap;
          word-break: break-word;
          &.list__option--is-disabled {
            opacity: 0.5;
            &:hover {
              background-color: #fff;
            }
          }
          &:hover {
            background-color: rgba(34, 35, 40, 0.05);
          }
        }
      }
    }
    .list__dropdown-indicator svg {
      -webkit-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      overflow: hidden;
    }
    .list__control--menu-is-open {
      .list__dropdown-indicator svg {
        transform: rotate(-180deg);
        -webkit-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    [class*="-menu"] {
      input[type="checkbox"] {
        display: none;

        + label {
          margin-top: 8px;
          display: inline-block;
          margin-left: 8px;
          width: 90%;
        }
      }
    }

    .list__multi-value {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      padding-inline: 0.5rem;
      gap: 0.25rem;
      background-color: rgba(193, 14, 33, 0.1);
    }

    .list__multi-value__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
      color: rgb(193, 14, 33);
      padding-inline: 0.5rem;
      background: transparent;
      border: none;
    }

    .list__multi-value__remove {
      padding: 0px;
      background-color: transparent;
      border: none;
      svg {
        fill: rgb(34, 35, 40);
        stroke: #fff;
        stroke-width: 0.4px;
      }
      &:hover {
        color: #222328;
      }
    }

    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {
      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
          stroke: #fff;
          stroke-width: 0.8px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }

  .disabled-field {
    opacity: 0.3;
    cursor: initial !important;
  }
`;

export const InputOptionStyle = styled.div`
  .multi-select-checkbox[type="checkbox"]:checked {
    accent-color: #c10e21;
  }
`;

export default InputTextStyle;

import React from "react";

const SvgCeCmeLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="223.582" height="67.571" viewBox="0 0 223.582 67.571" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_5469" data-name="Rectangle 5469" width="55.604" height="52.636" fill="none" />
        </clipPath>
      </defs>
      <g id="adh_logo" data-name="adh logo" transform="matrix(1, 0, 0, 1, 3, 0)">
        <path id="Divider" d="M 70.56 11.879 L 70.56 61.702" stroke="#838487" fill="none" strokeWidth="1" />
        <g id="aha_100_years_logo" data-name="aha 100 years logo" transform="matrix(1.203954, 0, 0, 1.17446, -1.919776, 2.160213)">
          <path id="Path_3842" data-name="Path 3842" d="M70,206.625v2.768h-1.93v-2.768l-2.32-4.425h2.105l1.287,2.671L70.43,202.2H72.3Z" transform="translate(-53.764 -165.387)" fill="#c10b1f" />
          <path id="Path_3843" data-name="Path 3843" d="M117.952,202.1h4.64v1.677h-2.71v1.131h2.632v1.618h-2.632v1.111h2.807v1.677h-4.737Z" transform="translate(-96.397 -165.306)" fill="#c10b1f" />
          <path id="Path_3844" data-name="Path 3844" d="M164.348,208.048h-2.437l-.429,1.345h-1.93l2.593-7.193h2.125l2.593,7.193h-2.027Zm-.487-1.5-.741-2.3-.741,2.3Z" transform="translate(-130.492 -165.387)" fill="#c10b1f" />
          <g id="Group_8215" data-name="Group 8215">
            <g id="Group_8214" data-name="Group 8214" clipPath="url(#clip-path)">
              <path id="Path_3845" data-name="Path 3845" d="M220.5,207.436v1.543h-.276a3.845,3.845,0,0,1-.882-.074,1.52,1.52,0,0,1-.7-.367,3.668,3.668,0,0,1-.753-.955l-.643-1.084h-.459v2.48h-1.837V202.2h2.756a2.77,2.77,0,0,1,1.286.276,1.965,1.965,0,0,1,.808.735,1.943,1.943,0,0,1,.276,1.01,2.11,2.11,0,0,1-1.157,1.911l.478.7a2.1,2.1,0,0,0,.4.459.96.96,0,0,0,.57.147Zm-3.73-2.333h.643a.742.742,0,0,0,.551-.184.75.75,0,0,0,.2-.551.671.671,0,0,0-.753-.716h-.643Z" transform="translate(-175.461 -165.052)" fill="#c10b1f" />
              <path id="Path_3846" data-name="Path 3846" d="M262.6,208.543a3.8,3.8,0,0,1-1.249-.68l.937-1.415a3.021,3.021,0,0,0,.827.514,2.316,2.316,0,0,0,.974.2c.514,0,.79-.147.79-.441a.3.3,0,0,0-.092-.22,1.335,1.335,0,0,0-.349-.2c-.184-.074-.459-.184-.863-.331a3.673,3.673,0,0,1-1.4-.79,1.637,1.637,0,0,1-.459-1.249,1.938,1.938,0,0,1,.331-1.139,2.051,2.051,0,0,1,.919-.735,3.516,3.516,0,0,1,1.36-.257,4.026,4.026,0,0,1,1.341.22,2.567,2.567,0,0,1,.992.57l-.937,1.341a2.171,2.171,0,0,0-.643-.423,2.136,2.136,0,0,0-.772-.147c-.5,0-.735.11-.735.349a.287.287,0,0,0,.11.22,2.3,2.3,0,0,0,.4.22c.2.073.5.184.9.312a4.2,4.2,0,0,1,.992.441,1.641,1.641,0,0,1,.588.643,2.241,2.241,0,0,1,.2,1.01,2.038,2.038,0,0,1-.331,1.157,2.175,2.175,0,0,1-.937.772,3.269,3.269,0,0,1-1.359.276,6.109,6.109,0,0,1-1.543-.22" transform="translate(-213.336 -164.725)" fill="#c10b1f" />
              <path id="Path_3847" data-name="Path 3847" d="M61.753,7.973a3.812,3.812,0,0,0-1.635,4.079c1.562-2.315,6.338-.22,7.937-5.548a4.712,4.712,0,0,1-3.142,1.01A4.813,4.813,0,0,0,66.677,0c.68,3.087-3.233,3.344-6.32,3.932-5.787,1.084-6.724,6.449-4.1,9.186-.312-3.252,2.278-5.181,5.493-5.144" transform="translate(-44.786)" fill="#c10b1f" />
              <path id="Path_3848" data-name="Path 3848" d="M31.353,70.819a5.493,5.493,0,0,0-4.391,1.856,6.874,6.874,0,0,0-4.005-5.2,7.1,7.1,0,0,0-8.745,2.3s-.551.827-.661,1.047l-.018.037c-.129-.22-.551-.9-.551-.9a7.126,7.126,0,0,0-8.874-2.462C.322,69.257-1.5,73.74,1.5,79.049c2.131,3.766,5.457,6.614,12.034,11.7q.772-.606,1.488-1.157l.037-.037V75.54a13.858,13.858,0,0,1-4.1,2.15V74.144a13.963,13.963,0,0,0,4.869-3.252h2.719V86.729a30.957,30.957,0,0,0,6.283-6.54v.22c0,3.05.533,5.42,1.617,7.055a5.418,5.418,0,0,0,4.887,2.48,5.391,5.391,0,0,0,4.832-2.462,13,13,0,0,0,1.617-7.073,13.032,13.032,0,0,0-1.617-7.092,5.35,5.35,0,0,0-4.813-2.5m2.241,14.312a2.363,2.363,0,0,1-4.52.037,13.277,13.277,0,0,1-.661-4.74,13.1,13.1,0,0,1,.68-4.777,2.336,2.336,0,0,1,2.26-1.672c1.947,0,2.9,2.131,2.9,6.394a13.6,13.6,0,0,1-.661,4.758" transform="translate(0 -54.541)" fill="#c10b1f" />
              <path id="Path_3849" data-name="Path 3849" d="M238.169,91.2a5.343,5.343,0,0,0-4.832-2.5,5.4,5.4,0,0,0-4.869,2.5,12.99,12.99,0,0,0-1.617,7.092c0,3.05.533,5.42,1.617,7.055a6.014,6.014,0,0,0,9.719.018A13,13,0,0,0,239.8,98.29c-.018-3.068-.551-5.438-1.635-7.092m-2.59,11.795a2.363,2.363,0,0,1-4.52.037,13.277,13.277,0,0,1-.661-4.74,13.1,13.1,0,0,1,.68-4.777,2.336,2.336,0,0,1,2.26-1.672c1.947,0,2.9,2.131,2.9,6.394a12.786,12.786,0,0,1-.661,4.758" transform="translate(-185.175 -72.404)" fill="#c10b1f" />
              <path id="Path_3850" data-name="Path 3850" d="M63.471,259.168a1.4,1.4,0,0,1,.276.882,1.577,1.577,0,0,1-.2.79,1.386,1.386,0,0,1-.588.533,1.971,1.971,0,0,1-.9.2h-2.2V256.1H61.8a1.83,1.83,0,0,1,1.231.386,1.37,1.37,0,0,1,.276,1.709,1.493,1.493,0,0,1-.551.514,1.426,1.426,0,0,1,.717.459m-2.554-.772h.753a.761.761,0,0,0,.57-.22.736.736,0,0,0,.22-.533.568.568,0,0,0-.22-.478.867.867,0,0,0-.588-.184h-.717Zm1.543,2.113a.693.693,0,0,0,.22-.551.663.663,0,0,0-.2-.514.771.771,0,0,0-.551-.184h-.992v1.451h.955a.97.97,0,0,0,.57-.2" transform="translate(-48.856 -209.049)" fill="#1a1413" />
              <path id="Path_3851" data-name="Path 3851" d="M85.581,268.085a2.058,2.058,0,0,1-.753-2.811,1.882,1.882,0,0,1,.753-.716,2.49,2.49,0,0,1,2.2,0,1.772,1.772,0,0,1,.753.716,2.127,2.127,0,0,1,0,2.094,1.772,1.772,0,0,1-.753.716,2.173,2.173,0,0,1-1.1.257,2.219,2.219,0,0,1-1.1-.257m1.911-.955a1.258,1.258,0,0,0,0-1.617,1.155,1.155,0,0,0-1.58,0,1.108,1.108,0,0,0-.312.808,1.184,1.184,0,0,0,.312.827,1.1,1.1,0,0,0,.79.312,1.192,1.192,0,0,0,.79-.331" transform="translate(-69.018 -215.743)" fill="#1a1413" />
              <path id="Path_3852" data-name="Path 3852" d="M112.832,259.977a.945.945,0,0,1-.514-.441,1.736,1.736,0,0,1-.165-.827V254.3h.992v4.3a.619.619,0,0,0,.165.5.781.781,0,0,0,.514.147h.165v.882h-.22a2.708,2.708,0,0,1-.937-.147" transform="translate(-91.547 -207.58)" fill="#1a1413" />
              <path id="Path_3853" data-name="Path 3853" d="M128.614,259.287a.657.657,0,0,0,.386.092v.882h-.037a2.15,2.15,0,0,1-.863-.147.869.869,0,0,1-.441-.478,1.6,1.6,0,0,1-.588.459,1.818,1.818,0,0,1-.772.165,2.064,2.064,0,0,1-1.01-.257,2.006,2.006,0,0,1-.7-.717,2.415,2.415,0,0,1,0-2.094,1.749,1.749,0,0,1,.7-.717,2.064,2.064,0,0,1,1.01-.257,1.72,1.72,0,0,1,1.231.478v-2.3h.992v4.593a.253.253,0,0,0,.092.294m-1.121-1.6a.943.943,0,0,0-.441-.4,1.5,1.5,0,0,0-.643-.147,1.023,1.023,0,0,0-.772.312,1.1,1.1,0,0,0-.294.808,1.17,1.17,0,0,0,.294.827,1.052,1.052,0,0,0,.772.312,1.585,1.585,0,0,0,.661-.147,1.039,1.039,0,0,0,.423-.423Z" transform="translate(-101.506 -207.661)" fill="#1a1413" />
              <path id="Path_3854" data-name="Path 3854" d="M167.926,256.1v5.475h-1.084V259.3h-2.425v2.278h-1.066V256.1h1.066v2.278h2.425V256.1Z" transform="translate(-133.341 -209.049)" fill="#1a1413" />
              <path id="Path_3855" data-name="Path 3855" d="M197.447,266.478h-2.9a.94.94,0,0,0,.367.7,1.246,1.246,0,0,0,.808.239,1.923,1.923,0,0,0,.661-.11,2.237,2.237,0,0,0,.514-.257l.367.7a2.588,2.588,0,0,1-1.6.5,2.348,2.348,0,0,1-1.121-.257,1.966,1.966,0,0,1-.735-.716,2.261,2.261,0,0,1,0-2.094,1.685,1.685,0,0,1,.716-.716,2.186,2.186,0,0,1,2.058,0,1.653,1.653,0,0,1,.661.753,2.451,2.451,0,0,1,.239,1.121Zm-1.01-.68a.953.953,0,0,0-.294-.606.88.88,0,0,0-.606-.22.976.976,0,0,0-.643.22,1.01,1.01,0,0,0-.349.606Z" transform="translate(-157.992 -215.661)" fill="#1a1413" />
              <path id="Path_3856" data-name="Path 3856" d="M222.151,267.413a.746.746,0,0,0,.386.092v.882h-.092c-.735,0-1.157-.2-1.268-.588a1.711,1.711,0,0,1-.606.478,1.82,1.82,0,0,1-.772.165,2.064,2.064,0,0,1-1.01-.257,2.005,2.005,0,0,1-.7-.716,2.415,2.415,0,0,1,0-2.094,1.75,1.75,0,0,1,.7-.716,2.065,2.065,0,0,1,1.01-.257,1.721,1.721,0,0,1,1.231.478v-.4h.992v2.664a.309.309,0,0,0,.129.276m-1.121-1.562a1.165,1.165,0,0,0-.423-.423,1.441,1.441,0,0,0-.643-.147,1.024,1.024,0,0,0-.772.312,1.1,1.1,0,0,0-.294.808,1.17,1.17,0,0,0,.294.827,1.052,1.052,0,0,0,.772.312,1.34,1.34,0,0,0,.643-.165.985.985,0,0,0,.441-.423Z" transform="translate(-177.828 -215.824)" fill="#1a1413" />
              <path id="Path_3857" data-name="Path 3857" d="M249.348,264.518v.974h-.367a1.116,1.116,0,0,0-.588.129,1.066,1.066,0,0,0-.367.386v2.425h-.974v-3.913h.974v.625a1.038,1.038,0,0,1,.423-.5,1.232,1.232,0,0,1,.606-.147Z" transform="translate(-201.663 -215.906)" fill="#1a1413" />
              <path id="Path_3858" data-name="Path 3858" d="M262.365,262.958a1.534,1.534,0,0,1-.386-1.176v-1.562h-.827v-.827h.827V258.2h.992v1.194h1.121v.827h-1.121v1.433a.92.92,0,0,0,.165.606.666.666,0,0,0,.478.184h.478v.9h-.533a1.815,1.815,0,0,1-1.194-.386" transform="translate(-213.173 -210.763)" fill="#1a1413" />
              <path id="Path_3859" data-name="Path 3859" d="M280.05,268.113a2.029,2.029,0,0,1-.7-.5l.551-.588a1.287,1.287,0,0,0,.478.349,1.231,1.231,0,0,0,.57.129,1.013,1.013,0,0,0,.459-.092.285.285,0,0,0,.165-.276.315.315,0,0,0-.184-.294,3.487,3.487,0,0,0-.661-.257,2.2,2.2,0,0,1-.882-.459,1,1,0,0,1-.312-.772,1.019,1.019,0,0,1,.4-.845,1.8,1.8,0,0,1,1.084-.312,2.289,2.289,0,0,1,.827.147,1.525,1.525,0,0,1,.588.4l-.625.588a.667.667,0,0,0-.312-.276,1.094,1.094,0,0,0-.5-.11.747.747,0,0,0-.386.092.268.268,0,0,0-.129.239.276.276,0,0,0,.073.2.678.678,0,0,0,.257.147c.11.037.312.11.551.2a2.035,2.035,0,0,1,.845.459.97.97,0,0,1,.294.753,1.141,1.141,0,0,1-.423.9,1.848,1.848,0,0,1-1.157.349,3.121,3.121,0,0,1-.882-.184" transform="translate(-228.029 -215.661)" fill="#1a1413" />
              <path id="Path_3860" data-name="Path 3860" d="M133.626,33.2H132.6l-.22.845h-.625l1.029-3.546h.68l1.029,3.546h-.661Zm-.147-.478-.386-1.47-.386,1.47Z" transform="translate(-107.546 -24.897)" fill="#1a1413" />
              <path id="Path_3861" data-name="Path 3861" d="M151.075,36.057a1.149,1.149,0,0,1,.184.717v1.6h-.57V36.829a.632.632,0,0,0-.092-.367.278.278,0,0,0-.257-.129.577.577,0,0,0-.459.312v1.764h-.57v-1.6a.545.545,0,0,0-.092-.331.321.321,0,0,0-.257-.129.552.552,0,0,0-.459.312v1.745h-.551V35.855h.57v.276a.776.776,0,0,1,.643-.331.628.628,0,0,1,.643.4,1.171,1.171,0,0,1,.312-.294.741.741,0,0,1,.4-.11.664.664,0,0,1,.551.257" transform="translate(-120.77 -29.223)" fill="#1a1413" />
              <path id="Path_3862" data-name="Path 3862" d="M169.71,37.251h-1.488c.018.459.22.68.57.68a.961.961,0,0,0,.331-.055.771.771,0,0,0,.257-.165l.2.4a1.5,1.5,0,0,1-.367.22,1.206,1.206,0,0,1-.459.073.959.959,0,0,1-.808-.349,1.416,1.416,0,0,1-.294-.955,1.581,1.581,0,0,1,.129-.7,1.165,1.165,0,0,1,.367-.459.957.957,0,0,1,.551-.147.843.843,0,0,1,.735.349,1.615,1.615,0,0,1,.257,1.01v.092h.018m-.588-.386a.913.913,0,0,0-.129-.459.357.357,0,0,0-.294-.147.392.392,0,0,0-.312.147.841.841,0,0,0-.147.459Z" transform="translate(-136.851 -29.223)" fill="#1a1413" />
              <path id="Path_3863" data-name="Path 3863" d="M181.983,36.018v.551H181.8a.467.467,0,0,0-.294.092.547.547,0,0,0-.184.257v1.617h-.57V36.018h.57v.367a.543.543,0,0,1,.22-.294.569.569,0,0,1,.312-.092Z" transform="translate(-147.544 -29.386)" fill="#1a1413" />
              <path id="Path_3864" data-name="Path 3864" d="M188.544,30.688a.32.32,0,0,1-.092-.257.349.349,0,0,1,.092-.239.3.3,0,0,1,.239-.092.349.349,0,0,1,.239.092.3.3,0,0,1,.092.239.439.439,0,0,1-.092.257.314.314,0,0,1-.478,0m-.037.514h.57v2.517h-.57Z" transform="translate(-153.829 -24.57)" fill="#1a1413" />
              <path id="Path_3865" data-name="Path 3865" d="M194.185,38.362a1.123,1.123,0,0,1-.386-.441,1.631,1.631,0,0,1-.147-.7,1.717,1.717,0,0,1,.147-.717,1.061,1.061,0,0,1,.386-.459.957.957,0,0,1,.551-.147,1.1,1.1,0,0,1,.717.257l-.257.423a.6.6,0,0,0-.4-.165.488.488,0,0,0-.4.2,1.035,1.035,0,0,0-.129.588,1.067,1.067,0,0,0,.147.588.465.465,0,0,0,.4.2.647.647,0,0,0,.423-.165l.239.423a1.6,1.6,0,0,1-.331.2,1.2,1.2,0,0,1-.386.073,1.378,1.378,0,0,1-.57-.165" transform="translate(-158.074 -29.304)" fill="#1a1413" />
              <path id="Path_3866" data-name="Path 3866" d="M205.993,37.9a.345.345,0,0,0,.184.073v.514h-.11a.884.884,0,0,1-.4-.073.4.4,0,0,1-.22-.239.787.787,0,0,1-.294.257.917.917,0,0,1-.386.092,1.054,1.054,0,0,1-.533-.147.992.992,0,0,1-.349-.459,1.959,1.959,0,0,1,0-1.4,1.131,1.131,0,0,1,.349-.459.859.859,0,0,1,.514-.165.764.764,0,0,1,.588.257v-.2h.57v1.764a.558.558,0,0,0,.092.184m-.625-1.176a.824.824,0,0,0-.184-.239.5.5,0,0,0-.294-.073.455.455,0,0,0-.386.2,1,1,0,0,0-.147.57,1.024,1.024,0,0,0,.147.588.455.455,0,0,0,.386.2.555.555,0,0,0,.294-.092.524.524,0,0,0,.2-.239v-.919Z" transform="translate(-166.318 -29.304)" fill="#1a1413" />
              <path id="Path_3867" data-name="Path 3867" d="M220.473,36.792v1.58h-.57V36.829c0-.349-.129-.514-.386-.514a.632.632,0,0,0-.5.312v1.745h-.57V35.855h.57v.276a.843.843,0,0,1,.68-.331c.533,0,.772.331.772.992" transform="translate(-178.318 -29.223)" fill="#1a1413" />
              <path id="Path_3868" data-name="Path 3868" d="M239.377,30.6v3.546h-.625V32.639h-1.176v1.507h-.625V30.6h.625v1.488h1.176V30.6Z" transform="translate(-193.419 -24.978)" fill="#1a1413" />
              <path id="Path_3869" data-name="Path 3869" d="M254.41,37.251h-1.488c.018.459.22.68.57.68a.961.961,0,0,0,.331-.055.771.771,0,0,0,.257-.165l.2.4a1.5,1.5,0,0,1-.367.22,1.206,1.206,0,0,1-.459.073.959.959,0,0,1-.808-.349,1.416,1.416,0,0,1-.294-.955,1.581,1.581,0,0,1,.129-.7,1.165,1.165,0,0,1,.367-.459.957.957,0,0,1,.551-.147.843.843,0,0,1,.735.349,1.615,1.615,0,0,1,.257,1.01v.092h.018m-.588-.386a.913.913,0,0,0-.129-.459.357.357,0,0,0-.294-.147.393.393,0,0,0-.312.147.841.841,0,0,0-.147.459Z" transform="translate(-205.99 -29.223)" fill="#1a1413" />
              <path id="Path_3870" data-name="Path 3870" d="M266.693,37.9a.345.345,0,0,0,.184.073v.514h-.11a.884.884,0,0,1-.4-.073.4.4,0,0,1-.22-.239.787.787,0,0,1-.294.257.917.917,0,0,1-.386.092,1.054,1.054,0,0,1-.533-.147.992.992,0,0,1-.349-.459,1.959,1.959,0,0,1,0-1.4,1.131,1.131,0,0,1,.349-.459.859.859,0,0,1,.514-.165.764.764,0,0,1,.588.257v-.2h.569v1.764a.558.558,0,0,0,.092.184m-.625-1.176a.825.825,0,0,0-.184-.239.5.5,0,0,0-.294-.073.455.455,0,0,0-.386.2,1,1,0,0,0-.147.57,1.024,1.024,0,0,0,.147.588.455.455,0,0,0,.386.2.555.555,0,0,0,.294-.092.523.523,0,0,0,.2-.239v-.919Z" transform="translate(-215.867 -29.304)" fill="#1a1413" />
              <path id="Path_3871" data-name="Path 3871" d="M280.483,36.018v.551H280.3a.467.467,0,0,0-.294.092.548.548,0,0,0-.184.257v1.617h-.569V36.018h.569v.367a.543.543,0,0,1,.22-.294.57.57,0,0,1,.312-.092Z" transform="translate(-227.948 -29.386)" fill="#1a1413" />
              <path id="Path_3872" data-name="Path 3872" d="M287,35.087a1,1,0,0,1-.22-.735V33.231h-.423v-.478h.423V32h.57v.772h.57v.478h-.57v1.029a.657.657,0,0,0,.092.386.322.322,0,0,0,.294.129h.184v.514h-.276a.881.881,0,0,1-.643-.22" transform="translate(-233.743 -26.121)" fill="#1a1413" />
              <path id="Path_3873" data-name="Path 3873" d="M177.726,55.4H176.7l-.22.845h-.625l1.029-3.546h.68l1.029,3.546h-.661Zm-.129-.5-.386-1.47-.386,1.47Z" transform="translate(-143.544 -43.018)" fill="#1a1413" />
              <path id="Path_3874" data-name="Path 3874" d="M191.338,60.535a.986.986,0,0,1-.386-.294l.312-.349a.693.693,0,0,0,.533.294.312.312,0,0,0,.239-.073.263.263,0,0,0,.092-.2.275.275,0,0,0-.092-.22,2.384,2.384,0,0,0-.331-.184,2.758,2.758,0,0,1-.331-.165.559.559,0,0,1-.22-.239.723.723,0,0,1-.092-.367.662.662,0,0,1,.239-.533.876.876,0,0,1,.588-.2.949.949,0,0,1,.772.331l-.331.312a.513.513,0,0,0-.4-.2.293.293,0,0,0-.22.073.236.236,0,0,0-.073.184.263.263,0,0,0,.092.2,1.9,1.9,0,0,0,.349.184,2.761,2.761,0,0,1,.331.165.7.7,0,0,1,.22.239.853.853,0,0,1,.092.386.638.638,0,0,1-.239.533.856.856,0,0,1-.625.2,1.052,1.052,0,0,1-.514-.073" transform="translate(-155.87 -47.344)" fill="#1a1413" />
              <path id="Path_3875" data-name="Path 3875" d="M201.238,60.535a.986.986,0,0,1-.386-.294l.312-.349a.693.693,0,0,0,.533.294.312.312,0,0,0,.239-.073.263.263,0,0,0,.092-.2.275.275,0,0,0-.092-.22,2.382,2.382,0,0,0-.331-.184,2.762,2.762,0,0,1-.331-.165.558.558,0,0,1-.22-.239.723.723,0,0,1-.092-.367.662.662,0,0,1,.239-.533.877.877,0,0,1,.588-.2.949.949,0,0,1,.772.331l-.331.312a.513.513,0,0,0-.4-.2.293.293,0,0,0-.22.073.236.236,0,0,0-.073.184.263.263,0,0,0,.092.2,1.9,1.9,0,0,0,.349.184,2.762,2.762,0,0,1,.331.165.7.7,0,0,1,.22.239.854.854,0,0,1,.092.386.638.638,0,0,1-.239.533.856.856,0,0,1-.625.2,1.128,1.128,0,0,1-.514-.073" transform="translate(-163.951 -47.344)" fill="#1a1413" />
              <path id="Path_3876" data-name="Path 3876" d="M211.546,60.36a1.7,1.7,0,0,1,0-1.911.982.982,0,0,1,.808-.349,1.049,1.049,0,0,1,.588.165,1.06,1.06,0,0,1,.386.459,1.634,1.634,0,0,1,.129.7,1.416,1.416,0,0,1-.294.955.982.982,0,0,1-.808.349,1.118,1.118,0,0,1-.808-.367m1.176-.386a1.047,1.047,0,0,0,.129-.57,1.186,1.186,0,0,0-.129-.57.447.447,0,0,0-.367-.2.455.455,0,0,0-.386.2,1.047,1.047,0,0,0-.129.57,1.186,1.186,0,0,0,.129.57.406.406,0,0,0,.367.2.433.433,0,0,0,.386-.2" transform="translate(-172.441 -47.426)" fill="#1a1413" />
              <path id="Path_3877" data-name="Path 3877" d="M224.985,60.562a1.123,1.123,0,0,1-.386-.441,1.63,1.63,0,0,1-.147-.7,1.717,1.717,0,0,1,.147-.717,1.061,1.061,0,0,1,.386-.459.957.957,0,0,1,.551-.147,1.1,1.1,0,0,1,.717.257L226,58.78a.6.6,0,0,0-.4-.165.488.488,0,0,0-.4.2,1.024,1.024,0,0,0-.147.588,1.067,1.067,0,0,0,.147.588.465.465,0,0,0,.4.2.647.647,0,0,0,.423-.165l.239.423a1.6,1.6,0,0,1-.331.2,1.2,1.2,0,0,1-.386.073,1.378,1.378,0,0,1-.551-.165" transform="translate(-183.215 -47.426)" fill="#1a1413" />
              <path id="Path_3878" data-name="Path 3878" d="M235.644,52.888a.32.32,0,0,1-.092-.257.349.349,0,0,1,.092-.239.3.3,0,0,1,.239-.092.349.349,0,0,1,.239.092.3.3,0,0,1,.092.239.439.439,0,0,1-.092.257.323.323,0,0,1-.239.11.41.41,0,0,1-.239-.11m-.037.514h.57v2.517h-.57Z" transform="translate(-192.276 -42.691)" fill="#1a1413" />
              <path id="Path_3879" data-name="Path 3879" d="M242.993,60.1a.346.346,0,0,0,.184.073v.514h-.11a.884.884,0,0,1-.4-.073.4.4,0,0,1-.22-.239.787.787,0,0,1-.294.257.917.917,0,0,1-.386.092,1.054,1.054,0,0,1-.533-.147.993.993,0,0,1-.349-.459,1.959,1.959,0,0,1,0-1.4,1.131,1.131,0,0,1,.349-.459.859.859,0,0,1,.514-.165.764.764,0,0,1,.588.257v-.2h.57v1.764a.293.293,0,0,0,.092.184m-.643-1.176a.824.824,0,0,0-.184-.239.5.5,0,0,0-.294-.073.455.455,0,0,0-.386.2,1,1,0,0,0-.147.57,1.024,1.024,0,0,0,.147.588.455.455,0,0,0,.386.2.555.555,0,0,0,.294-.092.524.524,0,0,0,.2-.239v-.919h-.018" transform="translate(-196.521 -47.426)" fill="#1a1413" />
              <path id="Path_3880" data-name="Path 3880" d="M254.9,57.2a1,1,0,0,1-.22-.735V55.349h-.423v-.478h.423V54.1h.57v.772h.57v.478h-.57v1.029a.657.657,0,0,0,.092.386.322.322,0,0,0,.294.129h.184v.514h-.276a.781.781,0,0,1-.643-.2" transform="translate(-207.541 -44.161)" fill="#1a1413" />
              <path id="Path_3881" data-name="Path 3881" d="M264.044,52.888a.32.32,0,0,1-.092-.257.349.349,0,0,1,.092-.239.3.3,0,0,1,.239-.092.349.349,0,0,1,.239.092.3.3,0,0,1,.092.239.439.439,0,0,1-.092.257.323.323,0,0,1-.239.11.41.41,0,0,1-.239-.11m-.037.514h.569v2.517h-.569Z" transform="translate(-215.459 -42.691)" fill="#1a1413" />
              <path id="Path_3882" data-name="Path 3882" d="M269.546,60.36a1.7,1.7,0,0,1,0-1.911.982.982,0,0,1,.808-.349,1.049,1.049,0,0,1,.588.165,1.061,1.061,0,0,1,.386.459,1.636,1.636,0,0,1,.129.7,1.416,1.416,0,0,1-.294.955.982.982,0,0,1-.808.349,1.085,1.085,0,0,1-.808-.367m1.176-.386a1.047,1.047,0,0,0,.129-.57,1.186,1.186,0,0,0-.129-.57.447.447,0,0,0-.367-.2.455.455,0,0,0-.386.2,1.048,1.048,0,0,0-.129.57,1.187,1.187,0,0,0,.129.57.406.406,0,0,0,.367.2.455.455,0,0,0,.386-.2" transform="translate(-219.785 -47.426)" fill="#1a1413" />
              <path id="Path_3883" data-name="Path 3883" d="M285.073,58.992v1.58h-.57V59.029c0-.349-.129-.514-.386-.514a.632.632,0,0,0-.5.312v1.745h-.57V58.055h.57v.276A.844.844,0,0,1,284.3,58q.772,0,.772.992" transform="translate(-231.049 -47.344)" fill="#1a1413" />
              <path id="Path_3884" data-name="Path 3884" d="M297.824,65.174a.539.539,0,0,1,.184.22.979.979,0,0,1,.074.349.7.7,0,0,1-.074.349.539.539,0,0,1-.184.22.487.487,0,0,1-.514,0,.541.541,0,0,1-.184-.22.866.866,0,0,1,0-.7.736.736,0,0,1,.184-.22.487.487,0,0,1,.514,0m.018.955a.73.73,0,0,0,0-.772.343.343,0,0,0-.294-.129.331.331,0,0,0-.276.129.73.73,0,0,0,0,.772.331.331,0,0,0,.276.129.343.343,0,0,0,.294-.129m-.092-.22c.018.037.037.037.055.037h.018v.129h-.018a.138.138,0,0,1-.092-.018c-.018-.018-.055-.037-.073-.074a.616.616,0,0,1-.074-.184h-.037v.276h-.147v-.7h.22a.208.208,0,0,1,.147.055.224.224,0,0,1,.055.165.138.138,0,0,1-.018.092.144.144,0,0,1-.074.073.285.285,0,0,0,.037.147m-.239-.22h.037a.065.065,0,0,0,.073-.073c0-.055-.018-.092-.073-.092h-.037Z" transform="translate(-242.477 -53.14)" fill="#1a1413" />
            </g>
          </g>
        </g>
        <text fill="#1d1d1d" fontFamily="Montserrat-Medium, Montserrat" fontWeight="500" fontSize="21px" x="76.927" y="41.764">CE SERVICE</text>
      </g>
    </svg>

  );
};

export default SvgCeCmeLogo;

import React, { FunctionComponent } from "react";

export interface AuiIconProps {
  elementObject?: any;
}

export const AuiIcon: FunctionComponent<AuiIconProps> = ({ elementObject }) => {
  return (
    <aui-icon
      block={elementObject.block ?? "false"}
      svgclass={elementObject.svgclass}
      pathclass={elementObject.pathclass}
      icon={elementObject.icon}
      svgwidth={elementObject.svgwidth}
      svgheight={elementObject.svgheight}
    ></aui-icon>
  );
};

export default AuiIcon;

import React from "react";
import {PageHeadingWrapper} from "./Styled";
const PageHeading = ({ heading }: any) => {
  return (
    <PageHeadingWrapper>
      <div className="title">
        <h1 className="h2-class" data-testid="headerTitle">
          {heading}
        </h1>
      </div>
    </PageHeadingWrapper>
  );
};
export default PageHeading;

import styled from "styled-components";
export const EditCustomReportSchedulerWrapper = styled.div`
  body {
    overflow-x: hidden;
  }
  .aha-breadcrumb a {
    font-size: 16px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .cp-addbtn:focus {
    outline: 2px solid #f7983a !important;
    -webkit-box-shadow: 0 0 4px 4px #f7983a !important;
    box-shadow: 0 0 4px 4px #f7983a !important;
  }
  .aha-breadcrumb .breadcrumb-item {
    color: #c10e21;
  }
  .aha-breadcrumb .breadcrumb-item:last-child {
    color: #707070;
  }
  .aha-breadcrumb .breadcrumb-item:last-child:after {
    content: " ";
  }
  .sub-heads-pr {
    border-bottom: 2px solid #c10e21;
    h1.cp-h2 {
      font-size: 1.7rem;
      margin: 20px 0px 20px 0px;
    }
  }
  .cp-alink {
    margin-top: 20px;
    .cp-addbtn {
      font-size: 26px;
      cursor: pointer;
    }
  }
  .li-one-ul li {
    margin: 5px 10px 5px 15px !important;
  }

  [class*="MultiValueGeneric"] {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  [class*="multiValue"] div:first-child {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .select-field-link {
    position: absolute;
    bottom: -7px;
    right: 0;
    text-decoration: underline;
    color: #c10e21;

    @media screen and (min-width: 767px) {
      right: 15px;
    }
  }

  .disabled-link {
    cursor: not-allowed;
    color: rgb(118, 118, 118);
  }

  .mob_btnLayout {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      color: #c10e21;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      min-width: 6vw;

      > img {
        padding-right: 5px;
        margin-top: -1px;
      }
    }
  }
  .bdr-btm {
    border-bottom: 1px solid #bcc3ca;
    margin-top: 70px;
  }
  .bdr-btm-address {
    border-bottom: 1px solid #bcc3ca;
  }
  .btn-div {
    margin: 40px 0px 185px 0px;
    button {
      width: 141px;
    }
  }
  .iti,
  .iti__flag-container,
  #iti-0__country-listbox {
    width: 100%;
  }
  .iti__selected-flag {
    width: 88px;
  }
  @media screen and (min-width: 1150px) {
    .feild-width {
      max-width: 350px;
      width: 350px;
    }
  }
  @media screen and (max-width: 767.5px) {
    .sub-heads-pr h1.cp-h2 {
      font-size: 24px;
      margin: 40px 0px 20px 0px;
    }
    .no-row-cls {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    form.row {
      margin-right: 0px !important;
      margin-left: 0px;
      width: 100%;
      .form-group {
        padding: 0px !important;
      }
      .lead-content-wrap {
        display: block !important;
      }
      .businesslead-content {
        margin-left: 0px !important;
      }
      .techlead-content,
      .businesslead-content {
        width: 100% !important;
      }
    }
    .cp-alink {
      margin-top: 40px;
    }
  }
  @media screen and (max-width: 575.5px) {
    .sub-heads-pr {
      margin-bottom: 30px !important;
    }
    .sub-heads {
      margin-bottom: 0px !important;
      padding-left: 0px;
    }
    .no-row-cls label {
      padding-left: 0px;
    }
    .bdr-btm {
      margin-top: 40px;
      margin-left: 0px;
    }
    .btn-div {
      margin: 44px 0px 67px auto !important;
      button {
        width: 140px;
        margin-right: 0px !important;
        margin-left: 10px !important;
      }
      button:nth-of-type(1) {
        margin-left: 0px !important;
      }
    }
  }
  .allow-dropdown.intl-tel-input {
    width: 100%;
    .country-list {
      width: 100%;
    }
  }

  @media screen and (max-width: 576px) {
    .mob_btnLayout {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      > button {
        width: 95%;
        margin-left: 0px !important;
      }

      div {
        margin-bottom: 6%;
      }
    }
  }

  .back_link {
    color: #c10e21;
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;

    :focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  @media screen and (min-width: 768px) {
    .scheduler-header {
      margin-left: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .scheduler-header {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 992px) {
    .bdr-btm {
      display: none;
    }

    .bdr-btm-address {
      display: none;
    }
  }

  #omitReport,
  #notifyMe {
    top: 406px;
    left: 491px;
    width: 0;
    height: 8px;
    background: #c10e21 0% 0% no-repeat padding-box;
    border: 0.5px solid #c10e21;
    opacity: 0;
  }

  input[type="checkbox"] + label.customCheck_nextSpan::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 20px;
    width: 20px;
    height: 20px;
    border-radius: 1px;
    border: 1px solid #777979;

    @media screen and (max-width: 991px) {
      left: 0px;
    }
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::before {
    border: 1px solid #777979;
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::after {
    content: "";
    position: absolute;
    left: 1px;
    top: 19px;
    border-style: solid;
    border-color: #d41818;
    width: 8px;
    height: 16px;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);

    @media screen and (max-width: 991px) {
      left: 6px;
    }
  }

  .multi {
    .list__control {
      &:hover {
        border-color: #777979;
      }
    }
    .list__menu {
      border: 1px solid rgb(234, 234, 234);
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      box-shadow: none;
      outline: none;
      .list__menu-list {
        padding: 0;
        border: 0;
        margin: 0;
        .list__option {
          overflow: hidden;
          color: rgb(34, 35, 40);
          font-size: 14px;
          white-space: wrap;
          word-break: break-word;
          &.list__option--is-disabled {
            opacity: 0.5;
            &:hover {
              background-color: #fff;
            }
          }
          &:hover {
            background-color: rgba(34, 35, 40, 0.05);
          }
        }
      }
    }
    .list__dropdown-indicator svg {
      -webkit-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      overflow: hidden;
    }
    .list__control--menu-is-open {
      .list__dropdown-indicator svg {
        transform: rotate(-180deg);
        -webkit-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    [class*="-menu"] {
      input[type="checkbox"] {
        display: none;

        + label {
          margin-top: 8px;
          display: inline-block;
          margin-left: 8px;
          width: 90%;
        }
      }
    }

    .list__multi-value {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      padding-inline: 0.5rem;
      gap: 0.25rem;
      background-color: rgba(193, 14, 33, 0.1);
    }

    .list__multi-value__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
      color: rgb(193, 14, 33);
      padding-inline: 0.5rem;
      background: transparent;
      border: none;
    }

    .list__multi-value__remove {
      padding: 0px;
      background-color: transparent;
      border: none;
      svg {
        fill: rgb(34, 35, 40);
        stroke: #fff;
        stroke-width: 0.4px;
      }
      &:hover {
        color: #222328;
      }
    }

    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {
      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
          stroke: #fff;
          stroke-width: .8px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }

  .checkboxDiv {
    @media screen and (min-width: 768px) {
      margin-top: -0.75rem;
    }
  }

  .message-text-editor {
    min-height: 10rem;
    font-family: inherit;
  }
  @media screen and (min-width: 576px) and (max-width: 991px) {
    div.form-group {
      flex: 100%;
      max-width: 100%;
      padding-right: 0 !important;
      padding-left: 15px !important;

      > label {
        flex: 100%;
        max-width: 100%;
        padding-left: 0 !important;
      }

      > div.float-right {
        flex: 100%;
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .no-row-cls {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    form.row {
      margin-right: 0px !important;
      margin-left: 0px;
      width: 100%;
      .form-group {
        padding: 0px !important;
      }
    }
  }

  select {
    min-height: 40px !important;
  }

  @media only screen and (min-width: 992px) {
    div.container {
      max-width: 98% !important;
      min-width: 70% !important;
    }

    form label {
      padding-right: 0.5px !important;
    }
  }

  .validation-error {
    border: 2px solid #e17509 !important;
  }

  .overlay-hidden {
    display: none;
  }

  .weekly-interval {
    display: none !important;
  }

  .custom-dropdown::before {
    background: white;
    cursor: default;
    border: none;
    margin: 0px;
  }
  .custom-dropdown {
    margin: 0px;
    background: white;
    cursor: default;
  }
  .dropdown-child {
    z-index: 2;
    border: 1px solid #777979;
    border-top: none;
    color: #495057;
    position: absolute;
    width: inherit;
    background: white;
    div {
      padding-left: 0.8rem;
      padding-top: 0.5rem;
    }
    div:hover,
    span:focus {
      background: #495057;
      color: white;
    }
    .custom-span {
      padding-bottom: 0.5rem;
    }
    .select-span {
      padding-bottom: 0.5rem;
    }
  }

  .expired-schedule-label {
    font-size: 12px;
    color: #c10e21;
    opacity: 1;
  }

  .input-field-height {
    height: 40px;
  }

  .invalid-input-text {
    background: #f7afb638 0% 0% no-repeat padding-box;
    border: 1px solid #c10e21;
  }
`;

export const CustomReportsWrapper = styled.div`
  input[type="radio"] {
    height: auto !important;
    outline: none !important;
    accent-color: #c10e21;
  }
  body {
    overflow-x: hidden;
  }
  .cp-view-heads,
  .cp-h3subheads {
    display: block;
  }
  .cp-view-heads {
    margin-top: 20px;
  }

  label.customCheck_nextSpan {
    width: 20px;
    height: 20px;
    margin-bottom: 0px;
    cursor: pointer;
  }
  input[type="checkbox"] + label.customCheck_nextSpan::before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 20px;
    height: 20px;
    border: 1px solid #777979;
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::before {
    border: 1px solid #777979;
  }

  input[type="checkbox"]:checked + label.customCheck_nextSpan::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 8px;
    border-style: solid;
    border-color: #d41818;
    width: 8px;
    height: 16px;
    border-width: 0 2.5px 2.5px 0;
    transform: rotate(45deg);
  }

  #notifyMe {
    width: 0;
    height: 8px;
    background: #c10e21 0% 0% no-repeat padding-box;
    border: 0.5px solid #c10e21;
    opacity: 0;
  }

  .danger-text {
    color: #c10e21;
    font-weight: 400;
    margin-top: -7px;
    font-size: 14px;
    text-align: left;
  }

  @media only screen and (max-width: 575px) {
    .danger-text {
      text-align: left;
    }
  }

  .notifyMeLabel {
    padding-left: 0px;
  }

  /* Save Report Modal */

  .save-report-modal-body {
    height: auto !important;
  }

  .save-report-modal-container {
    height: auto;
    input[type="radio"] {
      margin-top: 5px;
    }
  }
  @media only screen and (min-width: 992px) {
    .row-margin {
      margin-top: 1.5rem;
    }
  }
  .input-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .input-section-label {
    display: block;
    margin-bottom: 0;
    font-weight: 600;
  }

  .input-section-text {
    display: block;
    width: 100%;
    margin: 5px auto 10px auto;
    border: 1px solid #bcc3ca;
    padding: 5px;
  }
  .text-box {
    @media screen and (max-width: 992px) {
      margin-top: 1rem !important;
    }
  }
  .button-section {
    margin-top: 1.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  /* Change Report Modal */
  .change-modal-body {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .change-modal-body-title {
    padding-right: 40px;
    @media screen and (max-width: 575px) {
      padding-right: 10px;
    }
  }

  .radio-buttons-section {
    flex: 1;
  }

  .radio-fieldset {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 992px) {
    div.container {
      max-width: 98% !important;
      min-width: 70% !important;
    }
  }

  .custom-reports-wrapper {
    .cp-mrgnn2 {
      input {
        opacity: 0;
        right: 100px;
        position: absolute;
      }
    }
    .selected-items .reset-cls-btn {
      margin: 0px 19px 0px 10px;
    }
    .li-one-ul li {
      margin: 5px 10px 5px 15px !important;
    }
    .table-responsive {
      overflow-x: visible;
    }
    .pagination-block {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    th.code-cls {
      width: 9% !important;
    }
    th.type-cls {
      width: 11% !important;
    }
    th.sequence-cls {
      width: 13% !important;
    }
    th.filesize-cls {
      width: 14% !important;
    }
    th.createdate-cls {
      width: 13% !important;
    }
    th.updatedate-cls {
      width: 13% !important;
    }
    th.lastreview-cls {
      width: 14% !important;
    }
    // th{padding:21px 15px;}
    .unselected-cls {
      background-color: #fcfcfc !important;
    }
    th.action-cls {
      width: 140px !important;
    }
    .visible_cls {
      display: block !important;
    }
    .visible_cls {
      display: block !important;
      z-index: 3 !important;
      width: max-content !important;
      text-align: center !important;
    }
    .visible_cls.visible_cls-two {
      z-index: 2 !important;
    }
    .svg-parent .keydown-recordfilter {
      padding: 0px 4px;
      margin: 0px 4px;
    }
  }

  .view-report-container {
    width: 95%;
    padding: 0;
    margin: 1.5rem auto;
  }

  .export-text {
    margin: auto 1rem auto 0;
    font-size: 1rem;
    font-weight: 600;
  }

  .export-button {
    float: right;
  }

  .dropdown {
    width: 8rem;
    margin-right: 1rem;
  }

  @media screen and (max-width: 575px) {
    .aui-search-dropdown {
      width: 60%;
    }

    .exportReport {
      width: 100%;
      .btn {
        width: 100%;
      }
    }

    .dropdown {
      flex-basis: 100%;
      width: 100%;
    }

    .export-button {
      width: 100%;
    }

    .button-section .btn {
      padding: 0.5rem 1.5rem;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .export-button {
      padding-left: 1rem;
    }
  }

  .archive-error-actvity-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 0 0 0;
  }

  .filter-nav {
    display: flex;
    flex-direction: row;
    margin: 2% 0;

    .page-number {
      width: 68%;
      @media screen and (max-width: 575px) {
        width: 100%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        width: 36% !important;
        margin-right: 0px;
      }
    }

    .sort-by {
      width: 22%;

      @media screen and (max-width: 575px) {
        width: 50%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        width: 30%;
      }
    }

    .customize-tabel {
      width: 3%;
    }
  }

  @media screen and (max-width: 575px) {
    .exportType-title {
      font-weight: 500;
      width: 24%;
    }
    .selectReportFormat {
      margin-left: 2%;
      width: 74% !important;
    }
  }
`;

export const GenerateReportWrapper = styled.div`
  .title-text {
    font-size: 18px;
    font-weight: 700;
  }

  .non-react-select-field {
    height: 2.7rem;
  }

  .multi {
    .list__control {
      &:hover {
        border-color: #777979;
      }
    }
    .list__menu {
      border: 1px solid rgb(234, 234, 234);
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      box-shadow: none;
      outline: none;
      .list__menu-list {
        padding: 0;
        border: 0;
        margin: 0;
        .list__option {
          overflow: hidden;
          color: rgb(34, 35, 40);
          font-size: 14px;
          white-space: wrap;
          word-break: break-word;
          &.list__option--is-disabled {
            opacity: 0.5;
            &:hover {
              background-color: #fff;
            }
          }
          &:hover {
            background-color: rgba(34, 35, 40, 0.05);
          }
        }
      }
    }
    .list__dropdown-indicator svg {
      -webkit-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      overflow: hidden;
    }
    .list__control--menu-is-open {
      .list__dropdown-indicator svg {
        transform: rotate(-180deg);
        -webkit-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    [class*="-menu"] {
      input[type="checkbox"] {
        display: none;

        + label {
          margin-top: 8px;
          display: inline-block;
          margin-left: 8px;
          width: 90%;
        }
      }
    }

    .list__multi-value {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      padding-inline: 0.5rem;
      gap: 0.25rem;
      background-color: rgba(193, 14, 33, 0.1);
    }

    .list__multi-value__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
      color: rgb(193, 14, 33);
      padding-inline: 0.5rem;
      background: transparent;
      border: none;
    }

    .list__multi-value__remove {
      padding: 0px;
      background-color: transparent;
      border: none;
      svg {
        fill: rgb(34, 35, 40);
        stroke: #fff;
        stroke-width: 0.4px;
      }
      &:hover {
        color: #222328;
      }
    }

    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {
      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
          stroke: #fff;
          stroke-width: .8px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }

  .bdr-btm {
    border-bottom: 1px solid #bcc3ca;
    margin: 2rem 1rem;
    width: 100%;

    @media screen and (max-width: 991px) {
      visibility: hidden;
    }
  }

  .date-range-error {
    border: 2px solid #e17509 !important;
  }

  .button-div-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .add-report-btn,
    .view-report-btn {
      margin-right: 1rem;
    }

    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      .add-report-btn,
      .view-report-btn {
        width: 92%;
        margin-bottom: 10px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .my-react-select {
    /* Custom styles */
  }

  .my-react-select--compact .Select-value:first-child {
    font-style: italic;
  }
  .my-react-select--compact .Select-value:first-child .Select-value-icon {
    display: none;
  }

  .inputs-b {
    margin: 0px;
    .cp-view-h2 {
      font-weight: 700;
      font-size: 18px;
    }
  }

  @media screen and (max-width: 767px) {
    .text-box-div {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
`;

export const AnotherWraper = styled.div``;

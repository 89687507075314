import styled from "styled-components";

export const ModalPopupWrapper = styled.div`
  .confirmationModal {
    .btnWrapper {
      > button {
        padding: 0.575rem 2.5rem;
      }
    }

    .modal-content {
      padding: 3rem 0.9375rem;
    }

    .confirm_msg {
      font-size: 18px;
      color: #777777;
      font-weight: 500;

      @media screen and (max-width: 769px) {
        font-size: 16px;
      }
    }
  }

  .title-logs {
    font-size: 18px;
    font-weight: 600;
  }
  .aui-modal-wrapper h5.auimodal-heading {
    font-size: 1.25rem;
  }
  @media screen and (max-width: 400px) {
    .title-logs {
      font-size: 18px;
    }
  }

  .aui-confirmationtext {
    font-size: 18px;
    @media screen and (max-width: 575px) {
      font-size: 16px;
    }
  }

  .my-custom-scrollbar {
    position: relative;
    height: inherit !important;
    overflow: auto;
  }

  .table-wrapper-scroll-y {
    display: block;
  }

  .my-custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  .my-custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #ebebeb;
    border-radius: 10px;
    background-color: #ebebeb;
  }

  /* Handle */
  .my-custom-scrollbar::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 0 0 2px grey;
  }

  .modal-dialog-logs {
    max-width: 650px !important;
  }

  .modal-body-logs {
    height: 300px;
  }

  .close-logs {
    width: 40px;
    background: #c10e21;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    height: inherit;
    border: none;
  }

  .cross-icon {
    color: #ffffff;
  }

  .title {
    padding-left: 10px;
    padding-top: 15px;
    font-size: 17px;
  }

  .close {
    padding-right: 1rem;
    padding-top: 1rem;
  }

  td,
  th {
    text-align: left;
  }

  .modal-row-label {
    padding-right: 0;
    font-weight: 600;
  }

  .modal-old-new {
    min-width: 10rem;
  }

  .modal-row-desc {
    min-width: 100px;
    font-weight: 500;
  }

  .modal-body-header {
    font-weight: 600;
    font-size: 20px;
    background-color: #ebebeb;
    padding: 1rem;
  }

  .modal-sub-heading {
    font-size: 16px;
    font-weight: 700;
  }

  .modal-report-log-sub-labels {
    word-break: keep-all;
    min-width: 100px;
  }

  .modal-report-log-sub-values {
    padding-right: 15px !important;
  }

  .diff-container {
    padding-left: 7px;
  }

  @media (max-width: 576px) {
    .table,
    .diff-container {
      width: 450px !important;
    }

    .my-custom-scrollbar::-webkit-scrollbar {
      width: 10px;
    }
  }

  .pt-1 {
    padding-top: 0.75rem !important;
  }

  .modal-xs {
    width: 25rem !important;
  }

  .save-report-modal-body {
    height: 10rem;
  }
`;

import { IToast } from "components/Toast/toast.slice";

const getDetailedErrorMessage = (toast: any) => {
  let message = "";
  if (toast.details && toast.details.length > 0) {
    message = toast.details.map((detail: any) => detail.message).join(", ");
  } else {
    message = toast.message ? toast.message : toast;
  }
  return message;
};

const ToastHandler = (toast: any) => {
  const visibility = toast.suppress ? !toast.suppress : true;
  const errorResponse: IToast = {};
  errorResponse.message = getDetailedErrorMessage(toast);
  errorResponse.type = toast.type ? toast.type : "error";

  errorResponse.title = (() => {
    if (toast.code !== undefined) {
      return `${toast.code}-`;
    } else if (toast.title) {
      return toast.title;
    } else {
      return "Error";
    }
  })();

  errorResponse.visibility = visibility;
  return errorResponse;
};

export default ToastHandler;

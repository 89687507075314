import { Routes } from "./routes";

export const CONSTANTS = {
  Categories: "Categories",
  Frequency: "Frequency",
  Vendors: "Vendors",
  Programs: "Programs",
  Accreditations: "Accreditations",
  Classifications: "Classifications",
  Certificates: "Certificates",
  SuccessActivity: "SuccessActivity",
  errorTypes: "Error Types",
  mismatchErrors: "Mismatch Errors",
  rejectedErrors: "Rejected Errors",
  rejected: "Rejected",
  mismatch: "Mismatch",
  ErrorDetails: "Error Details",
  errorFields: "errorFields",
  errorType: "errorType",
  learnerDetails: "learnerDetails",
  learnerDetail: "Learner Details",
  accreditationsUnderPrograms: "accreditationsUnderPrograms",
  successActivities: "SuccessActivities",
  errorActivities: "ErrorActivities",
  selectAll: "Select All"
}

export const customReportFields = [
  "accreditations",
  "certificates",
  "classifications",
  "programs",
  "vendors",
];

export const filterFields: any = [
  "accreditations",
  "certificates",
  "classifications",
  "endDate",
  "startDate",
  "programs",
  "vendors",
  "dateType",
  "programMode",
  "programSubType"
];

type FLAT_NODES_KEY_TYPES = {
  [key: string]: string;
};

// select fields page flatNodes key mapping with categories fields
export const FLAT_NODES_KEY: FLAT_NODES_KEY_TYPES = {
  [CONSTANTS.Vendors]: "1",
  [CONSTANTS.Programs]: "2",
  [CONSTANTS.Accreditations]: "4",
  [CONSTANTS.Classifications]: "5",
  [CONSTANTS.Certificates]: "6",
  [CONSTANTS.SuccessActivity]: "7",
  [CONSTANTS.errorTypes]: "8",
  [CONSTANTS.accreditationsUnderPrograms]: "9",
};

// Excluded routes to clear state
export const excludedCustomRoutes: string[] = [
  Routes.CUSTOM_REPORT_ADD_SCHEDULER,
  Routes.CUSTOM_REPORT_EDIT_SCHEDULER,
  Routes.CUSTOM_REPORTS_SELECT_FIELDS,
  Routes.CUSTOM_REPORT_PREVIEW,
  Routes.ADMIN_CUSTOM_REPORT,
  Routes.ACTIVITY_CUSTOM_REPORT
];

export type CategoryOption = {
  code: string;
  id: number;
};
export type CategoryOptionWithlabel = {
  label:string,
  code: string;
  id: number;
};

export const components: CategoryOptionWithlabel[] = [
  {
    label:"Vendors",
    code: "Vendors",
    id: 1,
  },
  {
    label:"Programs",
    code: "Programs",
    id: 2,
  },
  {
    label:"Accreditations",
    code: "Accreditations",
    id: 4,
  },
  {
    label:"Classifications",
    code: "Classifications",
    id: 5,
  },
  {
    label:"Certificates",
    code: "Certificates",
    id: 6,
  },
  {
    label:"Learner Details",
    code: "Learner Details",
    id: 3,
  },
  {
    label:"Accreditations Under Programs",
    code: "accreditationsUnderPrograms",
    id: 7,
  },
];

export const schedulerCategories: CategoryOption[] = [
  {
    code: "Vendors",
    id: 1,
  },
  {
    code: "Programs",
    id: 2,
  },
  {
    code: "Accreditations",
    id: 3,
  },
  {
    code: "Classifications",
    id: 4,
  },
  {
    code: "Certificates",
    id: 5,
  },
  {
    code: "Error Types",
    id: 6,
  },
];

export const activityCategories: CategoryOption[] = [
  {
    code: "Select All",
    id: 1
  },
  {
    code: "Vendors",
    id: 2
  },
  {
    code: "Accreditations",
    id: 3,
  },
  {
    code: "Certificates",
    id: 4,
  },
  {
    code: "Classifications",
    id: 5,
  },
  {
    code: "Programs",
    id: 6,
  }
]

export const activityDateTypeFields: any = [
  {
    id: 1,
    code: "claimDate",
    name: "Claim Date"
  },
  {
    id: 2,
    code: "claimExpirationDate",
    name: "Claim Expiration Date"
  },
  {
    id: 3,
    code: "registeredDate",
    name: "Registered Date"
  },
  {
    id: 4,
    code: "completedDate",
    name: "Completed Date"
  }
]

import { createContext, Dispatch, useContext, useReducer } from "react";
import { ReactNode } from "react";
import * as React from "react";
import { CONSTANTS, components } from "common/constants/customReports";

export interface IPagination {
  pageNumber: number;
  pageOffset: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isFirst: number;
  isLast: number;
}

export interface customReportFieldsType {
  id: string;
  code: string;
  label: string;
  refName?: string;
  children?: Array<customReportFieldsType>;
}

const defaultPaginationValues: IPagination = {
  pageNumber: 0,
  pageOffset: 0,
  pageSize: 0,
  totalCount: 0,
  totalPages: 0,
  isFirst: 0,
  isLast: 0,
};
export interface CustomReportType {
  categories: Array<any>;
  frequency: string;
  startDate: string;
  endDate: string;
  vendors?: Array<string>;
  programs?: Array<string>;
  accreditations?: Array<string>;
  classifications?: Array<string>;
  certificates?: Array<string>;
  mismatchedErrors?: Array<any>;
  rejectedErrors?: Array<any>;
  isErrorTypeSelected?: boolean;
  viewReportData?: Array<any>;
  pagination?: IPagination;
  customReportApiReqBody: any;
  customReportTableColumns: customReportFieldsType[];
  showViewComponent: boolean;
  isSavedFilterSelected: boolean;
  notifyMe: boolean;
  reportID: string;
  reportName: string;
  selectedFields: Array<any>;
  dateType: string;
  programSubtype: string;
  programMode: string,
}

export enum CustomReportStateConstants {
  setInitialState = "setInitialState",
  selectedCategories = "selectedCategories",
  selectedFrequency = "selectedFrequency",
  selectedDateRange = "selectedDateRange",
  selectedVendors = "selectedVendors",
  selectedPrograms = "selectedPrograms",
  selectedAccreditations = "selectedAccreditations",
  selectedClassifications = "selectedClassification",
  selectedCertificates = "selectedCertificates",
  selectedMismatchedErrors = "selectedMismatchedErrors",
  selectedRejectedErrors = "selectedRejectedErrors",
  toggleErrorTypeSelect = "toggleErrorTypeSelect",
  setViewReportData = "setViewReportData",
  setPagination = "setPagination",
  setApiRequestBody = "setApiRequestBody",
  setCustomReportTableColumns = "setCustomReportTableColumns",
  setShowViewComponent = "setShowViewComponent",
  setIsSavedFilterSelected = "setIsSavedFilterSelected",
  setNotifyMe = "setNotifyMe",
  setReportID = "setReportID",
  setReportName = "setReportName",
  setSelectedFields = "setSelectedFields",
}

export interface IAction<T, P> {
  type: T;
  payload?: Partial<P>;
}

export type IActionType = IAction<CustomReportStateConstants, CustomReportType>;

export const initialState: CustomReportType = {
  categories: [],
  frequency: "",
  startDate: "",
  endDate: "",
  vendors: [],
  programs: [],
  accreditations: [],
  classifications: [],
  certificates: [],
  mismatchedErrors: [],
  rejectedErrors: [],
  isErrorTypeSelected: false,
  viewReportData: [],
  pagination: defaultPaginationValues,
  customReportApiReqBody: {},
  customReportTableColumns: [],
  showViewComponent: false,
  isSavedFilterSelected: false,
  notifyMe: false,
  reportID:"",
  reportName: "",
  selectedFields: [],
  dateType: "",
  programSubtype: "",
  programMode: "",
};

export const customReportReducer = (
  state: CustomReportType,
  action: IActionType
) => {
  switch (action.type) {
    case CustomReportStateConstants.setInitialState: {
      return initialState;
    }
    case CustomReportStateConstants.selectedCategories: {
      let stateClone = {...state}
      let categories = action.payload?.categories || [];

      for (const element of components) {
        let component = element.code;
        let componentFound = false;

        for (let j = 0; j < categories.length; j++) {
          if (component === categories[j].code) {
            componentFound = true;
          }
        }

        if (!componentFound) {
          component === CONSTANTS.Vendors && (stateClone.vendors = []);
          component === CONSTANTS.Programs && (stateClone.programs = []);
          component === CONSTANTS.Accreditations && (stateClone.accreditations = []);
          component === CONSTANTS.Classifications &&
            (stateClone.classifications = []);
          component === CONSTANTS.Certificates && (stateClone.certificates = []);
        }
      }

      stateClone.categories = categories;
      return stateClone;
    }
    case CustomReportStateConstants.selectedFrequency: {
      return { ...state, frequency: action.payload?.frequency || "" };
    }
    case CustomReportStateConstants.selectedDateRange: {
      return {
        ...state,
        startDate: action?.payload?.startDate || "",
        endDate: action.payload?.endDate || "",
      };
    }
    case CustomReportStateConstants.selectedVendors: {
      return { ...state, vendors: action.payload?.vendors || [] };
    }
    case CustomReportStateConstants.selectedPrograms: {
      return { ...state, programs: action.payload?.programs || [] };
    }
    case CustomReportStateConstants.selectedAccreditations: {
      return { ...state, accreditations: action.payload?.accreditations || [] };
    }
    case CustomReportStateConstants.selectedClassifications: {
      return {
        ...state,
        classifications: action.payload?.classifications || [],
      };
    }
    case CustomReportStateConstants.selectedCertificates: {
      return { ...state, certificates: action.payload?.certificates || [] };
    }
    case CustomReportStateConstants.selectedMismatchedErrors: {
      return {
        ...state,
        mismatchedErrors: action.payload?.mismatchedErrors || [],
      };
    }
    case CustomReportStateConstants.selectedRejectedErrors: {
      return { ...state, rejectedErrors: action.payload?.rejectedErrors || [] };
    }
    case CustomReportStateConstants.toggleErrorTypeSelect: {
      return {
        ...state,
        isErrorTypeSelected: action.payload?.isErrorTypeSelected || false,
      };
    }
    case CustomReportStateConstants.setViewReportData: {
      let reportData = JSON.parse(JSON.stringify(action.payload?.viewReportData || []))

      reportData.map((ele : any , index : any) => {
        if(ele.hasOwnProperty("classification")){
          ele["classification"] = ele["classification"]?.replace(/,/g, ", ")
        }
        if(ele.hasOwnProperty("specialties")){
          ele["specialties"] = ele["specialties"]?.replace(/,/g, ", ")
        }
        if(ele.hasOwnProperty("qualification")){
          ele["qualification"] = ele["qualification"]?.replace(/,/g, ", ")
        }
      })

      return { ...state, viewReportData:  reportData || [] };
    }
    case CustomReportStateConstants.setPagination: {
      return {
        ...state,
        pagination: action.payload?.pagination || defaultPaginationValues,
      };
    }
    case CustomReportStateConstants.setApiRequestBody: {
      return {
        ...state,
        customReportApiReqBody: action.payload?.customReportApiReqBody || {},
      };
    }
    case CustomReportStateConstants.setCustomReportTableColumns: {
      return {
        ...state,
        customReportTableColumns:
          action.payload?.customReportTableColumns || [],
      };
    }
    case CustomReportStateConstants.setShowViewComponent: {
      return {
        ...state,
        showViewComponent: action.payload?.showViewComponent || false,
      };
    }
    case CustomReportStateConstants.setIsSavedFilterSelected: {
      return {
        ...state,
        isSavedFilterSelected: action.payload?.isSavedFilterSelected || false,
      }
    }
    case CustomReportStateConstants.setNotifyMe: {
      return {
        ...state,
        notifyMe : action.payload?.notifyMe || false,
      }
    }
    case CustomReportStateConstants.setReportID: {
      return {
        ...state,
        reportID : action.payload?.reportID || "",
      }
    }
    case CustomReportStateConstants.setReportName: {
      return {
        ...state,
        reportName : action.payload?.reportName || "",
      }
    }
    case CustomReportStateConstants.setSelectedFields: {
      return {
        ...state,
        selectedFields: action.payload?.selectedFields || [],
      }
    }
    default: {
      return state;
    }
  }
};

interface IProps {
  children: ReactNode;
}

interface ICustomReportContext {
  customReportState: CustomReportType;
  dispatch: Dispatch<IActionType>;
}

export const initialCustomReportContext: ICustomReportContext = {
  customReportState: initialState,
  dispatch: () => null,
};

export const CustomReportContext = createContext(initialCustomReportContext);

export const useCustomReportContext = (): ICustomReportContext =>
  useContext(CustomReportContext);

export const CustomReportContextProvider = (props: IProps) => {
  const [customReportState, dispatch] = useReducer(
    customReportReducer,
    initialState
  );

  return (
    <CustomReportContext.Provider value={{ customReportState, dispatch }}>
      {props.children}
    </CustomReportContext.Provider>
  );
};

import React, { FunctionComponent, ReactNode, useMemo } from "react";
import classNames from "classnames";
import { mapToCssModules } from "./utils";
import { AccordionContext } from "./AccordionContext";
import { CSSModule, InnerRef, TagPropType } from "./types";

export interface AccordionPropTypes {
  children?: ReactNode;
  /** Add custom class */
  className?: string;
  /** Change existing className with a new className */
  cssModule?: CSSModule;
  /** Render accordions edge-to-edge with their parent container */
  flush?: boolean;
  innerRef?: InnerRef;
  /** The current active key that corresponds to the currently expanded card */
  open: string[] | string;
  /** Set a custom element for this component */
  tag?: TagPropType;
  /** Function that's triggered on clicking `AccordionHeader` */
  toggle: (id: string) => void;

  [key: string]: any;
}

export const Accordion: FunctionComponent<AccordionPropTypes> = (props) => {
  const {
    flush,
    open,
    toggle,
    className,
    cssModule,
    tag: Tag = "div",
    innerRef,
    ...attributes
  } = props;
  const classes = mapToCssModules(
    classNames(className, "accordion", {
      "accordion-flush": flush,
    }),
    cssModule
  );

  const accordionContext = useMemo(
    () => ({
      open,
      toggle,
    }),
    [open, toggle]
  );

  return (
    <AccordionContext.Provider value={accordionContext}>
      {Tag == "ul" ? (  <ul {...attributes} className={classes}  />):(  <div {...attributes} className={classes}  />)}
      {/* <Tag {...attributes} className={classes} ref={innerRef} /> */}
    </AccordionContext.Provider>
  );
};

import styled from "styled-components";
export const SignInWrapper = styled.div`
  .main-container {
    margin-top: 0;
    .ce-main-logo-div {
      width: 100%;
      border-bottom: 1.5px solid #e3e3e3;
      .main-content-logo {
        margin: 1rem 0 1rem 1rem;
        width: 15rem;
      }
    }
  
    aui-banner {
      min-height: calc(100vh - 258px);
      display: flex;
      align-items: center;
      .aui-banner {
        width: 66rem;
        padding: 0;
        @media only screen and (max-width: 991px) {
          padding: 12rem 0.625rem 0.625rem;
        }
        .aui-dr-img {
          background-image: url(../images/iStock-941446382_super.jpg);
        }
        .aui-banner-img {
          max-height: calc(100vh - 268px);
          min-height: 300px;
          width: 37.3125rem;
          @media only screen and (max-width: 991px) {
            width: 100%;
          }
        }
      }
      .aui-banner-content {
        width: 36rem;
        padding: 1.125rem 1.375rem 1.375rem;
        @media only screen and (max-width: 991px) {
          width: 100%;
        }
        h1 {
          font: 600 30px / 26px Montserrat;
          margin-bottom: 1rem;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
`;
import styled from "styled-components";

const NoRecordFoundWrapper = styled.div`
  .aui-not-found-wrapper {
    height: 100vh;
    padding: 0 30px;
    button {
      display: none;
    }
    .aui-not-found-image {
      font-size: 160px;
      @media screen and (max-width: 575px) {
        font-size: 110px;
      }
      @media screen and (max-width: 480px) {
        width: initial;
      }
    }
    .aui-error-divider {
      @media screen and (max-width: 575px) {
        height: 1px;
        width: 100%;
      }
    }
    h1 {
      @media screen and (max-width: 575px) {
        font-size: 16px;
      }
    }
  }
`;
export default NoRecordFoundWrapper;

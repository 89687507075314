import React, { useEffect, useState, FunctionComponent, useRef } from "react";
import {
  CustomReportsWrapper,
  EditCustomReportSchedulerWrapper,
  GenerateReportWrapper,
} from "./Styled";
import Breadcrumb from "components/Breadcrumb/Breadcrumb";
import { breadcrumbRoutes } from "components/Breadcrumb/BreadcrumbRoutes";
import { useTranslation } from "react-i18next";
import { RenderTextField } from "components/InputFields/RenderTextField";
import { DateOnly } from "common/utils/dateBasedOnLocale";
import { CheckBox } from "components/InputFields/RanderInputCheckBox";
import RichTextEditor from "react-rte";
import PageNotFound from "pages/PageNotFound";
import { RenderInputSelect } from "components/InputFields/RenderInputSelect";
import Select, { ActionMeta } from "react-select";
import {
  ListAccreditationByFilter,
  ListVendorByFilter,
  ListClassificationByFilter,
  GetProgramListByFilter,
  PutCustomSchedulerById,
  ListCustomReportColumns,
} from "services/api/report.api";
import { GetCertificationByFilter } from "services/api/Certification.api";
import {
  MismatchErrorTags,
  RejectedErrorTags,
  Routes,
  reportFrequency,
} from "common/constants";
import CustDateRangePicker from "components/DateRangePicker";
import Option from "components/InputFields/RenderMultiSelectInputField";
import AsyncSelect from "react-select/async";
import { useDebouncedCallback } from "use-debounce";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { useDispatch, useSelector } from "react-redux";
import { format, subDays, subMonths, subYears } from "date-fns";
import _ from "lodash";
import CONSTANTS from "common/constants/constants";
import { exportType } from "common/constants/report";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  CONSTANTS as CUSTOM_CONSTANTS,
  CategoryOption,
  components,
  schedulerCategories,
} from "common/constants/customReports";
import {
  setVendor as setVendorRedux,
  setProgram as setProgramRedux,
  setAccreditation as setAccreditationRedux,
  setClassification as setClassificationRedux,
  setCertificate as setCertificateRedux,
  setFrequency,
  setDateRange,
  setSchedulerState,
  setNotificationState,
  setMessage,
  setReportPeriod,
  setSchedulerDates,
  setReportDetails,
  setSelectedFields,
  setFilters,
  NotificationDetails,
  setInitialSchedulerData,
  setMisMatchedErrors,
  setRejectedErrors,
  setInitialSelectedFields,
  setCategory,
} from "pages/Report/CustomReport/CustomReportSlice";
import ReportPeriodPopover from "components/ReportPeriodPopover";
import { RouterProps } from "react-router";
import classNames from "classnames";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import { clearSchedulerState } from "pages/ReportScheduler/SchedulerSlice";
import { GetCustomSchedulerApi } from "services/api/report.api";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { handleAPIError } from "common/ErrorHandler";
import { customReportFields } from "common/constants/customReports";
import { convertListToString } from "common/utils/commonFunctionality";
import { AsyncPaginate, ShouldLoadMore } from "react-select-async-paginate";
import RenderAuiTextAreaField from "components/InputFields/RenderTextAreaFieldV2";
import AuiIcon from "components/IconComponent/AuiIcon";

export type EditCustomReportSchedulerProps = RouterProps;

interface FlatNode {
  id: string;
  label: string;
  code: string;
  refName?: string;
  children?: FlatNode[];
}
interface CustomFieldType {
  accreditations: Array<string>;
  certificates: Array<string>;
  classifications: Array<string>;
  errorActivities?: Array<string>;
  learnerDetails: Array<string>;
  programAccreditations: Array<string>;
  programs: Array<string>;
  successActivities: Array<string>;
}
interface FiltersType {
  vendors: Array<string>;
  programs: Array<string>;
  accreditations: Array<string>;
  classifications: Array<string>;
  certificates: Array<string>;
  frequency: string;
  startDate: string;
  endDate: string;
  errorFields: Array<string>;
  errorType: string;
}
interface ReportDetailsType {
  customField: CustomFieldType;
  filters: FiltersType;
}
interface EditApiPayloadType {
  title: string;
  startDate: string;
  endDate: string;
  runTime: string;
  isActive: boolean;
  meta?: any;
  reportPeriod: any;
  omitReport: boolean;
  notificationDetails: NotificationDetails;
  reportDetails: ReportDetailsType;
}

interface ReportDetailType {
  frequency: string;
  dateRange: string;
  startDate: string;
  endDate: string;
  vendors: string;
  accreditations: string;
  programs: string;
  classifications: string;
  certificates: string;
  mismatchedErrors: string;
  rejectedErrors: string;
}

interface EditSchedulerDataType {
  id: number;
  title: string;
  startDate: string;
  endDate: string;
  runTime: string;
  isActive: boolean;
  reportPeriod: any;
  omitReport: boolean;
  notificationDetails: NotificationDetails;
  reportDetails: ReportDetailType;
}

const EditCustomReportScheduler: FunctionComponent<
  EditCustomReportSchedulerProps
> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [editSchedulerData, setSchedulerData] = useState<EditSchedulerDataType>(
    {
      id: 0,
      title: "",
      startDate: "",
      endDate: "",
      runTime: "",
      reportPeriod: {},
      omitReport: true,
      isActive: true,
      reportDetails: {
        frequency: "",
        dateRange: "",
        startDate: "",
        endDate: "",
        vendors: "",
        accreditations: "",
        programs: "",
        classifications: "",
        certificates: "",
        mismatchedErrors: "",
        rejectedErrors: "",
      },
      notificationDetails: {
        email: "",
        subject: "",
        message: "",
        format: "xlsx",
        notifyMe: true,
      },
    }
  );

  const params = useParams<{ id: string }>();

  const customReportingState = useSelector((state: any) => state.customReport);

  const [vendors, setVendors] = useState<any[]>([]);
  const [accreditations, setAccreditation] = useState<any[]>([]);
  const [programs, setProgram] = useState<any[]>([]);
  const [classifications, setClassifications] = useState<any[]>([]);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [isInvalidID, setIsInvalidID] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [programSearchResult, setProgramSearchResult] = useState([]);

  const [frequencyRequiredClass, setFrequencyRequiredClass] =
    useState<boolean>(false);
  const [dateRangeRequiredClass, setDateRangeRequiredClass] =
    useState<boolean>(false);
  const [dateRangeErrorClass, setDateRangeErrorClass] =
    useState<boolean>(false);
  const [isDateDisabled, setDateDisabled] = useState<boolean>(true);
  const [messageValue, setMessageValue] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [showReportPeriod, setShowReportPeriod] = useState<boolean>(false);

  const [placeHolder, setPlaceHolder] = useState("Select");
  const [flag, setFlag] = useState(false);

  const [, setFormType] = useState<any>("AddForm");

  const [disableSave, setDisableSave] = useState<boolean>(false);

  const [intitialCategorySelected, setInitialCategorySelected] = useState<
    string[]
  >([]);

  const [isEndDateDisabled, setEndDateDisabled] = useState<boolean>(false);
  const [isRunTimeDisabled, setRunTimeDisabled] = useState<boolean>(false);
  const [emailError, setEmailError] = useState(false);
  const textareaRef: any = useRef(null);

  const navigateToListPage = () => {
    history.goBack();
  };

  function dropdownBlur(e: any) {
    if (
      (e.target as HTMLInputElement).className === "custom-span" ||
      (e.target as HTMLInputElement).className === "select-span"
    ) {
      setFlag(false);
    } else if (
      (e.target as HTMLInputElement).className ===
      "form-control custom-dropdown"
    ) {
      setFlag(!flag);
    } else {
      setFlag(false);
    }
  }

  function ConvertToJson(input: Array<string> = []) {
    const json: any = {};
    input?.forEach((val: string) => {
      json[val.split(":")[0]] = val.split(":")[1];
    });

    if (json !== "{}") {
      setSchedulerData((data: any) => {
        return {
          ...data,
          reportPeriod: json,
        };
      });
    }
  }

  const getVendors = async () => {
    try {
      const vendorParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListVendorByFilter(vendorParams);
      const vendorsData = response?.data?.data?.vendors;
      setVendors(vendorsData ?? []);
    } catch (e) {
      setVendors([]);
    }
  };

  const getAccreditations = async () => {
    try {
      const accreditationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "",
      };
      const response = await ListAccreditationByFilter(accreditationParams);
      const accreditationsData = response?.data?.data?.accreditations;
      const accreditationList = accreditationsData.map(
        (accreditation: any) => ({
          id: accreditation.id,
          code: accreditation.code,
        })
      );
      setAccreditation(accreditationList ?? []);
    } catch (e) {
      setAccreditation([]);
    }
  };

  const getClassifications = async () => {
    try {
      const classificationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListClassificationByFilter(classificationParams);
      const classificationsData = response?.data?.data?.categories;
      const accreditationList = classificationsData.map(
        (classification: any) => ({
          id: classification.id,
          code: classification.code,
          name: classification.name,
        })
      );

      setClassifications(accreditationList ?? []);
    } catch (e) {
      setClassifications([]);
    }
  };

  const getCertificates = async (
    accreditationCode: any = "",
    certificates: any = []
  ) => {
    try {
      const certificateParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
        accreditationCode: accreditationCode,
      };

      const response = await GetCertificationByFilter(certificateParams);
      const certificatesData = response?.data?.data?.certificateTemplates;
      const certificateList = certificatesData.map((certificate: any) => ({
        id: certificate.id,
        code: certificate.code,
        name: certificate.name,
      }));

      let initialCertificate =
        customReportingState.reportDetails.certificates &&
        customReportingState.reportDetails.certificates?.split(",")?.length > 0
          ? customReportingState.reportDetails.certificates.split(",")
          : certificates;
      let selectedCertificates = initialCertificate?.filter((code: any) =>
        certificateList.some((obj) => obj.code === code)
      );
      if (selectedCertificates) {
        dispatch(
          setCertificateRedux({
            ...customReportingState,
            certificates: selectedCertificates?.join(","),
          })
        );
      }
      setCertificates(certificateList ?? []);
    } catch (e) {
      setCertificates([]);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    const fieldValue =
      name === "omitReport" ? event.target.checked : value.trim();

    if (name === "reportPeriod") {
      setShowReportPeriod(true);
      setFormType(value);
    }

    dispatch(
      setSchedulerState({
        ...customReportingState,
        key: name,
        value: fieldValue,
      })
    );

    setSchedulerData({
      ...editSchedulerData,
      [name]: fieldValue,
    });
  };

  const setReportDates = (value: string) => {
    let endDate: any = new Date();
    let startDate: any;
    let formattedStartDate = "";
    let formattedEndDate = "";
    switch (value) {
      case "weekly":
        startDate = subDays(new Date(), 7);
        break;
      case "monthly":
        startDate = subMonths(new Date(), 1);
        break;
      case "quarterly":
        startDate = subMonths(new Date(), 3);
        break;
      case "half_yearly":
        startDate = subMonths(new Date(), 6);
        break;
      case "yearly":
        startDate = subYears(new Date(), 1);
        break;
      default:
        startDate = "";
        endDate = "";
    }

    if (!_.isEmpty(value)) {
      formattedStartDate = `${startDate.getFullYear()}-${(
        "0" +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;
      formattedEndDate = `${endDate.getFullYear()}-${(
        "0" +
        (endDate.getMonth() + 1)
      ).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;
    }

    return { formattedStartDate, formattedEndDate };
  };

  const enableDateRange = (value: string) => {
    if (value === "custom_range") {
      setDateDisabled(false);
      setDateRangeErrorClass(false);
      setDateRangeRequiredClass(true);
    } else {
      setDateDisabled(true);
      setDateRangeErrorClass(false);
      setDateRangeRequiredClass(false);
    }
  };

  const handleReportDetail = (event: any) => {
    const { name, value } = event.target;
    const reportDetail: any = editSchedulerData.reportDetails;

    if (value === "custom_range") {
      dispatch(
        setFrequency({
          ...customReportingState,
          frequency: value.trim(),
          startDate: "",
          endDate: "",
        })
      );
    }

    if (name === "frequency" && value !== "custom_range") {
      const { formattedStartDate, formattedEndDate } = setReportDates(value);
      setSchedulerData({
        ...editSchedulerData,
        reportDetails: {
          ...reportDetail,
          [name]: value.trim(),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
      });

      dispatch(
        setFrequency({
          ...customReportingState,
          frequency: value.trim(),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        })
      );

      getPrograms(
        editSchedulerData?.reportDetails?.vendors,
        editSchedulerData?.reportDetails?.accreditations,
        editSchedulerData?.reportDetails?.classifications,
        editSchedulerData?.reportDetails?.certificates,
        formattedStartDate,
        formattedEndDate,
        customReportingState?.reportDetails?.programs,
        customReportingState?.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CUSTOM_CONSTANTS.Programs
        )
      );
    } else {
      setSchedulerData({
        ...editSchedulerData,
        reportDetails: {
          ...reportDetail,
          [name]: value.trim(),
        },
      });
    }
    if (name === "frequency") {
      enableDateRange(value);
    }
  };

  const getProgramApiParams = (
    vendor: string,
    accreditation: string,
    classification: string,
    certificate: string,
    startDate: string,
    endDate: string,
    program: string = "",
    searchProgram: string = "",
    pageSize: number = 25,
    pageNumber: number = 1,
    pageOrder: string = ""
  ) => {
    let reportType = "custom_report";
    const programParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      pageOrder: pageOrder,
      vendor: "",
      program: "",
      searchProgram: "",
      accreditation: "",
      classification: "",
      certificate: "",
      reportType: "",
      startDate: "",
      endDate: "",
    };

    if (!_.isEmpty(startDate)) {
      programParams.startDate = startDate;
    }

    if (!_.isEmpty(endDate)) {
      programParams.endDate = endDate;
    }

    if (!_.isEmpty(reportType)) {
      programParams.reportType = reportType;
    }

    if (!_.isEmpty(vendor)) {
      programParams.vendor = vendor;
    }

    if (!_.isEmpty(accreditation)) {
      programParams.accreditation = accreditation;
    }

    if (!_.isEmpty(classification)) {
      programParams.classification = classification;
    }

    if (!_.isEmpty(certificate)) {
      programParams.certificate = certificate;
    }

    if (!_.isEmpty(program)) {
      programParams.program = program;
    }

    if (!_.isEmpty(searchProgram)) {
      programParams.searchProgram = searchProgram;
    }

    return programParams;
  };

  const getPrograms = async (
    vendor: any,
    accreditation: any,
    classification: any,
    certificate: any,
    startDate: string,
    endDate: string,
    program: string = "",
    isProgramFieldSelected: boolean = false
  ) => {
    // Return if program field is not selected
    if (!isProgramFieldSelected) {
      return;
    }

    const programParams = getProgramApiParams(
      vendor,
      accreditation,
      classification,
      certificate,
      startDate,
      endDate,
      program
    );

    let programs = [];
    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      if (!_.isEmpty(program)) {
        // to get the selected programs with program filter
        const response = await GetProgramListByFilter(programParams);
        programs = response?.data?.data?.programs;
      }
      let programsWithFilter = programs.map((program: any) => program.code);
      let selectedPrograms: any = program
        .split(",")
        ?.filter((selectedProgram: any) =>
          programsWithFilter.includes(selectedProgram)
        )
        .join(","); //Remove those programs from selected list which are not part of filter(Accreditation, certificate, classification)

      dispatch(
        // To update selected programs list after adding filter
        setProgramRedux({ ...customReportingState, programs: selectedPrograms })
      );

      setProgram(programs ?? []);

      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    } catch (e) {
      setProgram([]);
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  const checkEmpty = (value: any) => {
    if (_.isEmpty(value)) {
      setDateRangeErrorClass(true);
      return false;
    } else {
      setDateRangeErrorClass(false);
      return true;
    }
  };

  let validationErros: any = [];

  const validateScheduleForm = () => {
    let isValid = true;
    let isEndDateValid = true;

    if (editSchedulerData.reportDetails.frequency === "custom_range") {
      isValid = checkEmpty(editSchedulerData.reportDetails.dateRange);
    } else {
      setDateRangeErrorClass(false);
    }

    if (
      editSchedulerData?.endDate !== "" &&
      editSchedulerData?.runTime !== ""
    ) {
      const formattedDate = new Date(
        editSchedulerData?.endDate + " " + editSchedulerData?.runTime
      );
      if (formattedDate < new Date()) {
        isEndDateValid = false;
        validationErros.push(CONSTANTS.END_DATE_TIME_ERROR);
      }
    }

    if (validationErros.length > 0) {
      store.dispatch(
        showToast({
          title: t<string>("EditScheduler"),
          message: validationErros.join(","),
        })
      );
    }

    return isValid && isEndDateValid;
  };

  const validateEmail = () => {
    const email = editSchedulerData.notificationDetails.email;
    if (_.isEmpty(email)) {
      setEmailError(true);
      textareaRef.current.scrollIntoView({ behavior: "smooth" });
      return false;
    }
    const emailList = email.replace(/\s/g, "").split(",");
    const invalidEmails = [];

    emailList.forEach((item: any) => {
      if (
        item.trim() !== "" &&
        !String(item.trim())
          .toLowerCase()
          .match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
          )
      ) {
        invalidEmails.push(item);
      }
    });

    if (invalidEmails.length > 0) {
      validationErros.push("Invalid email");
    }

    if (validationErros.length > 0) {
      store.dispatch(
        showToast({
          title: t<string>("EditScheduler"),
          message: validationErros.join(","),
        })
      );

      return false;
    }
    return true;
  };

  const validateReportPeriod = () => {
    if (!editSchedulerData.reportPeriod.FREQ) {
      validationErros.push(t<string>("reportPeriodRequired"));
    }
    if (validationErros.length > 0) {
      store.dispatch(
        showToast({
          title: t<string>("addScheduler"),
          message: validationErros.join(","),
        })
      );
      return false;
    }
    return true;
  };

  const updateScheduleData = (curEditSchedulerData: any) => {
    if (
      curEditSchedulerData.startDate !== "" &&
      editSchedulerData.runTime !== ""
    ) {
      const formattedDate = new Date(
        curEditSchedulerData.startDate + " " + curEditSchedulerData.runTime
      );
      const formattedDateUTC = formattedDate.toISOString();
      curEditSchedulerData.startDate = `${formattedDateUTC.substring(0, 10)}`;
      curEditSchedulerData.runTime = `${formattedDateUTC.substring(11, 16)}`;
    }
  };

  const generateRequestBody = () => {
    let requestBody: EditApiPayloadType = {
      title: editSchedulerData.title,
      startDate: editSchedulerData.startDate,
      endDate: editSchedulerData.endDate,
      runTime: editSchedulerData.runTime,
      reportPeriod: editSchedulerData.reportPeriod,
      omitReport: editSchedulerData.omitReport,
      isActive: true,
      meta: {},
      notificationDetails: editSchedulerData.notificationDetails,
      reportDetails: {
        customField: customReportingState?.filters?.customField,
        filters: {
          vendors: !_.isEmpty(editSchedulerData.reportDetails?.vendors)
            ? editSchedulerData.reportDetails?.vendors.split(",")
            : [],
          accreditations: !_.isEmpty(
            editSchedulerData.reportDetails?.accreditations
          )
            ? editSchedulerData.reportDetails?.accreditations.split(",")
            : [],
          classifications: !_.isEmpty(
            editSchedulerData.reportDetails?.classifications
          )
            ? editSchedulerData.reportDetails?.classifications.split(",")
            : [],
          certificates: !_.isEmpty(
            editSchedulerData.reportDetails?.certificates
          )
            ? editSchedulerData.reportDetails?.certificates.split(",")
            : [],
          programs: !_.isEmpty(editSchedulerData.reportDetails?.programs)
            ? editSchedulerData.reportDetails?.programs.split(",")
            : [],
          frequency: editSchedulerData.reportDetails?.frequency ?? "",
          startDate: editSchedulerData.reportDetails?.startDate ?? "",
          endDate: editSchedulerData.reportDetails?.endDate ?? "",
          errorFields: !_.isEmpty(
            editSchedulerData.reportDetails?.mismatchedErrors
          )
            ? editSchedulerData.reportDetails?.mismatchedErrors.split(",")
            : !_.isEmpty(editSchedulerData.reportDetails?.rejectedErrors)
            ? editSchedulerData.reportDetails?.rejectedErrors.split(",")
            : [],
          errorType: !_.isEmpty(
            editSchedulerData.reportDetails?.mismatchedErrors
          )
            ? CUSTOM_CONSTANTS.mismatch
            : !_.isEmpty(editSchedulerData.reportDetails?.rejectedErrors)
            ? CUSTOM_CONSTANTS.rejected
            : "",
        },
      },
    };

    return requestBody;
  };

  const formDataSubmit = async (event: any) => {
    event.preventDefault();

    if (
      _.isEqual(customReportingState.initialSchedulerData, editSchedulerData) &&
      _.isEqual(
        customReportingState.initialSelectedFields,
        customReportingState.selectedFields
      )
    ) {
      setTimeout(function () {
        props.history.replace(`/schedulers`);
      }, 500);
      return;
    }
    dispatch(setPageLoadingStatus({ isPageLoading: true }));
    const isValid =
      validateScheduleForm() && validateEmail() && validateReportPeriod();

    if (isValid) {
      updateScheduleData(editSchedulerData);
      try {
        const reqPayload: EditApiPayloadType = generateRequestBody();
        const res = await PutCustomSchedulerById(
          parseInt(params.id),
          reqPayload
        );
        if (res.status === 200) {
          const timer = setInterval(async () => {
            const getSchedulerData = await GetCustomSchedulerApi(
              res?.data?.data?.scheduler?.id
            );
            if (getSchedulerData?.data?.status === 200) {
              store.dispatch(
                showToast({
                  type: "success",
                  title: "Edit Scheduler - ",
                  message: "Custom Report Scheduler Updated Successfully!",
                })
              );
            } else {
              store.dispatch(
                showToast({
                  type: "error",
                  title: "Edit Scheduler - ",
                  message:
                    getSchedulerData?.data?.error?.details[0]?.message ||
                    getSchedulerData?.data?.error?.message ||
                    "Custom Report Scheduler Update Failed!",
                })
              );
            }

            dispatch(setPageLoadingStatus({ isPageLoading: false }));
            setTimeout(function () {
              clearInterval(timer);
              props.history.replace(`/schedulers`);
            }, 2000);
          }, 2000);
        }
      } catch (error) {
        handleAPIError(error, history, dispatch);
        dispatch(setPageLoadingStatus({ isPageLoading: false }));
      }
    } else {
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
      validationErros = [];
    }
  };

  useEffect(() => {
    const clearLocalStorageOnReload = () => {
      localStorage.setItem("_prevRoute", JSON.stringify([]));
    };

    window.addEventListener("beforeunload", clearLocalStorageOnReload);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorageOnReload);
    };
  }, []);

  const GetID = (filter: any, toFind: string): any => {
    for (const element of filter) {
      if (element.code === toFind) {
        return element.id;
      }

      if (element.hasOwnProperty("children")) {
        const id = GetID(element.children, toFind);
        if (id) {
          return id;
        }
      }
    }
  };

  const updateSelectedFields = (fields: any, customFields: any) => {
    let selected: string[] = [];

    for (const [key, value] of Object.entries(customFields)) {
      if (Array.isArray(value) && value.length > 0) {
        let filteredField: any[] = [];

        if (key === "programAccreditations") {
          filteredField = fields
            .filter((item: any) => item.code === "programs")[0]
            .children.filter((item: any) => item.code === key)[0];
        } else {
          filteredField = fields.filter((item: any) => item.code === key)[0];
        }

        value.forEach((item: string) => {
          const id = GetID((filteredField as any)?.children, item);
          selected = [...selected, id];
        });
      }
    }

    dispatch(
      setSelectedFields({
        ...customReportingState,
        selectedFields: selected,
      })
    );

    return Promise.resolve(selected);
  };

  const updateCategories = (filters: any) => {
    let selectedCategories: CategoryOption[] = [];
    // Generate the categories object and store in redux.
    for (const [key, value] of Object.entries(filters)) {
      components.forEach((item: CategoryOption) => {
        if (
          item.code === _.capitalize(key) &&
          Array.isArray(value) &&
          value.length > 0
        ) {
          selectedCategories.push(item);
        }
        if (
          key == CUSTOM_CONSTANTS.errorType &&
          value &&
          item.code === CUSTOM_CONSTANTS.errorTypes
        ) {
          selectedCategories.push(item);
        }
      });
    }

    dispatch(
      setCategory({
        ...customReportingState,
        categories: selectedCategories,
      })
    );

    return selectedCategories;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetCustomSchedulerApi(params.id);

        if (response.data.status === 200) {
          const data: any = response.data?.data;
          const customField: any = data?.scheduler?.reportDetails?.customField;

          const customReportColumns = await ListCustomReportColumns();

          const fields: FlatNode[] =
            customReportColumns.data?.data?.customColumn?.custom_report_column
              ?.customFields;

          const selected = await updateSelectedFields(fields, customField);

          dispatch(
            setInitialSelectedFields({
              ...customReportingState,
              initialSelectedFields: selected,
            })
          );

          const startDateTime = new Date(
            data.scheduler.startDate + "T" + data.scheduler.runTime + "Z"
          );

          const startDate = format(startDateTime, "yyyy-MM-dd");
          const runTime = format(startDateTime, "HH:mm");

          let schedulerDetail = {
            id: data?.scheduler?.id,
            title: data?.scheduler?.title,
            startDate: startDate,
            endDate: data?.scheduler?.endDate,
            runTime: runTime,
            reportPeriod: data?.scheduler?.reportPeriod,
            omitReport: data?.scheduler?.omitReport,
            status: data?.scheduler?.status,
          };

          let reportDetail = data?.scheduler?.reportDetails?.filters;
          let notificationDetail = data?.scheduler?.notificationDetails;

          const selectedCategories = updateCategories(reportDetail);

          let reportDetails = {
            vendors: reportDetail?.vendors?.join(","),
            programs: reportDetail?.programs?.join(","),
            accreditations: reportDetail?.accreditations?.join(","),
            classifications: reportDetail.classifications?.join(","),
            certificates: reportDetail?.certificates?.join(","),
            frequency: reportDetail?.frequency,
            startDate: reportDetail?.startDate,
            endDate: reportDetail?.endDate,
            mismatchedErrors:
              reportDetail?.errorType === CUSTOM_CONSTANTS.mismatch
                ? reportDetail?.errorFields.join(",")
                : "",
            rejectedErrors:
              reportDetail?.errorType === CUSTOM_CONSTANTS.rejected
                ? reportDetail?.errorFields.join(",")
                : "",
          };

          if (!_.isEmpty(data) && data.hasOwnProperty("scheduler")) {
            let schedulerData: EditSchedulerDataType = {
              ...editSchedulerData,
              id: schedulerDetail?.id,
              title: schedulerDetail?.title,
              startDate: schedulerDetail?.startDate,
              endDate: schedulerDetail?.endDate,
              runTime: schedulerDetail?.runTime,
              reportPeriod: schedulerDetail?.reportPeriod,
              omitReport: schedulerDetail?.omitReport,
              reportDetails: {
                ...editSchedulerData.reportDetails,
                vendors: reportDetail?.vendors?.join(","),
                programs: reportDetail?.programs?.join(","),
                accreditations: reportDetail?.accreditations?.join(","),
                classifications: reportDetail.classifications?.join(","),
                certificates: reportDetail?.certificates?.join(","),
                frequency: reportDetail?.frequency,
                startDate: reportDetail?.startDate,
                endDate: reportDetail?.endDate,
                dateRange:
                  !_.isEmpty(reportDetail?.startDate) &&
                  !_.isEmpty(reportDetail?.endDate)
                    ? `${reportDetail?.startDate}~${reportDetail?.endDate}`
                    : "",
                mismatchedErrors: reportDetails.mismatchedErrors,
                rejectedErrors: reportDetails.rejectedErrors,
              },
              notificationDetails: {
                ...editSchedulerData.notificationDetails,
                email: notificationDetail?.email,
                subject: notificationDetail?.subject,
                message: notificationDetail?.message,
                format: notificationDetail?.format,
                notifyMe: notificationDetail?.notifyMe,
              },
            };

            // Update local state
            setSchedulerData(schedulerData);
            dispatch(
              setInitialSchedulerData({
                ...customReportingState,
                initialSchedulerData: schedulerData,
              })
            );

            if (!_.isEmpty(data.scheduler?.reportPeriod)) {
              setPlaceHolder(
                data.scheduler?.reportPeriod?.FREQ ? "Custom" : "Select"
              );
            }

            if (!_.isEmpty(data.scheduler?.notificationDetails?.message)) {
              setMessageValue(
                RichTextEditor.createValueFromString(
                  data.scheduler?.notificationDetails?.message,
                  "html"
                )
              );
            }

            // Update Redux State

            // Notification Details
            for (const [key, value] of Object.entries(notificationDetail)) {
              dispatch(
                setNotificationState({
                  ...customReportingState,
                  key: key,
                  value: value,
                })
              );
            }

            // Scheduler Details
            for (const [key, value] of Object.entries(schedulerDetail)) {
              dispatch(
                setSchedulerState({
                  ...customReportingState,
                  key: key,
                  value: value,
                })
              );
            }

            // Report Details
            for (const [key, value] of Object.entries(reportDetails)) {
              dispatch(
                setReportDetails({
                  ...customReportingState,
                  key: key,
                  value: Array.isArray(value) ? value.join(",") : value,
                })
              );
            }

            // Set Filters
            dispatch(
              setFilters({
                ...customReportingState,
                filters: data?.scheduler?.reportDetails,
              })
            );

            // API calls

            if (!_.isEmpty(reportDetails?.vendors)) {
              getVendors();
            }

            if (!_.isEmpty(reportDetails?.accreditations)) {
              getAccreditations();
            }

            if (!_.isEmpty(reportDetails?.classifications)) {
              getClassifications();
            }

            if (!_.isEmpty(reportDetails?.certificates)) {
              getCertificates(
                reportDetails?.accreditations,
                reportDetails?.certificates?.split(",")
              );
            }

            if (!_.isEmpty(reportDetails?.programs)) {
              getPrograms(
                reportDetails?.vendors,
                reportDetails?.accreditations,
                reportDetails?.classifications,
                reportDetails?.certificates,
                reportDetails?.startDate,
                reportDetails?.endDate,
                reportDetails?.programs,
                selectedCategories.some(
                  (category: CategoryOption) =>
                    category.code === CUSTOM_CONSTANTS.Programs
                )
              );
            }
          }
        }
      } catch (error: any) {
        const errorStatus = error?.response?.data?.status;
        let errorResponse =
          error?.response?.data?.error?.activity?.error ??
          error?.response?.data?.error;
        const invalidIdRegex = /^Invalid.*Id$|not found$/;
        if (
          errorStatus === 404 ||
          invalidIdRegex.test(errorResponse?.message)
        ) {
          setIsInvalidID(true);
          setErrorMessage(errorResponse?.message);
        }
        if (error?.response?.data?.error?.message) {
          error.response.data.error.message = errorResponse.message?.replace(
            /scheduler/gi,
            "Scheduler"
          );
          handleAPIError(error, history, dispatch);
          dispatch(setPageLoadingStatus({ isPageLoading: false }));
        }
      }
    };

    let previousRoute = localStorage.getItem("_prevRoute");
    const shouldFetchData =
      previousRoute &&
      !previousRoute?.includes("select-fields") &&
      !previousRoute?.includes("/custom/preview-report");

    if (shouldFetchData) {
      fetchData();
    } else {
      // API calls

      if (!_.isEmpty(customReportingState.reportDetails.vendors)) {
        getVendors();
      }

      if (!_.isEmpty(customReportingState.reportDetails.accreditations)) {
        getAccreditations();
      }

      if (!_.isEmpty(customReportingState.reportDetails.classifications)) {
        getClassifications();
      }

      if (!_.isEmpty(customReportingState.reportDetails.certificates)) {
        getCertificates(customReportingState.reportDetails.accreditations);
      }

      if (!_.isEmpty(customReportingState.reportDetails.programs)) {
        getPrograms(
          customReportingState.reportDetails.vendors,
          customReportingState.reportDetails.accreditations,
          customReportingState.reportDetails.classifications,
          customReportingState.reportDetails.certificates,
          customReportingState.reportDetails.startDate,
          customReportingState.reportDetails.endDate,
          customReportingState.reportDetails.programs,
          customReportingState?.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Programs
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(customReportingState.notificationDetails?.message)) {
      setMessageValue(
        RichTextEditor.createValueFromString(
          customReportingState.notificationDetails?.message,
          "html"
        )
      );
    }

    if (!_.isEmpty(customReportingState.schedulerDetails?.reportPeriod)) {
      setPlaceHolder(
        customReportingState.schedulerDetails?.reportPeriod
          ? "Custom"
          : "Select"
      );
    }

    // set initial selection
    let selections: string[] = [];

    for (const [key, value] of Object.entries(
      customReportingState?.reportDetails
    )) {
      if (customReportFields.includes(key) && !_.isEmpty(value)) {
        selections = [...selections, key];
      }
    }

    setInitialCategorySelected(selections);

    // Accessibility on date range
    if (document.getElementsByClassName("rs-picker-toggle-placeholder")[0]) {
      document
        .getElementsByClassName("rs-picker-toggle-placeholder")[0]
        .removeAttribute("aria-placeholder");
    }
    if (document.getElementsByClassName("rs-picker-toggle-textbox")[0]) {
      (document.getElementsByClassName("rs-picker-toggle-textbox")[0] as HTMLDivElement).tabIndex =
        -1;
    }
    if (document.getElementsByClassName("rs-btn-disabled")[0]) {
      document
        .getElementsByClassName("rs-btn-disabled")[0]
        .removeAttribute("disabled");
    }
  }, []);

  useEffect(() => {
    let reportDetail = editSchedulerData.reportDetails;
    let notificationDetail = editSchedulerData.notificationDetails;

    setSchedulerData({
      ...editSchedulerData,
      id: customReportingState.schedulerDetails.id,
      title: customReportingState.schedulerDetails.title,
      startDate: customReportingState.schedulerDetails.startDate,
      endDate: customReportingState.schedulerDetails.endDate,
      runTime: customReportingState.schedulerDetails.runTime,
      reportPeriod: customReportingState.schedulerDetails.reportPeriod,
      omitReport: customReportingState.schedulerDetails.omitReport,
      reportDetails: {
        ...reportDetail,
        vendors: customReportingState.reportDetails.vendors,
        programs: customReportingState.reportDetails.programs,
        accreditations: customReportingState.reportDetails.accreditations,
        classifications: customReportingState.reportDetails.classifications,
        certificates: customReportingState.reportDetails.certificates,
        frequency: customReportingState.reportDetails.frequency,
        startDate: customReportingState.reportDetails.startDate,
        endDate: customReportingState.reportDetails.endDate,
        dateRange:
          !_.isEmpty(customReportingState.reportDetails.startDate) &&
          !_.isEmpty(customReportingState.reportDetails.endDate)
            ? `${customReportingState.reportDetails.startDate}~${customReportingState.reportDetails.endDate}`
            : "",
        mismatchedErrors: customReportingState.reportDetails?.mismatchedErrors,
        rejectedErrors: customReportingState.reportDetails?.rejectedErrors,
      },
      notificationDetails: {
        ...notificationDetail,
        email: customReportingState.notificationDetails.email,
        subject: customReportingState.notificationDetails.subject,
        message: customReportingState.notificationDetails.message,
        format: customReportingState.notificationDetails.format,
        notifyMe: customReportingState.notificationDetails.notifyMe,
      },
    });

    // Validate date range required
    enableDateRange(customReportingState?.reportDetails?.frequency);

    if (
      customReportingState.schedulerDetails.status ===
      CONSTANTS.SCHEDULE_DELETED
    ) {
      setEndDateDisabled(true);
      setRunTimeDisabled(true);
    }
  }, [customReportingState]);

  const handleMultiSelect = (selected: any, key: string) => {
    // If new category is selected or completely unselected.
    if (selected.length === 0 || selected.length === 1) {
      dispatch(
        setSelectedFields({
          ...customReportingState,
          selectedFields: [],
        })
      );

      setDisableSave(true);
    }

    setProgramSearchResult([]);
    const reportDetail: any = editSchedulerData.reportDetails;
    const selectedValues: any = selected?.map((obj: any) => obj.code).join(",");

    switch (key) {
      case "vendors":
        dispatch(
          setVendorRedux({ ...customReportingState, vendors: selectedValues })
        );
        break;
      case "programs":
        dispatch(
          setProgramRedux({ ...customReportingState, programs: selectedValues })
        );
        break;
      case "accreditations":
        dispatch(
          setAccreditationRedux({
            ...customReportingState,
            accreditations: selectedValues,
          })
        );
        break;
      case "classifications":
        dispatch(
          setClassificationRedux({
            ...customReportingState,
            classifications: selectedValues,
          })
        );
        break;
      case "certificates":
        dispatch(
          setCertificateRedux({
            ...customReportingState,
            certificates: selectedValues,
          })
        );
        break;
      case "mismatchedErrors":
        dispatch(
          setMisMatchedErrors({
            ...customReportingState,
            mismatchedErrors: selectedValues,
            errorType: CUSTOM_CONSTANTS.mismatch,
          })
        );
        break;
      case "rejectedErrors":
        dispatch(
          setRejectedErrors({
            ...customReportingState,
            rejectedErrors: selectedValues,
            errorType: CUSTOM_CONSTANTS.rejected,
          })
        );
        break;
      default:
        return;
    }

    setSchedulerData({
      ...editSchedulerData,
      reportDetails: {
        ...reportDetail,
        [key]: selectedValues,
      },
    });
  };

  const filterSelectedMultiSelect = (
    previousList: any,
    values: string,
    key: string
  ) => {
    let selected = [];

    if (previousList?.length === 0) {
      return [];
    }

    if (key === "program") {
      // Append the search result value in previousList
      programSearchResult.forEach((item: any) => {
        let programExists = false;
        previousList.forEach((program: any) => {
          if (program.code === item.code) {
            programExists = true;
          }
        });

        if (!programExists) {
          previousList.push(item);
        }
      });
    }

    if (!_.isEmpty(values)) {
      selected = previousList.filter((item: any) => {
        if (_.includes(values.split(","), item.code)) {
          return item;
        }
      });
    }

    return selected;
  };

  const getErrorTypeValue = (values: any, options: any) => {
    const selectedValues = values ? values.split(",") : [];
    if (
      Array.isArray(selectedValues) &&
      Array.isArray(options) &&
      selectedValues?.length > 0 &&
      options.length > 0
    ) {
      let selected = options.filter((item: any) => {
        if (_.includes(selectedValues, item.code)) {
          return item;
        }
      });

      return selected;
    }
  };

  const isErrorFieldSelected = () => {
    return (
      !_.isEmpty(editSchedulerData.reportDetails?.mismatchedErrors) ||
      !_.isEmpty(editSchedulerData.reportDetails?.rejectedErrors)
    );
  };

  const disableErrorFields = () => {
    return (
      !_.isEmpty(editSchedulerData.reportDetails?.programs) ||
      !_.isEmpty(editSchedulerData.reportDetails?.accreditations) ||
      !_.isEmpty(editSchedulerData.reportDetails?.classifications) ||
      !_.isEmpty(editSchedulerData.reportDetails?.certificates)
    );
  };

  const dateRangeChange = (dates: any) => {
    if (!_.isEmpty(dates)) {
      getPrograms(
        editSchedulerData?.reportDetails?.vendors,
        editSchedulerData?.reportDetails?.accreditations,
        editSchedulerData?.reportDetails?.classifications,
        editSchedulerData?.reportDetails?.certificates,
        dates.split("~")[0],
        dates.split("~")[1],
        customReportingState?.reportDetails?.programs,
        customReportingState?.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CUSTOM_CONSTANTS.Programs
        )
      );

      dispatch(
        setDateRange({
          ...customReportingState,
          startDate: dates.split("~")[0],
          endDate: dates.split("~")[1],
        })
      );
    } else {
      getPrograms(
        editSchedulerData?.reportDetails?.vendors,
        editSchedulerData?.reportDetails?.accreditations,
        editSchedulerData?.reportDetails?.classifications,
        editSchedulerData?.reportDetails?.certificates,
        "",
        "",
        customReportingState?.reportDetails?.programs,
        customReportingState?.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CUSTOM_CONSTANTS.Programs
        )
      );

      dispatch(
        setDateRange({
          ...customReportingState,
          startDate: "",
          endDate: "",
        })
      );
    }

    const reportDetail: any = editSchedulerData.reportDetails;
    setSchedulerData({
      ...editSchedulerData,
      reportDetails: {
        ...reportDetail,
        dateRange: dates,
        startDate: dates ? dates.split("~")[0] : "",
        endDate: dates ? dates.split("~")[1] : "",
      },
    });
  };

  const handleNotificationDetail = (event: any) => {
    const { name, value } = event.target;

    const notificationDetail: any = editSchedulerData.notificationDetails;
    const fieldValue =
      name === "notifyMe" ? event.target.checked : value.trim();

    dispatch(
      setNotificationState({
        ...customReportingState,
        key: name,
        value: fieldValue,
      })
    );

    setSchedulerData({
      ...editSchedulerData,
      notificationDetails: {
        ...notificationDetail,
        [name]: fieldValue,
      },
    });
  };

  const handleTextareaNotificationDetail = (name: any, event: any) => {
    const notificationDetail: any = editSchedulerData.notificationDetails;
    dispatch(
      setNotificationState({
        ...customReportingState,
        key: name,
        value: event.detail,
      })
    );

    setSchedulerData({
      ...editSchedulerData,
      notificationDetails: {
        ...notificationDetail,
        [name]: event.detail,
      },
    });
  };

  const handleMessageChange = (value: any) => {
    const notificationDetail: any = editSchedulerData.notificationDetails;

    dispatch(
      setMessage({
        ...customReportingState,
        message: value,
      })
    );

    setSchedulerData({
      ...editSchedulerData,
      notificationDetails: {
        ...notificationDetail,
        message: value,
      },
    });
  };

  const dateChangeHanler = (value: string) => {
    dateRangeChange(value);
  };

  const formatDate = (dates: DateRange | null) => {
    if (!dates?.length) {
      return "";
    }

    return `${dates[0].getFullYear()}-${("0" + (dates[0].getMonth() + 1)).slice(
      -2
    )}-${("0" + dates[0].getDate()).slice(-2)}~${dates[1].getFullYear()}-${(
      "0" +
      (dates[1].getMonth() + 1)
    ).slice(-2)}-${("0" + dates[1].getDate()).slice(-2)}`;
  };

  const { afterToday } = DateRangePicker;

  const customStyles = {
    control: (base: any, state: any) =>
      state.isDisabled
        ? {
            ...base,
            maxHeight: "5rem",
            overflowY: "auto",
            borderColor: "#777979",
            borderRadius: "2px",
            opacity: ".3",
            backgroundColor: "#FFF",
          }
        : {
            ...base,
            maxHeight: "5rem",
            overflowY: "auto",
            borderColor: "#777979",
            borderRadius: "2px",
            outline: state.isFocused && "2px solid #E17509",
            boxShadow: state.isFocused && "0 0 4px 4px #e17509",
          },
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: state.isDisabled && "white",
      color: state.isDisabled ? "#c0c0c0" : "#808080",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    return () => {
      if (
        history.action !== "POP" &&
        history.action !== "REPLACE" &&
        !window.location.pathname.includes(Routes.CUSTOM_REPORTS_SELECT_FIELDS)
      ) {
        dispatch(clearSchedulerState());
      }
    };
  }, [history]);

  // to retain the startDate and endDate in scheduler details if got changed through reportPeriod
  useEffect(() => {
    if (
      _.isEmpty(editSchedulerData.startDate) ||
      _.isEmpty(editSchedulerData.endDate)
    ) {
      return;
    }

    if (
      editSchedulerData.startDate !==
        customReportingState.schedulerDetails.startDate ||
      editSchedulerData.endDate !==
        customReportingState.schedulerDetails.endDate
    ) {
      dispatch(
        setSchedulerDates({
          ...customReportingState,
          startDate: editSchedulerData.startDate,
          endDate: editSchedulerData.endDate,
        })
      );
    }
  }, [editSchedulerData]);

  useEffect(() => {
    if (!_.isEmpty(editSchedulerData.reportPeriod)) {
      dispatch(
        setSchedulerState({
          ...customReportingState,
          key: "reportPeriod",
          value: editSchedulerData.reportPeriod,
        })
      );
    }
  }, [editSchedulerData.reportPeriod]);

  const handleCategoryChange = async (
    selected: CategoryOption,
    allSelected: CategoryOption[],
    actionMeta?: ActionMeta<any>
  ) => {
    switch (selected?.code) {
      case CUSTOM_CONSTANTS.Vendors:
        const vendorFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Vendors
          );
        if (!vendorFieldSelected) {
          await getVendors();
        }
        break;
      case CUSTOM_CONSTANTS.Programs:
        const programFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Programs
          );
        if (!programFieldSelected) {
          getPrograms(
            customReportingState.reportDetails?.vendors,
            customReportingState.reportDetails?.accreditations,
            customReportingState.reportDetails?.classifications,
            customReportingState.reportDetails?.certificates,
            customReportingState.reportDetails?.startDate,
            customReportingState.reportDetails?.endDate,
            "",
            customReportingState?.reportDetails?.categories.some(
              (category: CategoryOption) =>
                category.code === CUSTOM_CONSTANTS.Programs
            )
          );
        }
        break;
      case CUSTOM_CONSTANTS.Accreditations:
        const accreditationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Accreditations
          );

        if (!accreditationFieldSelected) {
          await getAccreditations();
        }
        break;
      case CUSTOM_CONSTANTS.Classifications:
        const classificationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Classifications
          );

        if (!classificationFieldSelected) {
          await getClassifications();
        }
        break;
      case CUSTOM_CONSTANTS.Certificates:
        const certificateFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CUSTOM_CONSTANTS.Certificates
          );

        if (!certificateFieldSelected) {
          await getCertificates(
            customReportingState.reportDetails.accreditations
          );
        }
        break;
      default:
    }

    if (
      actionMeta?.action == "remove-value" &&
      actionMeta?.removedValue?.code == CUSTOM_CONSTANTS.Accreditations
    ) {
      getCertificates();
    }

    dispatch(
      setSelectedFields({
        ...customReportingState,
        selectedFields: [],
      })
    );

    // If we remove any category than values to corresponding state should also get cleared
    let currentCategories = customReportingState?.reportDetails?.categories.map(
      (item: CategoryOption) => item.code
    );
    let currentSelected = allSelected.map((item: any) => item.code);

    if (currentCategories.length > allSelected.length) {
      currentCategories.forEach((item: any) => {
        if (!currentSelected.includes(item)) {
          if (item === "Error Types") {
            dispatch(
              setReportDetails({
                ...customReportingState,
                key: "mismatchedErrors",
                value: "",
              })
            );

            dispatch(
              setReportDetails({
                ...customReportingState,
                key: "rejectedErrors",
                value: "",
              })
            );
          } else {
            dispatch(
              setReportDetails({
                ...customReportingState,
                key: _.lowerCase(item),
                value: "",
              })
            );

            setSchedulerData({
              ...editSchedulerData,
              reportDetails: {
                ...editSchedulerData.reportDetails,
                [_.lowerCase(item)]: "",
              },
            });
          }
        }
      });
    }

    dispatch(
      setCategory({
        ...customReportingState,
        categories: allSelected,
      })
    );
  };

  const isCategoryOptionDisabled = (option: any) => {
    const categoryList: string[] =
      customReportingState.reportDetails?.categories.map(
        (category: CategoryOption) => category.code
      );

    switch (option?.code) {
      case CUSTOM_CONSTANTS.errorTypes: {
        return (
          categoryList.includes(CUSTOM_CONSTANTS.Accreditations) ||
          categoryList.includes(CUSTOM_CONSTANTS.Certificates) ||
          categoryList.includes(CUSTOM_CONSTANTS.Classifications) ||
          categoryList.includes(CUSTOM_CONSTANTS.Programs)
        );
      }
      case CUSTOM_CONSTANTS.Vendors: {
        return false;
      }
      default: {
        return categoryList.includes(CUSTOM_CONSTANTS.errorTypes);
      }
    }
  };

  const uniqueCheck = (list1: any, list2: any) => {
    if (typeof list1 === "undefined" || list1 === null) {
      list1 = [];
    }

    if (typeof list2 === "undefined" || list2 === null) {
      list2 = [];
    }

    const uniqueList = list1.filter((item: any) => {
      let containsInSavedList = false;

      for (let i = 0; i < list2.length; i++) {
        if (list2[i].code === item.code) {
          containsInSavedList = true;
          break;
        }
      }

      if (!containsInSavedList) {
        return true;
      } else {
        return false;
      }
    });

    return [...uniqueList, ...list2];
  };

  const shouldLoadMore: ShouldLoadMore = (
    scrollHeight,
    clientHeight,
    scrollTop
  ) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    return bottomBorder < scrollTop;
  };

  const programLoadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const programParams = getProgramApiParams(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.startDate,
      customReportingState.reportDetails?.endDate,
      "",
      searchQuery,
      25,
      page
    );

    const response: any = await GetProgramListByFilter(programParams);
    let loadedPrograms = response.data?.data?.programs;

    setProgram(uniqueCheck(loadedPrograms, programs));

    return {
      options: loadedPrograms,
      hasMore: Math.ceil(response?.data?._pagination?.totalCount / 25) > page,
      additional: {
        page: page + 1,
      },
    };
  };
  if (isInvalidID) {
    return <PageNotFound heading={_.startCase(errorMessage)} />;
  }

  return (
    editSchedulerData?.id !== 0 &&
    editSchedulerData?.id !== undefined ? (
      <EditCustomReportSchedulerWrapper>
        {!_.isEmpty(editSchedulerData.reportPeriod) && (
          <ReportPeriodPopover
            show={showReportPeriod}
            setShow={setShowReportPeriod}
            addSchedulerData={editSchedulerData}
            setSchedulerData={setSchedulerData}
            ConvertToJson={ConvertToJson}
            placeHolder={placeHolder}
            formState={editSchedulerData}
            setPlaceHolder={setPlaceHolder}
          />
        )}
        <div className="addvendor-wrapper d-flex w-100">
          <div className="container" onClick={dropdownBlur}>
            <Breadcrumb
              currentpage={breadcrumbRoutes.editCustomScheduler[1].title}
              previouspages={JSON.stringify([
                breadcrumbRoutes.editCustomScheduler[0],
              ])}
            />

            <div className="sub-heads-pr justify-content-between d-block w-100 float-left mb-5">
              <h1 className="cp-h2 float-left" data-testid="formTitle">
                {t<string>("editCustomReportSchedulerLabel")}
              </h1>
            </div>
            <div className="position-relative float-left">
              <form
                className="row mr-0 d-flex justify-content-between"
                onSubmit={formDataSubmit}
              >
                <div className="row col-lg-8 pl-0 mb-3 position-relative scheduler-header">
                  <h2>{t<string>("schedulerDetails")}</h2>
                </div>
                <div className="form-group row no-row-cls required col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="title"
                    className="col-lg-2 col-md-2 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("title")}
                  </label>
                  <div className="col-lg-10 col-md-10 col-sm-8 p-0 float-right">
                    <RenderTextField
                      elementObject={{
                        id: "title",
                        name: "title",
                        value: editSchedulerData.title,
                        type: "text",
                      }}
                      handleData={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row no-row-cls required col-lg-6 col-md-6 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="startDate"
                    className="col-lg-4 col-md-4 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("startDate")}
                  </label>
                  <div className="col-lg-8 col-md-8 col-sm-8 p-0 float-right">
                    <div className="position-relative ">
                      <RenderTextField
                        elementObject={{
                          id: "startDate",
                          name: "startDate",
                          type: "date",
                          value: editSchedulerData.startDate,
                        }}
                        handleData={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group required row no-row-cls col-lg-6 col-md-6 pr-0 mr-0 d-block pl-4 mb-4 pb-1 pl-md-0 ml-md-0 pr-md-0">
                  <label
                    htmlFor="endDate"
                    className="col-lg-4 col-md-4 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("endDate")}
                  </label>
                  <div
                    className={classNames(
                      "col-lg-8 col-md-8 col-sm-8 p-0 float-right",
                      {
                        "input-field-height": isEndDateDisabled,
                      }
                    )}
                  >
                    <div className="position-relative ">
                      <RenderTextField
                        elementObject={{
                          id: "endDate",
                          name: "endDate",
                          type: "date",
                          value: editSchedulerData.endDate,
                          className: classNames({
                            "invalid-input-text": isEndDateDisabled,
                          }),
                        }}
                        handleData={handleChange}
                      />
                      {isEndDateDisabled && (
                        <span className="expired-schedule-label">
                          {t<string>("expiredScheduleEndDateLabel")}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group row no-row-cls required col-lg-6 col-md-6 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="runTime"
                    className="col-lg-4 col-md-4 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("runTime")}
                  </label>
                  <div
                    className={classNames(
                      "col-lg-8 col-md-8 col-sm-8 p-0 float-right",
                      {
                        "input-field-height": isRunTimeDisabled,
                      }
                    )}
                  >
                    <RenderTextField
                      elementObject={{
                        id: "runTime",
                        name: "runTime",
                        value: editSchedulerData.runTime,
                        type: "time",
                        className: classNames({
                          "invalid-input-text": isRunTimeDisabled,
                        }),
                      }}
                      handleData={handleChange}
                    />
                    {isRunTimeDisabled && (
                      <span className="expired-schedule-label">
                        {t<string>("expiredScheduleRunTimeLabel")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group required row no-row-cls col-lg-6 col-md-6 pr-0 mr-0 d-block pl-4 mb-4 pb-1 pl-md-0 ml-md-0 pr-md-0">
                  <label className="col-lg-4 col-md-4 col-sm-4 col-form-label float-left">
                    {t<string>("reportPeriod")}
                  </label>
                  <div className="col-lg-8 col-md-8 col-sm-8 p-0 float-right">
                    <div
                      className="form-control custom-dropdown"
                      data-testid="reportPeriod"
                      onClick={() => setFlag(!flag)}
                    >
                      {placeHolder}
                    </div>
                    <div
                      className={
                        flag ? `d-flex flex-column dropdown-child` : `d-none`
                      }
                    >
                      <div
                        className="select-span"
                        onClick={() => {
                          setFlag(false);
                          setSchedulerData({
                            ...editSchedulerData,
                            reportPeriod: {},
                          });
                          setPlaceHolder("Select");
                        }}
                      >
                        Select
                      </div>
                      <div
                        className="custom-span"
                        data-testid="custom"
                        onClick={() => {
                          setShowReportPeriod(true);
                          setPlaceHolder("Custom");
                          setFlag(false);
                        }}
                        data-backdrop="static"
                        data-keyboard="false"
                        data-toggle="modal"
                        data-target="#ShowReportPeriodpopup"
                      >
                        Custom
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row no-row-cls col-lg-6 col-md-6 pr-0 ml-0 d-block pl-2 mb-4 pb-1">
                  <label
                    htmlFor="omitReport"
                    className="col-lg-4 col-md-4 col-sm-4 col-form-label float-left pl-md-2"
                  >
                    {t<string>("omitReportLabel")}
                  </label>
                  <div className="col-lg-8 col-md-8 col-sm-8 p-0 float-right checkboxDiv">
                    <CheckBox
                      elementObject={{
                        type: "checkbox",
                        id: "omitReport",
                        name: "omitReport",
                        defaultChecked: editSchedulerData.omitReport,
                        handleData: handleChange,
                      }}
                    />
                  </div>
                </div>

                <div className="bdr-btm-address w-100 mb-4 mt-0"></div>

                {/* Report Details */}
                <div className="row col-lg-12 pl-0 mb-3 position-relative scheduler-header">
                  <h2>{t<string>("customReportDetails")}</h2>
                </div>

                <div className="form-group row no-row-cls col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <CustomReportsWrapper>
                    <div className="custom-reports-wrapper">
                      <GenerateReportWrapper>
                        <div className="row inputs-b">
                          <div
                            className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 text-box-div"
                            data-testid="category_field"
                          >
                            <Select
                              id="Category"
                              className="multi text-box"
                              classNamePrefix="list"
                              aria-label="selectCategory"
                              value={
                                customReportingState?.reportDetails?.categories
                              }
                              placeholder={t<string>("selectCategory")}
                              getOptionValue={(category: any) => {
                                return category.id;
                              }}
                              getOptionLabel={(category: any) => {
                                return category.code;
                              }}
                              options={schedulerCategories}
                              isMulti
                              closeMenuOnSelect={false}
                              hideSelectedOptions={true}
                              isOptionDisabled={isCategoryOptionDisabled}
                              onChange={(
                                selected: any,
                                actionMeta: ActionMeta<any>
                              ) => {
                                handleCategoryChange(
                                  selected[selected.length - 1],
                                  selected,
                                  actionMeta
                                );
                              }}
                              // @ts-ignore
                              allowSelectAll={true}
                              styles={customStyles}
                            />
                          </div>

                          <div
                            className={`${
                              frequencyRequiredClass ? "required" : ""
                            } col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 text-box-div`}
                          >
                            <RenderInputSelect
                              elementObject={{
                                id: "frequency",
                                name: "frequency",
                                placeholder: t<string>("selectFrequency"),
                                required: frequencyRequiredClass,
                                data: reportFrequency,
                                value:
                                  editSchedulerData.reportDetails.frequency,
                                selected:
                                  editSchedulerData.reportDetails.frequency,
                                className:
                                  "multi text-box non-react-select-field",
                              }}
                              handleData={handleReportDetail}
                            />
                          </div>

                          <div
                            className={`${
                              dateRangeRequiredClass ? "required" : ""
                            } col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 text-box-div`}
                            data-testid="dateRange_field"
                          >
                            {customReportingState.reportDetails.frequency &&
                              customReportingState.reportDetails?.frequency ===
                                "custom_range" && (
                                <DateRangePicker
                                  appearance="default"
                                  placeholder="Select Date Range"
                                  format="yyyy-MM-dd"
                                  data-testid="daterange"
                                  className={`w-100 text-truncate form-control inputs text-box non-react-select-field ${
                                    dateRangeErrorClass
                                      ? "date-range-error"
                                      : ""
                                  }`}
                                  style={{ color: "Red", display: "block" }}
                                  onChange={(dates, ev) => {
                                    dateChangeHanler(formatDate(dates));
                                  }}
                                  disabled={
                                    customReportingState?.reportDetails
                                      ?.frequency !== "custom_range"
                                  }
                                  // @ts-ignore
                                  value={
                                    !_.isEmpty(
                                      customReportingState.reportDetails
                                        ?.frequency
                                    ) &&
                                    !_.isEmpty(
                                      customReportingState.reportDetails
                                        ?.startDate
                                    ) &&
                                    !_.isEmpty(
                                      customReportingState.reportDetails
                                        ?.endDate
                                    ) &&
                                    customReportingState.reportDetails
                                      ?.frequency === "custom_range" && [
                                      new Date(
                                        customReportingState.reportDetails?.startDate
                                      ),
                                      new Date(
                                        customReportingState.reportDetails?.endDate
                                      ),
                                    ]
                                  }
                                  // @ts-ignore
                                  disabledDate={afterToday()}
                                  placement="bottomEnd"
                                />
                              )}
                            {customReportingState.reportDetails?.frequency !==
                              "custom_range" && (
                              <CustDateRangePicker
                                changeHanler={(value: Array<Date>) => {
                                  dateRangeChange(value);
                                }}
                                value={
                                  editSchedulerData.reportDetails?.dateRange
                                }
                                className="w-100 text-truncate form-control inputs text-box non-react-select-field"
                                disabled={isDateDisabled}
                              />
                            )}
                          </div>

                          {customReportingState.reportDetails?.categories
                            .length > 0 &&
                            customReportingState.reportDetails.categories.map(
                              (item: CategoryOption) => {
                                return item.code ===
                                  CUSTOM_CONSTANTS.Vendors ? (
                                  <div
                                    key={item.id}
                                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div"
                                    data-testid="custom-field"
                                  >
                                    {" "}
                                    <Select
                                      id={"selectVendor"}
                                      className="multi text-box"
                                      classNamePrefix="list"
                                      aria-label="selectVendor"
                                      value={filterSelectedMultiSelect(
                                        vendors,
                                        customReportingState.reportDetails
                                          ?.vendors,
                                        "vendor"
                                      )}
                                      placeholder={`Select Vendors`}
                                      getOptionValue={(vendor: any) => {
                                        return vendor.id;
                                      }}
                                      getOptionLabel={(vendor: any) => {
                                        return vendor.name;
                                      }}
                                      options={vendors}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={true}
                                      onChange={(selected) => {
                                        handleMultiSelect(selected, "vendors");
                                        getPrograms(
                                          selected
                                            ?.map((obj: any) => obj.code)
                                            .join(","),
                                          editSchedulerData.reportDetails
                                            ?.accreditations,
                                          editSchedulerData.reportDetails
                                            ?.classifications,
                                          editSchedulerData.reportDetails
                                            ?.certificates,
                                          editSchedulerData.reportDetails
                                            ?.startDate,
                                          editSchedulerData.reportDetails
                                            ?.endDate,
                                          customReportingState?.reportDetails
                                            ?.programs,
                                          customReportingState?.reportDetails?.categories.some(
                                            (category: CategoryOption) =>
                                              category.code ===
                                              CUSTOM_CONSTANTS.Programs
                                          )
                                        );
                                      }}
                                      // @ts-ignore
                                      allowSelectAll={true}
                                      styles={customStyles}
                                    />
                                  </div>
                                ) : item.code === CUSTOM_CONSTANTS.Programs ? (
                                  <div
                                    key={item.id}
                                    className="col-md-8 col-sm-12 px-10 row-margin text-box-div"
                                    data-testid="custom-field"
                                  >
                                    <AsyncPaginate
                                      id={item.code}
                                      className="multi text-box text-nowrap"
                                      classNamePrefix="list"
                                      aria-label="selectPrograms"
                                      value={filterSelectedMultiSelect(
                                        programs,
                                        customReportingState.reportDetails
                                          ?.programs,
                                        "program"
                                      )}
                                      loadOptions={programLoadOptions}
                                      getOptionValue={(program: any) => {
                                        return program.code;
                                      }}
                                      getOptionLabel={(program: any) => {
                                        return `${program.name} (${program.code})`;
                                      }}
                                      onChange={(selected: any) => {
                                        handleMultiSelect(selected, "programs");
                                      }}
                                      isSearchable={true}
                                      placeholder={t<string>("selectProgram")}
                                      additional={{
                                        page: 1,
                                      }}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      isDisabled={isErrorFieldSelected()}
                                      styles={customStyles}
                                      debounceTimeout={500}
                                      shouldLoadMore={shouldLoadMore}
                                      cacheUniqs={[
                                        customReportingState.reportDetails
                                          ?.accreditations,
                                        customReportingState.reportDetails
                                          ?.classifications,
                                        customReportingState.reportDetails
                                          ?.certificates,
                                        customReportingState.reportDetails
                                          ?.startDate,
                                        customReportingState.reportDetails
                                          ?.endDate,
                                        customReportingState.reportDetails
                                          ?.frequency,
                                      ]}
                                    />
                                  </div>
                                ) : item.code ===
                                  CUSTOM_CONSTANTS.Accreditations ? (
                                  <div
                                    key={item.id}
                                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div"
                                    data-testid="custom-field"
                                  >
                                    <Select
                                      id="selectAccreditation"
                                      name="selectAccreditation"
                                      className="multi text-box text-nowrap"
                                      classNamePrefix="list"
                                      placeholder={t<string>(
                                        "selectAccreditation"
                                      )}
                                      aria-label="selectAccreditation"
                                      getOptionValue={(accreditation: any) =>
                                        accreditation.code
                                      }
                                      getOptionLabel={(accreditation: any) =>
                                        accreditation.code
                                      }
                                      options={accreditations}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={true}
                                      components={{
                                        Option,
                                      }}
                                      onChange={(selected: any) => {
                                        handleMultiSelect(
                                          selected,
                                          "accreditations"
                                        );
                                        getPrograms(
                                          editSchedulerData.reportDetails
                                            ?.vendors,
                                          selected
                                            ?.map((obj: any) => obj.code)
                                            .join(","),
                                          editSchedulerData.reportDetails
                                            ?.classifications,
                                          editSchedulerData.reportDetails
                                            ?.certificates,
                                          editSchedulerData.reportDetails
                                            ?.startDate,
                                          editSchedulerData.reportDetails
                                            ?.endDate,
                                          customReportingState?.reportDetails
                                            ?.programs,
                                          customReportingState?.reportDetails?.categories.some(
                                            (category: CategoryOption) =>
                                              category.code ===
                                              CUSTOM_CONSTANTS.Programs
                                          )
                                        );
                                        getCertificates(
                                          convertListToString(
                                            selected.map(
                                              (item: any) => item.code
                                            )
                                          )
                                        );
                                      }}
                                      value={filterSelectedMultiSelect(
                                        accreditations,
                                        customReportingState.reportDetails
                                          ?.accreditations,
                                        "accreditation"
                                      )}
                                      styles={customStyles}
                                      isDisabled={isErrorFieldSelected()}
                                    />
                                  </div>
                                ) : item.code ===
                                  CUSTOM_CONSTANTS.Classifications ? (
                                  <div
                                    key={item.id}
                                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div"
                                    data-testid="custom-field"
                                  >
                                    <Select
                                      id="selectClassification"
                                      name="selectClassification"
                                      className="multi text-box text-nowrap"
                                      classNamePrefix="list"
                                      placeholder={t<string>(
                                        "selectClassification"
                                      )}
                                      aria-label="selectClassification"
                                      getOptionValue={(classification: any) =>
                                        classification.code
                                      }
                                      getOptionLabel={(classification: any) =>
                                        classification.name
                                      }
                                      options={classifications}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={true}
                                      components={{
                                        Option,
                                      }}
                                      onChange={(selected: any) => {
                                        handleMultiSelect(
                                          selected,
                                          "classifications"
                                        );
                                        getPrograms(
                                          editSchedulerData.reportDetails
                                            ?.vendors,
                                          editSchedulerData.reportDetails
                                            ?.accreditations,
                                          selected
                                            ?.map((obj: any) => obj.code)
                                            .join(","),
                                          editSchedulerData.reportDetails
                                            ?.certificates,
                                          editSchedulerData.reportDetails
                                            ?.startDate,
                                          editSchedulerData.reportDetails
                                            ?.endDate,
                                          customReportingState?.reportDetails
                                            ?.programs,
                                          customReportingState?.reportDetails?.categories.some(
                                            (category: CategoryOption) =>
                                              category.code ===
                                              CUSTOM_CONSTANTS.Programs
                                          )
                                        );
                                      }}
                                      value={filterSelectedMultiSelect(
                                        classifications,
                                        customReportingState.reportDetails
                                          ?.classifications,
                                        "classification"
                                      )}
                                      styles={customStyles}
                                      isDisabled={isErrorFieldSelected()}
                                    />
                                  </div>
                                ) : item.code ===
                                  CUSTOM_CONSTANTS.Certificates ? (
                                  <div
                                    key={item.id}
                                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div"
                                    data-testid="custom-field"
                                  >
                                    <Select
                                      id="selectCertificates"
                                      name="selectCertificates"
                                      className="multi text-box text-nowrap"
                                      classNamePrefix="list"
                                      placeholder={t<string>(
                                        "selectCertificate"
                                      )}
                                      aria-label="selectCertificates"
                                      getOptionValue={(certificate: any) =>
                                        certificate.code
                                      }
                                      getOptionLabel={(certificate: any) =>
                                        certificate.name
                                      }
                                      options={certificates}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={true}
                                      components={{
                                        Option,
                                      }}
                                      onChange={(selected: any) => {
                                        handleMultiSelect(
                                          selected,
                                          "certificates"
                                        );
                                        getPrograms(
                                          editSchedulerData.reportDetails
                                            ?.vendors,
                                          editSchedulerData.reportDetails
                                            ?.accreditations,
                                          editSchedulerData.reportDetails
                                            ?.classifications,
                                          selected
                                            ?.map((obj: any) => obj.code)
                                            .join(","),
                                          editSchedulerData.reportDetails
                                            ?.startDate,
                                          editSchedulerData.reportDetails
                                            ?.endDate,
                                          customReportingState?.reportDetails
                                            ?.programs,
                                          customReportingState?.reportDetails?.categories.some(
                                            (category: CategoryOption) =>
                                              category.code ===
                                              CUSTOM_CONSTANTS.Programs
                                          )
                                        );
                                      }}
                                      value={filterSelectedMultiSelect(
                                        certificates,
                                        customReportingState.reportDetails
                                          ?.certificates,
                                        "certificate"
                                      )}
                                      styles={customStyles}
                                      isDisabled={isErrorFieldSelected()}
                                    />
                                  </div>
                                ) : (
                                  item.code === CUSTOM_CONSTANTS.errorTypes && (
                                    <React.Fragment key="item.id">
                                      <div className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div">
                                        <Select
                                          id="selectMismatchedErrors"
                                          className="multi text-box text-nowrap"
                                          classNamePrefix="list"
                                          aria-label="selectMismatchedErrors"
                                          value={getErrorTypeValue(
                                            editSchedulerData.reportDetails
                                              ?.mismatchedErrors,
                                            MismatchErrorTags
                                          )}
                                          placeholder={t<string>(
                                            "selectMismatchedErrors"
                                          )}
                                          getOptionValue={(
                                            mismatchError: any
                                          ) => {
                                            return mismatchError.code;
                                          }}
                                          getOptionLabel={(
                                            mismatchError: any
                                          ) => {
                                            return mismatchError.name;
                                          }}
                                          options={MismatchErrorTags}
                                          isMulti
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={true}
                                          onChange={(selected: any) => {
                                            handleMultiSelect(
                                              selected,
                                              "mismatchedErrors"
                                            );
                                          }}
                                          // @ts-ignore
                                          allowSelectAll={true}
                                          styles={customStyles}
                                          isDisabled={
                                            !_.isEmpty(
                                              customReportingState.reportDetails
                                                ?.rejectedErrors
                                            ) || disableErrorFields()
                                          }
                                        />
                                      </div>

                                      <div className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin text-box-div">
                                        <Select
                                          id="selectRejectedErrors"
                                          className="multi text-box text-nowrap"
                                          classNamePrefix="list"
                                          aria-label="selectRejectedErrors"
                                          value={getErrorTypeValue(
                                            editSchedulerData.reportDetails
                                              ?.rejectedErrors,
                                            RejectedErrorTags
                                          )}
                                          placeholder={t<string>(
                                            "selectRejectedErrors"
                                          )}
                                          getOptionValue={(
                                            rejectedError: any
                                          ) => {
                                            return rejectedError.code;
                                          }}
                                          getOptionLabel={(
                                            rejectedError: any
                                          ) => {
                                            return rejectedError.name;
                                          }}
                                          options={RejectedErrorTags}
                                          isMulti
                                          closeMenuOnSelect={false}
                                          hideSelectedOptions={true}
                                          onChange={(selected: any) => {
                                            handleMultiSelect(
                                              selected,
                                              "rejectedErrors"
                                            );
                                          }}
                                          // @ts-ignore
                                          allowSelectAll={true}
                                          styles={customStyles}
                                          isDisabled={
                                            !_.isEmpty(
                                              customReportingState.reportDetails
                                                ?.mismatchedErrors
                                            ) || disableErrorFields()
                                          }
                                        />
                                      </div>
                                    </React.Fragment>
                                  )
                                );
                              }
                            )}
                        </div>
                      </GenerateReportWrapper>
                    </div>
                  </CustomReportsWrapper>

                  {/* Report Details - Select Fields */}
                  <div className="float-right my-3">
                    {(_.isEmpty(
                      customReportingState?.reportDetails?.frequency
                    ) &&
                      _.isEmpty(customReportingState?.reportDetails?.vendors) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.programs
                      ) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.accreditations
                      ) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.classifications
                      ) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.certificates
                      ) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.mismatchedErrors
                      ) &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.rejectedErrors
                      )) ||
                    (customReportingState?.reportDetails?.frequency ===
                      "custom_range" &&
                      _.isEmpty(
                        customReportingState?.reportDetails?.startDate
                      )) ? (
                      <Link
                        to={{
                          pathname: Routes.CUSTOM_REPORTS_SELECT_FIELDS,
                          state: {
                            fromSchedulerPage: true,
                            vendors: editSchedulerData.reportDetails?.vendors,
                            programs: editSchedulerData.reportDetails?.programs,
                            accreditations:
                              editSchedulerData.reportDetails?.accreditations,
                            classifications:
                              editSchedulerData.reportDetails?.classifications,
                            certificates:
                              editSchedulerData.reportDetails?.certificates,
                          },
                        }}
                        className={classNames(
                          "float-right select-field-link disabled-link"
                        )}
                        onClick={(event) => {
                          event.preventDefault();
                          setDisableSave(false);
                        }}
                      >
                        {t<string>("selectReportFields")}
                      </Link>
                    ) : (
                      <Link
                        to={{
                          pathname: Routes.CUSTOM_REPORTS_SELECT_FIELDS,
                          state: {
                            fromSchedulerPage: true,
                            [CUSTOM_CONSTANTS.Vendors]:
                              editSchedulerData.reportDetails?.vendors,
                            [CUSTOM_CONSTANTS.Programs]:
                              editSchedulerData.reportDetails?.programs,
                            [CUSTOM_CONSTANTS.Accreditations]:
                              editSchedulerData.reportDetails?.accreditations,
                            [CUSTOM_CONSTANTS.Classifications]:
                              editSchedulerData.reportDetails?.classifications,
                            [CUSTOM_CONSTANTS.Certificates]:
                              editSchedulerData.reportDetails?.certificates,
                            [CUSTOM_CONSTANTS.errorTypes]:
                              editSchedulerData.reportDetails
                                ?.mismatchedErrors ||
                              editSchedulerData.reportDetails?.rejectedErrors,
                          },
                        }}
                        className={classNames("float-right select-field-link")}
                        data-testid="selectFieldsBtn"
                      >
                        {t<string>("selectReportFields")}
                      </Link>
                    )}
                  </div>
                </div>

                <div className="bdr-btm-address w-100 mb-4 mt-0"></div>

                {/* Notification Details */}
                <div className="row col-lg-12 pl-0 mb-3 position-relative scheduler-header">
                  <h2>{t<string>("notificationDetails")}</h2>
                </div>

                <div className="form-group row no-row-cls required col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <div className="col-12">
                    <RenderAuiTextAreaField
                      elementObject={{
                        id: "email",
                        name: "email",
                        label: t<string>("email"),
                        labelgrid:
                          "col-lg-2 col-md-12 col-sm-12 col-form-label float-left pl-0 pl-lg-3",
                        textareagrid:
                          "col-lg-10 col-md-12 col-sm-12 p-0 float-right",
                        value: editSchedulerData.notificationDetails.email,
                        required: true,
                        placeholder: "e.g: test@mail.com,test1@mail.com",
                        errorstate: emailError,
                        errormessage: t<string>("emailErrorMessage"),
                        ref: textareaRef,
                      }}
                      handleData={handleTextareaNotificationDetail}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls required col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="subject"
                    className="col-lg-2 col-md-2 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("subject")}
                  </label>
                  <div className="col-lg-10 col-md-10 col-sm-8 p-0 float-right">
                    <RenderTextField
                      elementObject={{
                        id: "subject",
                        name: "subject",
                        value: editSchedulerData.notificationDetails.subject,
                        type: "text",
                      }}
                      handleData={handleNotificationDetail}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="sender"
                    className="col-lg-2 col-md-2 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("sender")}
                  </label>
                  <div className="col-lg-10 col-md-10 col-sm-8 p-0 float-right">
                    <RenderTextField
                      elementObject={{
                        id: "sender",
                        name: "sender",
                        value: CONSTANTS.REPORT_SENDER_EMAIL,
                        type: "email",
                        disabled: true,
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label className="col-lg-2 col-md-2 col-sm-4 col-form-label float-left">
                    {t<string>("message")}
                  </label>
                  <div className="col-lg-10 col-md-10 col-sm-8 p-0 float-right">
                    <RichTextEditor
                      value={messageValue}
                      onChange={(messageVal) => {
                        setMessageValue(messageVal);
                        handleMessageChange(
                          messageVal.toString("html").replace("<p><br></p>", "")
                        );
                      }}
                      className="message-text-editor"
                      placeholder={t<string>("additionalRemarks")}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls required col-lg-12 col-md-12 pl-0 ml-0 d-block pr-0 mb-4 pb-1">
                  <label
                    htmlFor="format"
                    className="col-lg-2 col-md-2 col-sm-4 col-form-label float-left"
                  >
                    {t<string>("format")}
                  </label>
                  <div className="col-lg-2 col-md-2 col-sm-2 p-0 float-left">
                    <RenderInputSelect
                      elementObject={{
                        id: "format",
                        name: "format",
                        data: exportType,
                        value: editSchedulerData.notificationDetails.format,
                        selected:
                          editSchedulerData.notificationDetails.format.toLowerCase(),
                      }}
                      handleData={handleNotificationDetail}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls col-lg-4 col-md-4 pr-0 ml-0 d-block pl-2 mb-4 pb-1">
                  <label
                    htmlFor="notifyMe"
                    className="col-lg-6 col-md-6 col-sm-4 col-form-label float-left pl-md-2"
                  >
                    {t<string>("notifyMe")}
                  </label>
                  <div className="col-lg-6 col-md-6 col-sm-8 p-0 float-left checkboxDiv">
                    <CheckBox
                      elementObject={{
                        type: "checkbox",
                        id: "notifyMe",
                        name: "notifyMe",
                        value: editSchedulerData.notificationDetails.notifyMe,
                        defaultChecked:
                          editSchedulerData.notificationDetails.notifyMe,
                        handleData: handleNotificationDetail,
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row no-row-cls col-lg-4 col-md-4 pr-0 ml-0 d-block pl-2 mb-4 pb-1"></div>

                <div className="bdr-btm w-100 order-lg-7 order-md-7"></div>
                <div className="float-right btn-div mr-3 ml-auto mob_btnLayout order-md-8 order-lg-8">
                  <div
                    aria-label={t<string>("editSchedulerCancel")}
                    data-testid={t<string>("cancel")}
                    onClick={navigateToListPage}
                  >
                    <AuiIcon
                      elementObject={{
                        svgclass: "aui-icon-arrowleft text-primary mr-1",
                        pathclass: "aui-path-arrowleft",
                        icon: "arrowleft",
                        svgwidth: "18",
                        svgheight: "18",
                      }}
                    ></AuiIcon>
                    {t<string>("back")}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-round btn-primary ml-5"
                    aria-label={t<string>("editSchedulerSave")}
                    data-testid="submit"
                    disabled={disableSave}
                  >
                    {t<string>("save")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </EditCustomReportSchedulerWrapper>
    ) : (<></>)
  );
};

export default EditCustomReportScheduler;

import React from "react";
import "./Styled.css";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
const { afterToday } = DateRangePicker;

const CustDateRangePicker = (props: any) => {
  const formatDate = (dates: DateRange | null) => {
    if (!dates?.length) {
      return "";
    }

    return `${dates[0].getFullYear()}-${("0" + (dates[0].getMonth() + 1)).slice(
      -2
    )}-${("0" + dates[0].getDate()).slice(-2)}~${dates[1].getFullYear()}-${(
      "0" +
      (dates[1].getMonth() + 1)
    ).slice(-2)}-${("0" + dates[1].getDate()).slice(-2)}`;
  };

  return (
    <DateRangePicker
      appearance="default"
      placeholder="Select Date Range"
      format="yyyy-MM-dd"
      data-testid="daterange"
      className={props.className ? props.className : ""}
      style={{ color: "Red", display: "block" }}
      onChange={(dates, ev) => {
        props.changeHanler(formatDate(dates));
      }}
      disabled={props.disabled ?? false}
      defaultValue={props.defaultValue || []}
      // @ts-ignore
      disabledDate={afterToday()}
      placement="bottomEnd"
    />
  );
};

export default CustDateRangePicker;

import React, { useEffect, useRef, useState } from "react";
import { CustomReportsWrapper } from "./Styled";
import PageHeading from "components/PageHeading/PageHeading";
import ViewReport from "./ViewReport";
import { GenerateReportWrapper } from "./Styled";
import { useTranslation } from "react-i18next";
import Select, { ActionMeta } from "react-select";
import RenderInputSelect from "components/InputFields/RenderInputSelect";
import {
  MismatchErrorTags,
  RejectedErrorTags,
  Routes,
  reportFrequency,
} from "common/constants";
import _, { lowerCase } from "lodash";
import CustDateRangePicker from "components/DateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  ListAccreditationByFilter,
  ListVendorByFilter,
  ListClassificationByFilter,
  ListCustomReportByFilters,
  GetProgramListByFilter,
  GetCustomReportFilterById,
  ListCustomReportColumns,
  GetClassificationList,
} from "services/api/report.api";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import { format, subDays, subMonths, subYears } from "date-fns";
import { GetCertificationByFilter } from "services/api/Certification.api";
import ModalPopupComp from "components/ModalPopup";
import { ModalHeader } from "components/ModalPopup/ModalHeader";
import {
  CustomReportStateConstants,
  useCustomReportContext,
} from "./CustomReportContext/CustomReportContext";
import {
  CONSTANTS,
  CategoryOption,
  activityDateTypeFields,
  activityCategories,
} from "common/constants/customReports";
import { Link, Switch, useHistory, useParams } from "react-router-dom";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import {
  setVendor,
  setProgram,
  setAccreditation,
  setClassification,
  setCertificate,
  setFrequency,
  setDateRange,
  setNotifyMe,
  setMisMatchedErrors,
  setRejectedErrors,
  setSelectedFields,
  setCategory,
  setInitialSavedFilterData,
  setIsInvalidId,
  setErrorMessage,
  setDateType,
  setReportName,
  setReportId,
  setPreviewData,
  setCustomReportAPIReqBody,
  setPaginationData,
  setShowViewReport,
  setTableColumns,
  setReportType,
  clearReportDetails,
  setExportReportButtonDisable,
  updateSaveReportState,
  setDisableSaveReportFilter,
} from "./CustomReportSlice";
import { listEquals } from "common/utils/EqualList";
import { SaveReportBody } from "./modals/SaveReportModalBody";
import { ChangeReportModalBody } from "./modals/ChangeReportModalBody";
import { setReportDetails } from "./CustomReportSlice";
import { setCurrentTab } from "pages/ReportScheduler/SchedulerSlice";
import SelectFields, { IColumn } from "./SelectFields/SelectFields";
import { typeFlatNodes, Node } from "./SelectFields/SelectedFieldsConfig";
import { setShowMyReports } from "components/AdminLeftPanel/LeftPanelSlice";
import { AsyncPaginate, ShouldLoadMore } from "react-select-async-paginate";

import { setActiveFilter } from "components/AdminLeftPanel/LeftPanelSlice";
import {
  convertListToString,
  convertStringToList,
} from "common/utils/commonFunctionality";
import { handleAPIError } from "common/ErrorHandler";

import { CustomReportContextProvider } from "./CustomReportContext/CustomReportContext";
import classNames from "classnames";
import {
  setAccreditationsList,
  setCertificatesList,
  setClassificationsList,
  setProgramsList,
  setVendorsList,
} from "./DropdownData";

const { afterToday } = DateRangePicker;

const navigateToListPage = () => undefined;

const modalPopupProps = {
  popupDiscardFunc: navigateToListPage,
};

interface FlatNode {
  id: string;
  label: string;
  code: string;
  refName?: string;
  children?: FlatNode[];
}

type ActivityReportProps = {
  routeState?: any;
  getData?: any;
};

const ActivityReport = ({ routeState, getData }: ActivityReportProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const routeParams: any = useParams();

  const adminLeftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });
  const OptionsData = useSelector((state: any) => {
    return state.dropdownOptionsData;
  });

  const [flatNodes, setFlatNodes] = useState<typeFlatNodes>({});

  const [savedReportName, setSavedReportName] = useState("");
  const [reportNameErrorClass, setReportNameErrorClass] =
    useState<boolean>(false);
  const [showError, setShowError] = useState("");
  const saveReportNameRef = useRef<HTMLInputElement>(null);
  const [paginations, setPaginations] = useState({
    pageNumber: 1,
    pageSize: 25,
  });

  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const firstRenderRef = useRef(true);

  const getCategoryOptions = () => {
    const selectedCategories =
      customReportingState.reportDetails?.categories.map(
        (category: CategoryOption) => category.code
      );

    if (
      selectedCategories.includes(CONSTANTS.Vendors) &&
      selectedCategories.includes(CONSTANTS.Programs) &&
      selectedCategories.includes(CONSTANTS.Accreditations) &&
      selectedCategories.includes(CONSTANTS.Certificates) &&
      selectedCategories.includes(CONSTANTS.Classifications)
    ) {
      return activityCategories.filter(
        (category: CategoryOption) => category.code !== CONSTANTS.selectAll
      );
    }

    return activityCategories;
  };

  useEffect(() => {
    // Accessibility on date range
    if (document.getElementsByClassName("rs-picker-toggle-placeholder")[0]) {
      document
        .getElementsByClassName("rs-picker-toggle-placeholder")[0]
        .removeAttribute("aria-placeholder");
    }

    if (document.getElementsByClassName("rs-picker-toggle-textbox")[0]) {
      (document.getElementsByClassName("rs-picker-toggle-textbox")[0] as HTMLDivElement).tabIndex =
        -1;
    }

    if (document.getElementsByClassName("rs-btn-disabled")[0]) {
      document
        .getElementsByClassName("rs-btn-disabled")[0]
        .removeAttribute("disabled");
    }
  }, [customReportingState.reportDetails?.frequency]);

  const [vendors, setVendors] = useState<any>();

  const [programs, setPrograms] = useState<any>();

  const [accreditations, setAccreditations] = useState<any>();

  const [classifications, setClassifications] = useState<any>();

  const [certificates, setCertificates] = useState<any>();

  const [dateRangeValue, setDateRangeValue] = useState<any>();

  const [dateRangeErrorClass, setDateRangeErrorClass] =
    useState<boolean>(false);

  const [frequencyErrorClass, setFrequencyErrorClass] =
    useState<boolean>(false);

  const [programSearchResult, setProgramSearchResult] = useState([]);

  // selected categories at page load
  const [categories, setCategories] = useState<string[]>(
    customReportingState.reportDetails?.categories?.map(
      (category: CategoryOption) => _.lowerCase(category?.code)
    ) ?? []
  );

  const getProgramApiParams = (
    vendor: string,
    accreditation: string,
    classification: string,
    certificate: string,
    startDate: string,
    endDate: string,
    program: string = "",
    searchProgram: string = "",
    pageSize: number = 25,
    pageNumber: number = 1,
    pageOrder: string = ""
  ) => {
    let reportType = "custom_report";
    const programParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      pageOrder: pageOrder,
      vendor: "",
      program: "",
      searchProgram: "",
      accreditation: "",
      classification: "",
      certificate: "",
      reportType: "",
      startDate: "",
      endDate: "",
    };

    if (!_.isEmpty(startDate)) {
      programParams.startDate = startDate;
    }

    if (!_.isEmpty(endDate)) {
      programParams.endDate = endDate;
    }

    if (!_.isEmpty(reportType)) {
      programParams.reportType = reportType;
    }

    if (!_.isEmpty(vendor)) {
      programParams.vendor = vendor;
    }

    if (!_.isEmpty(accreditation)) {
      programParams.accreditation = accreditation;
    }

    if (!_.isEmpty(classification)) {
      programParams.classification = classification;
    }

    if (!_.isEmpty(certificate)) {
      programParams.certificate = certificate;
    }

    if (!_.isEmpty(program)) {
      programParams.program = program;
    }

    if (!_.isEmpty(searchProgram)) {
      programParams.searchProgram = searchProgram;
    }

    return programParams;
  };

  const getPrograms = async (
    vendor: string,
    accreditation: string,
    classification: string,
    certificate: string,
    startDate: string,
    endDate: string,
    program: string = "",
    isProgramFieldSelected: boolean = false
  ) => {
    // Return if program field is not selected
    if (!isProgramFieldSelected) {
      return;
    }

    const programParams = getProgramApiParams(
      vendor,
      accreditation,
      classification,
      certificate,
      startDate,
      endDate,
      program
    );

    let programs = [];

    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      if (!_.isEmpty(program)) {
        // to get the selected programs with program filter
        const response = await GetProgramListByFilter(programParams);
        programs = response?.data?.data?.programs;
      }
      let programsWithFilter = programs.map((program: any) => program.code);
      let selectedPrograms: any = program
        .split(",")
        ?.filter((selectedProgram: any) =>
          programsWithFilter.includes(selectedProgram)
        ); //Remove those programs from selected list which are not part of filter(Accreditation, certificate, classification)

      dispatch(setProgramsList(programs));
      setPrograms(programs ?? []);

      dispatch(setProgram({
        ...customReportingState,
        programs: selectedPrograms.join(",")
      }))

      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    } catch (e) {
      setPrograms([]);
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  // Set the category state to the initial selected categories.
  useEffect(() => {
    setCategories(
      customReportingState.reportDetails?.categories?.map(
        (category: CategoryOption) => _.lowerCase(category?.code)
      ) ?? []
    );
    if (_.isEmpty(customReportingState.previewData)) {
      dispatch(setDisableSaveReportFilter(true));
    }
  }, [customReportingState.customReportApiReqBody]);

  const nodeHasChildren = (node: FlatNode) => {
    return Array.isArray(node?.children);
  };

  const flattenNodes = (
    nodes: FlatNode[] | undefined,
    parent: FlatNode,
    depth = 0
  ) => {
    let tempObject: any = {};

    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    //Create a flat object for every node
    nodes.forEach((node: FlatNode, index) => {
      const isParent = nodeHasChildren(node);

      // Check for duplicate value
      if (flatNodes[node.id] !== undefined) {
        console.error("Every field has to have an unique Value");
      }

      tempObject[node.id] = {
        label: node.label,
        id: node.id,
        code: node.code,
        children: node?.children,
        parent,
        isChild: parent?.id !== undefined,
        isParent,
        isLeaf: !isParent,
        disabled: false,
        treeDepth: depth,
        index,
        refName: node?.refName,
      };

      const res = flattenNodes(node?.children, node, depth + 1);
      tempObject = { ...tempObject, ...res };
    });
    return tempObject;
  };

  const getNode = (id: any, flatNodes: any): Node => {
    return flatNodes[id];
  };

  // Search value within field and generate column properties
  const getColumn = (
    value: string,
    flatNodes: any,
    field: any
    //@ts-ignore
  ): IColumn => {
    if (field?.isLeaf && field?.code == value) {
      let columnObj: IColumn = {
        name: field.parent.label + " - " + field.label,
        isChecked: true,
        mandatory: true,
        class: "code-cls",
        propertyName: field.refName,
      };

      if (field.refName.includes("date") || field.refName.includes("Date")) {
        columnObj.isDate = true;
      } else {
        columnObj.isDate = false;
      }
      return columnObj;
    }
    if (!field?.isLeaf) {
      let childrenList = field?.children;

      for (const element of childrenList) {
        let childNode = getNode(element.id, flatNodes);

        let column: IColumn = getColumn(value, flatNodes, childNode);
        if (column) {
          return column;
        }
      }
    }
  };

  const GetID = (filter: any = [], toFind: string): any => {
    for (const element of filter) {
      if (element?.code === toFind) {
        return element.id;
      }

      if (element.hasOwnProperty("children")) {
        const id = GetID(element?.children, toFind);
        if (id) {
          return id;
        }
      }
    }
  };

  const updateSelectedFields = (fields: any, customFields: any) => {
    let selected: string[] = [];

    for (const [key, value] of Object.entries(customFields)) {
      if (Array.isArray(value) && value.length > 0) {
        let filteredField: any[] = [];

        if (key === "programAccreditations") {
          filteredField = fields
            .filter((item: any) => item.code === "programs")[0]
            .children?.filter((item: any) => item.code === key)[0];
        } else {
          filteredField = fields?.filter((item: any) => item.code === key)[0];
        }

        value.forEach((item: string) => {
          const id = GetID((filteredField as any)?.children, item);
          selected = [...selected, id];
        });
      }
    }
    return selected
  };

  const filterField = (fields: any[], categories: CategoryOption[]) => {
    let filteredFields: any[] = [];

    if (getData?.customReportFilter.filterType == "SUCCESS_ACTIVITY") {
      const successActivityField = fields.filter(
        (field: any) =>
          field.code === _.camelCase(CONSTANTS.successActivities)
      );
      filteredFields = [...filteredFields, ...successActivityField];
    }
    else if (getData?.customReportFilter.filterType == "ERROR_ACTIVITY") {
      const errorActivityField = fields.filter(
        (field: any) => field.code === _.camelCase(CONSTANTS.errorActivities)
      );
      filteredFields = [...filteredFields, ...errorActivityField];
    }

    return filteredFields;
  };

  const setSavedReportTableColumns = async (
    customField: any,
    categories: CategoryOption[],
  ) => {
    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));

      const response = await ListCustomReportColumns();
      const fields: FlatNode[] =
        response.data?.data?.customColumn?.custom_report_column?.customFields;
      const filteredFields = filterField(fields, categories);

      const selectedColumns = updateSelectedFields(filteredFields, customField);

      const parent: FlatNode = {
        id: "",
        label: "",
        code: "",
      };

      let tempState = flattenNodes(filteredFields, parent);
      setFlatNodes(tempState);

      let columns: IColumn[] = [];

      // Generate the table columns

      if (
        customField?.successActivities &&
        customField?.successActivities.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "successActivities"
        );

        customField?.successActivities.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      } else if (
        customField?.errorActivities &&
        customField?.errorActivities.length > 0
      ) {
        let fieldValue = Object.values(tempState).filter(
          (field: any) => field.code === "errorActivities"
        );

        customField?.errorActivities.forEach((value: string) => {
          let col = getColumn(value, tempState, fieldValue[0]);
          columns.push(col);
        });
      } else {
        if (customField?.vendors && customField?.vendors.length > 0) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "vendors"
          );

          customField?.vendors.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (customField?.programs && customField?.programs.length > 0) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "programs"
          );

          customField?.programs.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (
          customField?.programAccreditations &&
          customField?.programAccreditations.length > 0
        ) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "programAccreditations"
          );

          customField?.programAccreditations.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (
          customField?.learnerDetails &&
          customField?.learnerDetails.length > 0
        ) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "learnerDetails"
          );

          customField?.learnerDetails.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (
          customField?.accreditations &&
          customField?.accreditations.length > 0
        ) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "accreditations"
          );

          customField?.accreditations.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (
          customField?.classifications &&
          customField?.classifications.length > 0
        ) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "classifications"
          );

          customField?.classifications.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }

        if (customField?.certificates && customField?.certificates.length > 0) {
          let fieldValue = Object.values(tempState).filter(
            (field: any) => field.code === "certificates"
          );

          customField?.certificates.forEach((value: string) => {
            let col = getColumn(value, tempState, fieldValue[0]);
            columns.push(col);
          });
        }
      }
      return [columns, selectedColumns];

    } catch (error) {
      console.error(error);
    }
  };

  const getViewReportData = async (viewReportReqBody: any) => {
    let reportData = {
      ...viewReportReqBody,
    };
    const frequency = viewReportReqBody?.filters?.frequency;

    if (frequency !== "custom_range") {
      const endDate = format(new Date(), "yyyy-MM-dd");
      let startDate: any = "";
      switch (frequency) {
        case "weekly":
          startDate = subDays(new Date(), 7);
          break;
        case "monthly":
          startDate = subMonths(new Date(), 1);
          break;
        case "quarterly":
          startDate = subMonths(new Date(), 3);
          break;
        case "half_yearly":
          startDate = subMonths(new Date(), 6);
          break;
        case "yearly":
          startDate = subYears(new Date(), 1);
          break;
        default:
          startDate = "";
      }
      startDate = startDate !== "" ? format(startDate, "yyyy-MM-dd") : "";
    }
    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));

      const response = await ListCustomReportByFilters(
        { ...reportData, reportId: parseInt(routeParams?.id) },
        {
          pageNumber: 1,
          pageOffset: 1,
          pageSize: 25,
        }
      );

      if (response?.data?.status === 200) {
        const previewData = response?.data;
        const pagination = response?.data?._pagination;

        return [previewData, pagination]
      }
    } catch (err: any) {
      dispatch(
        setPageLoadingStatus({
          isPageLoading: false,
        })
      );
      store.dispatch(
        showToast({
          title: "CustomReport",
          message: err.response?.data?.error?.details[0]?.message,
        })
      );
    }
  };
  const UpdateCustomReportWithFilters = async () => {
    if (getData.customReportFilter) {
      const { filters, name: reportName, filterType: reportType } = getData?.customReportFilter
      let viewReportReqBody = {
        customField: filters.customField,
        filters: filters.filters,
      };

      let selectedCategories: CategoryOption[] = [];

      // Generate the category object .
      activityCategories.forEach((item: CategoryOption) => {
        if (filters?.filters?.subCategories.includes(_.lowerCase(item.code))) {
          selectedCategories.push(item)
        }
      })
      const [columns, selectedColumns]: any = await setSavedReportTableColumns(
        viewReportReqBody?.customField,
        selectedCategories
      );
      const [previewData, pagination]: any = await getViewReportData(viewReportReqBody);

      dispatch(updateSaveReportState({
        ...customReportingState,
        isAdminReport: false,
        isActivityReport: true,
        isSuccessActivity: getData?.customReportFilter.filterType == "SUCCESS_ACTIVITY" ? true : false,
        isErrorActivity: getData?.customReportFilter.filterType == "ERROR_ACTIVITY" ? true : false,
        customReportApiReqBody: viewReportReqBody,
        tableColumns: columns ?? [],
        reportName: reportName,
        previewData: previewData ?? {},
        pagination: pagination ?? {},
        reportID: routeParams?.id,
        showViewReport: true,
        selectedFields: selectedColumns ?? [],
        reportDetails: {
          ...customReportingState.reportDetails,
          categories: selectedCategories,
          vendors: filters?.filters?.vendors.join(","),
          programs: filters?.filters?.programs.join(","),
          accreditations: filters?.filters?.accreditations.join(","),
          certificates: filters?.filters?.certificates.join(","),
          classifications: filters?.filters?.classifications.join(","),
          frequency: filters?.filters?.frequency,
          startDate: filters?.filters?.startDate,
          endDate: filters?.filters?.endDate,
          dateType: filters?.filters?.dateType,
          programMode: filters?.filters?.programType ?? "",
          programSubType: filters?.filters?.programSubType ?? [],
          mismatchedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.mismatch
              ? filters.filters[CONSTANTS.errorFields].toString()
              : [],

          rejectedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.rejected
              ? filters.filters[CONSTANTS.errorFields].toString()
              : [],
        },
        notificationDetails: {
          ...customReportingState.notificationDetails,
          notifyMe: filters.filters.notifyMe,
        },
        initialSavedFilterData: {
          categories: selectedCategories,
          startDate: filters.filters?.startDate,
          endDate: filters.filters?.endDate,
          frequency: filters.filters?.frequency,
          accreditations: filters?.filters?.accreditations,
          certificates: filters?.filters?.certificates,
          classifications: filters?.filters?.classifications,
          programs: filters?.filters?.programs,
          vendors: filters?.filters?.vendors,
          isSavedFilterSelected: true,
          errorType: "",
          mismatchedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.mismatch
              ? filters.filters[CONSTANTS.errorFields]
              : "",

          rejectedErrors:
            filters.filters[CONSTANTS.errorType] === CONSTANTS.rejected
              ? filters.filters[CONSTANTS.errorFields]
              : "",

        },
      }))

      for (const category of selectedCategories) {
        if (category.code === CONSTANTS.Vendors) {
          await getVendors();
        } else if (category.code == CONSTANTS.Accreditations) {
          await getAccreditations();
        } else if (category.code === CONSTANTS.Certificates) {
          await getCertificates(
            convertListToString(filters?.filters?.accreditations),
            filters?.filters?.certificates
          );
        } else if (category.code === CONSTANTS.Classifications) {
          await getClassifications(
            customReportingState.reportDetails?.certificates
          );
        } else if (category.code === CONSTANTS.Programs) {
          await getPrograms(
            convertListToString(filters?.filters?.vendors),
            convertListToString(filters?.filters?.accreditations),
            convertListToString(filters?.filters?.classifications),
            convertListToString(filters?.filters?.certificates),
            filters.filters.startDate,
            filters.filters.endDate,
            convertListToString(filters?.filters?.programs),
            selectedCategories.some(
              (category) => category.code === CONSTANTS.Programs
            )
          );
        }
      }
      handleLoaderClose();
    }
  };
  const leftPanelState = useSelector((state: any) => {
    return state.adminLeftPanel;
  });

  const fetchFilter = async () => {
    if (getData?.customReportFilter?.name == leftPanelState?.activeFilter?.name || !leftPanelState?.activeFilter?.name) {
      await UpdateCustomReportWithFilters();
    }
  };
  useEffect(() => {
    if (!customReportingState.isErrorActivity) {
      dispatch(
        setReportType({
          ...customReportingState,
          isAdminReport: false,
          isActivityReport: true,
          isSuccessActivity: true,
          isErrorActivity: false,
        })
      );
    }
  }, []);


  //to reload the dropdown coming back from select fields page - normal report
  useEffect(() => {
    (async () => {
      try {
        if (!routeParams?.id) {
          for (const category of customReportingState.reportDetails
            ?.categories) {
            if (category.code === CONSTANTS.Vendors) {
              await getVendors();
            } else if (category.code == CONSTANTS.accreditationsUnderPrograms) {
              await getAccreditations();
              await getCertificates(
                customReportingState.reportDetails?.accreditations
              );
              await getClassifications(
                customReportingState.reportDetails?.certificates
              );
            } else if (category.code == CONSTANTS.Accreditations) {
              await getAccreditations();
            } else if (category.code === CONSTANTS.Certificates) {
              await getCertificates(
                customReportingState.reportDetails?.accreditations
              );
            } else if (category.code === CONSTANTS.Classifications) {
              await getClassifications(
                customReportingState.reportDetails?.certificates
              );
            } else if (category.code === CONSTANTS.Programs) {
              await getPrograms(
                customReportingState.reportDetails?.vendors,
                customReportingState.reportDetails?.accreditations,
                customReportingState.reportDetails?.classifications,
                customReportingState.reportDetails?.certificates,
                customReportingState.reportDetails?.startDate,
                customReportingState.reportDetails?.endDate,
                customReportingState.reportDetails?.programs,
                customReportingState.reportDetails?.categories.some(
                  (category: CategoryOption) =>
                    category.code === CONSTANTS.Programs
                )
              );
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        handleLoaderClose();
      }
    })();

    // Set the dateRangeValue on component load from context
    if (
      customReportingState.reportDetails?.startDate !== "" &&
      customReportingState.reportDetails?.endDate !== ""
    ) {
      setDateRangeValue([
        new Date(customReportingState.reportDetails?.startDate),
        new Date(customReportingState.reportDetails?.endDate),
      ]);
    }
  }, []);

  //to reload the dropdown coming back from select fields page - Saved report
  useEffect(() => {
    // Set the dateRangeValue on component load from context
    if (
      customReportingState.reportDetails?.startDate !== "" &&
      customReportingState.reportDetails?.endDate !== ""
    ) {
      setDateRangeValue([
        new Date(customReportingState.reportDetails?.startDate),
        new Date(customReportingState.reportDetails?.endDate),
      ]);
    }
  }, [adminLeftPanelState?.dataUpdated, adminLeftPanelState?.count, getData?.customReportFilter?.name])


  useEffect(() => {

    if (routeParams?.id && leftPanelState.shouldFetchFilter && getData?.customReportFilter) {
      fetchFilter();
    }
  }, [leftPanelState?.dataUpdated, leftPanelState?.count, getData?.customReportFilter?.name])



  const filterSelectedMultiSelect = (
    previousList: any[],
    values: any[] | undefined,
    key: string
  ) => {
    let selected = [];

    if (previousList?.length === 0) {
      return [];
    }

    if (key === CONSTANTS.Programs) {
      // Append the search result value in previousList
      programSearchResult.forEach((item: any) => {
        let programExists = false;
        previousList.forEach((program: any) => {
          if (program.code === item.code) {
            programExists = true;
          }
        });
        if (!programExists) {
          previousList.push(item);
        }
      });
    }

    if (
      values &&
      Array.isArray(values) &&
      Array.isArray(previousList) &&
      values?.length > 0 &&
      previousList.length > 0
    ) {
      selected = previousList.filter((item: any) => {
        if (_.includes(values, item.code)) {
          return item;
        }
      });
    }
    return selected;
  };

  const handleLoaderClose = () => {
    dispatch(setPageLoadingStatus({ isPageLoading: false }));
  };

  const getVendors = async () => {
    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      const vendorParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListVendorByFilter(vendorParams);
      const vendorsData = response?.data?.data?.vendors;
      dispatch(setVendorsList(vendorsData));
      setVendors(vendorsData ?? []);
    } catch (e) {
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  const uniqueCheck = (list1: any, list2: any) => {
    if (typeof list1 === "undefined" || list1 === null) {
      list1 = [];
    }

    if (typeof list2 === "undefined" || list2 === null) {
      list2 = [];
    }

    const uniqueList = list1.filter((item: any) => {
      let containsInSavedList = false;

      for (let i = 0; i < list2.length; i++) {
        if (list2[i].code === item.code) {
          containsInSavedList = true;
          break;
        }
      }

      if (!containsInSavedList) {
        return true;
      } else {
        return false;
      }
    });

    return [...uniqueList, ...list2];
  };

  const shouldLoadMore: ShouldLoadMore = (
    scrollHeight,
    clientHeight,
    scrollTop
  ) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;

    return bottomBorder < scrollTop;
  };

  const programLoadOptions = async (
    searchQuery: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const programParams = getProgramApiParams(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      customReportingState.reportDetails?.startDate,
      customReportingState.reportDetails?.endDate,
      "",
      searchQuery,
      25,
      page
    );

    const response: any = await GetProgramListByFilter(programParams);
    let loadedPrograms = response.data?.data?.programs;
    dispatch(setProgramsList(uniqueCheck(loadedPrograms, programs)));

    setPrograms(uniqueCheck(loadedPrograms, programs));

    return {
      options: loadedPrograms,
      hasMore: Math.ceil(response?.data?._pagination?.totalCount / 25) > page,
      additional: {
        page: page + 1,
      },
    };
  };

  const getAccreditations = async () => {
    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      const accreditationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
      };
      const response = await ListAccreditationByFilter(accreditationParams);
      const accreditationsData = response?.data?.data?.accreditations;
      const accreditationList = accreditationsData.map(
        (accreditation: any) => ({
          id: accreditation.id,
          code: accreditation.code,
        })
      );
      dispatch(setAccreditationsList(accreditationList));
      setAccreditations(accreditationList ?? []);
    } catch (e) {
      setAccreditations([]);
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  const getClassifications = async (
    certificates?: string,
    isClassificationSelected: boolean = true
  ) => {
    if (!isClassificationSelected) {
      return;
    }

    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      const classificationParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
        certificates: certificates,
      };

      const response = await GetClassificationList(classificationParams);
      const classificationsData = response?.data?.data?.categories;
      const classificationList = classificationsData
        ? classificationsData.map((classification: any) => ({
          id: classification.id,
          code: classification.code,
          name: classification.name,
        }))
        : [];

      dispatch(setClassificationsList(classificationList));
      setClassifications(classificationList ?? []);
      handleLoaderClose();
    } catch (e) {
      setClassifications([]);
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  const getCertificates = async (
    accreditationCode: any = "",
    certificates: any = [],
    isCertificateFieldSelected: boolean = true
  ) => {
    if (!isCertificateFieldSelected) {
      return;
    }

    try {
      dispatch(setPageLoadingStatus({ isPageLoading: true }));
      const certificateParams = {
        pageNumber: "",
        pageSize: 500,
        pageOrder: "updated_at DESC",
        accreditationCode: accreditationCode,
      };

      const response = await GetCertificationByFilter(certificateParams);
      const certificatesData = response?.data?.data?.certificateTemplates;
      const certificateList = certificatesData
        ? certificatesData.map((certificate: any) => ({
          id: certificate.id,
          code: certificate.code,
          name: certificate.name,
        }))
        : [];

      let initialCertificate =
        convertStringToList(
          customReportingState.reportDetails?.certificates,
          ","
        ) &&
          convertStringToList(
            customReportingState.reportDetails?.certificates,
            ","
          )?.length > 0
          ? convertStringToList(
            customReportingState.reportDetails?.certificates,
            ","
          )
          : certificates;

      let selectedCertificates = initialCertificate?.filter((code: any) =>
        certificateList.some((obj) => obj.code === code)
      );


      const isClassificationFieldSelected =
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) =>
            category.code === CONSTANTS.Classifications
        );

      if (
        !_.isEmpty(accreditationCode) &&
        initialCertificate.length !== selectedCertificates.length
      ) {
        getClassifications(
          selectedCertificates.join(","),
          isClassificationFieldSelected
        );
      }

      dispatch(setCertificatesList(certificateList));
      setCertificates(certificateList ?? []);
      handleLoaderClose();
    } catch (e) {
      setCertificates([]);
      dispatch(setPageLoadingStatus({ isPageLoading: false }));
    }
  };

  const dateRangeChange = (dates: any) => {
    let startDate = "";
    let endDate = "";

    if (!_.isEmpty(dates)) {
      startDate = dates.split("~")[0];
      endDate = dates.split("~")[1];

      setDateRangeValue([new Date(startDate), new Date(endDate)]);

      dispatch(
        setDateRange({
          ...customReportingState,
          startDate,
          endDate,
        })
      );

      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        startDate,
        endDate,
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) => category.code === CONSTANTS.Programs
        )
      );
    } else {
      dispatch(
        setDateRange({
          ...customReportingState,
          startDate: "",
          endDate: "",
        })
      );
      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        "",
        "",
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) => category.code === CONSTANTS.Programs
        )
      );

      setDateRangeValue([]);
    }
    dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when Date is updated
  };

  const handleDateTypeChange = (event: any) => {
    dispatch(setDisableSaveReportFilter(true))
    let dateType = event.target.value;

    dispatch(
      setDateType({
        ...customReportingState,
        dateType,
      })
    );
  };

  const handleFrequencyChange = (event: any) => {
    let frequency = event.target.value;
    setDateRangeErrorClass(false);

    if (frequency === "custom_range" || frequency === "") {
      setDateRangeValue([]);

      dispatch(
        setFrequency({
          ...customReportingState,
          frequency: frequency,
          startDate: "",
          endDate: "",
        })
      );

      getPrograms(
        customReportingState.reportDetails?.vendors,
        customReportingState.reportDetails?.accreditations,
        customReportingState.reportDetails?.classifications,
        customReportingState.reportDetails?.certificates,
        "",
        "",
        customReportingState.reportDetails?.programs,
        customReportingState.reportDetails?.categories.some(
          (category: CategoryOption) => category.code === CONSTANTS.Programs
        )
      );
    }

    const endDate = format(new Date(), "yyyy-MM-dd");
    let startDate: any = Date;

    switch (frequency) {
      case "weekly":
        startDate = subDays(new Date(), 7);
        break;
      case "monthly":
        startDate = subMonths(new Date(), 1);
        break;
      case "quarterly":
        startDate = subMonths(new Date(), 3);
        break;
      case "half_yearly":
        startDate = subMonths(new Date(), 6);
        break;
      case "yearly":
        startDate = subYears(new Date(), 1);
        break;
      default:
        return null;
    }

    startDate = format(startDate, "yyyy-MM-dd");

    dispatch(
      setFrequency({
        ...customReportingState,
        frequency: frequency,
        startDate: startDate,
        endDate: endDate,
      })
    );

    getPrograms(
      customReportingState.reportDetails?.vendors,
      customReportingState.reportDetails?.accreditations,
      customReportingState.reportDetails?.classifications,
      customReportingState.reportDetails?.certificates,
      startDate,
      endDate,
      customReportingState.reportDetails?.programs,
      customReportingState.reportDetails?.categories.some(
        (category: CategoryOption) => category.code === CONSTANTS.Programs
      )
    );
    dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when frequency is updated
  };

  const handleViewReport = async () => {
    if (!isDisabledViewReport() && validateFrequency()) {
      const category = customReportingState?.isSuccessActivity
        ? ["successActivities"]
        : customReportingState?.isErrorActivity
          ? ["errorActivities"]
          : [];

      // Add sub categories in case of success/error activities
      let subCategories = [];

      if (
        customReportingState?.isSuccessActivity ||
        customReportingState?.isErrorActivity
      ) {
        subCategories = customReportingState.reportDetails?.categories.map(
          (item: CategoryOption) => _.lowerCase(item.code)
        );
      }

      let requestBody: any = {};
      let customFields: any = JSON.parse(
        JSON.stringify(
          customReportingState.customReportApiReqBody.customField ?? {}
        )
      );

      let filters: any = {
        categories: category,
        subCategories: subCategories,
        vendors: convertStringToList(
          customReportingState.reportDetails?.vendors,
          ","
        ),
        programs: convertStringToList(
          customReportingState.reportDetails?.programs,
          ","
        ),
        accreditations: convertStringToList(
          customReportingState.reportDetails?.accreditations,
          ","
        ),
        certificates: convertStringToList(
          customReportingState.reportDetails?.certificates,
          ","
        ),
        classifications: convertStringToList(
          customReportingState.reportDetails?.classifications,
          ","
        ),
        frequency: customReportingState.reportDetails?.frequency,
        startDate: customReportingState.reportDetails?.startDate,
        endDate: customReportingState.reportDetails?.endDate,
        dateType: customReportingState.reportDetails?.dateType,
        programType: customReportingState.reportDetails?.programMode,
        programSubType: customReportingState.reportDetails?.programSubType
      };

      requestBody = {
        ...requestBody,
        customField: customFields,
        filters: filters,
      };

      const mismatchedErrorList = convertStringToList(
        customReportingState.reportDetails?.mismatchedErrors,
        ","
      );
      const rejectedErrorList = convertStringToList(
        customReportingState.reportDetails?.rejectedErrors,
        ","
      );

      if (mismatchedErrorList && mismatchedErrorList?.length > 0) {
        requestBody = {
          ...requestBody,
          filters: {
            ...requestBody.filters,
            [CONSTANTS.errorType]: CONSTANTS.mismatch,
            [CONSTANTS.errorFields]: mismatchedErrorList,
          },
        };
      }
      if (rejectedErrorList && rejectedErrorList?.length > 0) {
        requestBody = {
          ...requestBody,
          filters: {
            ...requestBody.filters,
            [CONSTANTS.errorType]: CONSTANTS.rejected,
            [CONSTANTS.errorFields]: rejectedErrorList,
          },
        };
      }

      dispatch(
        setCustomReportAPIReqBody({
          ...customReportingState,
          customReportApiReqBody: requestBody,
        })
      );

      dispatch(setPageLoadingStatus({ isPageLoading: true }));

      if (customReportingState.reportDetails?.frequency !== "custom_range") {
        const endDate = format(new Date(), "yyyy-MM-dd");
        let startDate: any = Date;

        switch (customReportingState.reportDetails?.frequency) {
          case "weekly":
            startDate = subDays(new Date(), 7);
            break;
          case "monthly":
            startDate = subMonths(new Date(), 1);
            break;
          case "quarterly":
            startDate = subMonths(new Date(), 3);
            break;
          case "half_yearly":
            startDate = subMonths(new Date(), 6);
            break;
          case "yearly":
            startDate = subYears(new Date(), 1);
            break;
          default:
            startDate = "";
        }

        startDate = startDate !== "" ? format(startDate, "yyyy-MM-dd") : "";

        if (startDate !== "") {
          requestBody = {
            ...requestBody,
            filters: {
              ...requestBody.filters,
              startDate: startDate,
              endDate: endDate,
            },
          };
        } else {
          requestBody = {
            ...requestBody,
            filters: {
              ...requestBody.filters,
              startDate: "",
              endDate: "",
            },
          };
        }
      }

      // If it is a saved report send the name of the report also
      if (routeParams?.id) {
        requestBody = {
          ...requestBody,
          reportId: parseInt(routeParams.id),
        };
      }

      if (
        customReportingState.reportDetails?.frequency === "custom_range" &&
        filters.startDate === "" &&
        filters.endDate === ""
      ) {
        validateDateRangeField([]);
      } else {
        validateDateRangeField([
          new Date(filters.startDate),
          new Date(filters.endDate),
        ]);
        try {
          const response = await ListCustomReportByFilters(
            requestBody,
            paginations
          );

          if (response?.data?.data?.customReport) {
            dispatch(setExportReportButtonDisable({ data: false }));
          } else {
            dispatch(setExportReportButtonDisable({ data: true }));
          }
          if (response?.data?.status === 200) {
            if (response?.data?.data?.customReport?.length > 0) {
              dispatch(setDisableSaveReportFilter(false));
            } else {
              dispatch(setDisableSaveReportFilter(true));
            }
            dispatch(
              setPreviewData({
                ...customReportingState,
                previewData: response?.data,
              })
            );

            dispatch(
              setPaginationData({
                ...customReportingState,
                pagination: response?.data?._pagination,
              })
            );
          }
        } catch (err: any) {
          store.dispatch(
            showToast({
              title: "CustomReport",
              message:
                err.response?.data?.error?.details?.[0]?.message ??
                err.response?.data?.error?.message,
            })
          );
        }
      }

      dispatch(setPageLoadingStatus({ isPageLoading: false }));

      dispatch(
        setShowViewReport({
          ...customReportingState,
          showViewReport: true,
        })
      );
    }
  };

  const customStyles = {
    control: (base: any, state: any) =>
      state.isDisabled
        ? {
          ...base,
          maxHeight: "5rem",
          overflowY: "auto",
          borderColor: "#777979",
          borderRadius: "2px",
          opacity: ".3",
          backgroundColor: "#FFF",
        }
        : {
          ...base,
          maxHeight: "5rem",
          overflowY: "auto",
          borderColor: "#777979",
          borderRadius: "2px",
          outline: state.isFocused && "2px solid #E17509",
          boxShadow: state.isFocused && "0 0 4px 4px #e17509",
        },
    option: (styles: any, state: any) => ({
      ...styles,
      backgroundColor: "white",
      color: state.isDisabled ? "#c0c0c0" : "#808080",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    menu: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  let history = useHistory();

  const formatDate = (dates: DateRange | null) => {
    if (!dates?.length) {
      return "";
    }

    return `${dates[0].getFullYear()}-${("0" + (dates[0].getMonth() + 1)).slice(
      -2
    )}-${("0" + dates[0].getDate()).slice(-2)}~${dates[1].getFullYear()}-${(
      "0" +
      (dates[1].getMonth() + 1)
    ).slice(-2)}-${("0" + dates[1].getDate()).slice(-2)}`;
  };

  const dateChangeHanler = (value: string) => {
    dateRangeChange(value);
    setDateRangeErrorClass(false);
  };

  const validateDateRangeField = (value: any) => {
    if (customReportingState.reportDetails?.frequency == "custom_range" && (!customReportingState.reportDetails?.startDate || !customReportingState.reportDetails?.endDate)) {
      setDateRangeErrorClass(true);
      return false;
    } else {
      setDateRangeErrorClass(false);
      return true;
    }
  };

  const validateFrequency = () => {
    const dateType = customReportingState.reportDetails?.dateType;
    const frequency = customReportingState.reportDetails?.frequency;
    if (!_.isEmpty(dateType) && _.isEmpty(frequency)) {
      setFrequencyErrorClass(true);
      return false;
    } else {
      setFrequencyErrorClass(false);
      return true;
    }
  };

  const selectFieldsPage = () => {
    if (!isDisabledSelectReportFields() && validateFrequency() && validateDateRangeField([])) {
      setDateRangeErrorClass(false);
      let id = routeParams?.id;
      if (id) {
        history.push(Routes.CUSTOM_REPORTS_SELECT_FIELDS, {
          fromSavedReport: true,
          id: id,
          reportName: customReportingState.reportName,
        });
      } else {
        history.push(Routes.CUSTOM_REPORTS_SELECT_FIELDS);
      }
    }
  };

  const handleMultiSelect = (selected: any, key: string) => {
    setProgramSearchResult([]);
    const selectedValues: any =
      selected.length === 0
        ? ""
        : selected?.map((obj: any) => obj.code).join(",");

    switch (key) {
      case _.lowerCase(CONSTANTS.Vendors):
        dispatch(
          setVendor({ ...customReportingState, vendors: selectedValues })
        );
        break;
      case _.lowerCase(CONSTANTS.Programs):
        dispatch(
          setProgram({ ...customReportingState, programs: selectedValues })
        );
        break;
      case _.lowerCase(CONSTANTS.Accreditations):
        dispatch(
          setAccreditation({
            ...customReportingState,
            accreditations: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Classifications):
        dispatch(
          setClassification({
            ...customReportingState,
            classifications: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.Certificates):
        dispatch(
          setCertificate({
            ...customReportingState,
            certificates: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.mismatchErrors):
        dispatch(
          setMisMatchedErrors({
            ...customReportingState,
            mismatchedErrors: selectedValues,
          })
        );
        break;
      case _.lowerCase(CONSTANTS.rejectedErrors):
        dispatch(
          setRejectedErrors({
            ...customReportingState,
            rejectedErrors: selectedValues,
          })
        );
        break;
      default:
        return;
    }
    dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when category filter is updated
  };

  const handleCategoryChange = async (
    selected: CategoryOption,
    allSelected: CategoryOption[],
    actionMeta?: ActionMeta<any>
  ) => {
    let selections: string[] = [];

    if (!_.isEmpty(customReportingState.customReportApiReqBody)) {
      allSelected.forEach((item) => {
        selections = [...selections, _.lowerCase(item.code)];
      });
    }

    switch (selected?.code) {
      case CONSTANTS.Vendors:
        const vendorFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) => category.code === CONSTANTS.Vendors
          );
        if (!vendorFieldSelected) {
          await getVendors();
          handleLoaderClose();
        }
        break;
      case CONSTANTS.Programs:
        const programFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) => category.code === CONSTANTS.Programs
          );
        if (!programFieldSelected) {
          getPrograms(
            customReportingState.reportDetails?.vendors,
            customReportingState.reportDetails?.accreditations,
            customReportingState.reportDetails?.classifications,
            customReportingState.reportDetails?.certificates,
            customReportingState.reportDetails?.startDate,
            customReportingState.reportDetails?.endDate,
            customReportingState.reportDetails?.programs,
            customReportingState.reportDetails?.categories.some(
              (category: CategoryOption) => category.code === CONSTANTS.Programs
            )
          );
        }
        break;
      case CONSTANTS.Accreditations:
        const accreditationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Accreditations
          );

        if (!accreditationFieldSelected) {
          await getAccreditations();
          handleLoaderClose();
        }
        break;
      case CONSTANTS.Classifications:
        const classificationFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Classifications
          );

        if (!classificationFieldSelected) {
          await getClassifications(
            customReportingState.reportDetails?.certificates
          );
          handleLoaderClose();
        }
        break;
      case CONSTANTS.Certificates:
        const certificateFieldSelected =
          customReportingState.reportDetails?.categories.some(
            (category: CategoryOption) =>
              category.code === CONSTANTS.Certificates
          );

        if (!certificateFieldSelected) {
          await getCertificates(
            customReportingState.reportDetails?.accreditations
          );
          handleLoaderClose();
        }
        break;
      case CONSTANTS.selectAll:
        getVendors();
        getAccreditations();
        getClassifications(customReportingState.reportDetails?.certificates);
        getCertificates(customReportingState.reportDetails?.accreditations);

        handleLoaderClose();
        break;

      default:
    }

    if (
      actionMeta?.action == "remove-value" &&
      actionMeta?.removedValue?.code == CONSTANTS.Accreditations
    ) {
      getCertificates();
    }

    dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when Category is updated

    dispatch(
      setSelectedFields({
        ...customReportingState,
        selectedFields: [],
      })
    );
  };

  const handleAddScheduler = () => {
    if (
      validateDateRangeField(dateRangeValue) ||
      customReportingState.reportDetails?.frequency !== "custom_range"
    ) {
      dispatch(
        setCurrentTab({
          PREDEFINED_SCHEDULER: false,
          CUSTOM_SCHEDULER: true,
        })
      );

      dispatch(setShowMyReports(false));

      history.push({
        pathname: Routes.CUSTOM_REPORT_ADD_SCHEDULER,
        state: {
          isSavedFilter: routeParams?.id ? true : false,
        },
      });
    }
  };

  const getErrorTypeValue = (values: any, options: any) => {
    if (
      values &&
      Array.isArray(values) &&
      Array.isArray(options) &&
      values?.length > 0 &&
      options.length > 0
    ) {
      let selected = options.filter((item: any) => {
        if (_.includes(values, item.code)) {
          return item;
        }
      });

      return selected;
    }
  };

  const getCategoriesValue = () => {
    const categoryList: string[] =
      customReportingState.reportDetails?.categories.map(
        (category: CategoryOption) => category.code
      );

    if (categoryList.includes(CONSTANTS.selectAll)) {
      return activityCategories.filter(
        (category: CategoryOption) => category.code === CONSTANTS.selectAll
      );
    }

    return customReportingState.reportDetails?.categories;
  };

  const isCategoryOptionDisabled = (option: any) => {
    const categoryList: string[] =
      customReportingState.reportDetails?.categories.map(
        (category: CategoryOption) => category.code
      );

    switch (option?.code) {
      case CONSTANTS.Vendors: {
        return categoryList.includes(CONSTANTS.selectAll);
      }

      case CONSTANTS.Accreditations: {
        return categoryList.includes(CONSTANTS.selectAll);
      }

      case CONSTANTS.Programs: {
        return categoryList.includes(CONSTANTS.selectAll);
      }

      case CONSTANTS.Classifications: {
        return categoryList.includes(CONSTANTS.selectAll);
      }

      case CONSTANTS.Certificates: {
        return categoryList.includes(CONSTANTS.selectAll);
      }

      default:
        return false;
    }
  };

  const isDisabledSelectReportFields = () => {
    if (customReportingState.isErrorActivity) {
      return (
        convertStringToList(
          customReportingState.reportDetails?.rejectedErrors,
          ","
        )?.length === 0 &&
        convertStringToList(
          customReportingState.reportDetails?.mismatchedErrors,
          ","
        )?.length === 0
      );
    } else {
      return (
        customReportingState.reportDetails?.categories?.length === 0 &&
        _.isEmpty(customReportingState.reportDetails?.dateType) &&
        _.isEmpty(customReportingState.reportDetails?.startDate) &&
        _.isEmpty(customReportingState.reportDetails?.endDate)
      );
    }
  };
  const isDisabledViewReport = () => {
    if (_.isEmpty(customReportingState.customReportApiReqBody)) {
      return true;
    }
    if (!hasCategoryChanged()) {
      if (customReportingState.isErrorActivity) {
        return (
          convertStringToList(
            customReportingState.reportDetails?.rejectedErrors,
            ","
          )?.length === 0 &&
          convertStringToList(
            customReportingState.reportDetails?.mismatchedErrors,
            ","
          )?.length === 0
        );
      } else {
        return (
          convertStringToList(customReportingState.reportDetails?.vendors, ",")
            ?.length === 0 &&
          convertStringToList(customReportingState.reportDetails?.programs, ",")
            ?.length === 0 &&
          convertStringToList(
            customReportingState.reportDetails?.accreditations,
            ","
          )?.length === 0 &&
          convertStringToList(
            customReportingState.reportDetails?.classifications,
            ","
          )?.length === 0 &&
          convertStringToList(
            customReportingState.reportDetails?.certificates,
            ","
          )?.length === 0 &&
          customReportingState.reportDetails?.frequency === ""
        );
      }
    }
    return true;
  };

  const hasCategoryChanged = () => {
    let oldCategories: string[] = categories; //Categories from saved report filter or after doing select report fields > view report
    let newCategories: string[] = []; //categories that are currently selected in the dropdown

    customReportingState.reportDetails?.categories.forEach(
      (item: CategoryOption) => {
        newCategories = [...newCategories, _.lowerCase(item.code)];
      }
    );

    if (listEquals(newCategories, oldCategories)) {
      return false;
    }
    return true;
  };

  const handleReportTypeChange = (e: any) => {
    const reportType = e.target.value;

    if (reportType === CONSTANTS.errorActivities) {
      dispatch(
        setReportType({
          ...customReportingState,
          isAdminReport: false,
          isActivityReport: true,
          isSuccessActivity: false,
          isErrorActivity: true,
        })
      );
    } else if (reportType === CONSTANTS.successActivities) {
      dispatch(
        setReportType({
          ...customReportingState,
          isAdminReport: false,
          isActivityReport: true,
          isSuccessActivity: true,
          isErrorActivity: false,
        })
      );
    }

    dispatch(clearReportDetails());
  };

  const isSelectedCategory = (category: string): boolean => {
    let found = customReportingState.reportDetails?.categories.find(
      (element: CategoryOption) => element.code === category
    );
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <CustomReportContextProvider>
      <CustomReportsWrapper>
        <div className="custom-reports-wrapper w-100">
          <div className="container">
            <div className="archive-error-actvity-header">
              <PageHeading heading={t<string>("Custom Reports")}></PageHeading>
            </div>
            <GenerateReportWrapper>
              <div className="row inputs-b">
                <div className="col-lg-12 mb-0 position-relative d-flex flex-column justify-content-between flex-wrap flex-sm-nowrap">
                  <div className="generate-report-header row justify-content-between flex-column flex-md-row">
                    <h2
                      className="cp-view-h2 mb-0 pr-3 col-xs-12 col-md-5"
                      data-testid="activityReportHeaderTitle"
                    >
                      {routeParams?.id
                        ? `${t<string>("generateActivityReport")} -  ${customReportingState.reportName
                        } `
                        : t<string>("generateActivityReport")}
                    </h2>

                    <div className="col-xs-12 col-md-7 generate-report-header-links-wrapper text-md-right text-left mt-3 mt-md-0">
                      <span className="d-block d-sm-inline-block">
                        <aui-button
                          id="saveReportFilter"
                          buttontitle={t<string>("saveReportFilter")}
                          variant="link-style-arrow"
                          size="medium"
                          aria-label={t<string>("saveReportFilter")}
                          data-testid="saveReportFilter"
                          data-target={
                            routeParams?.id
                              ? "#changeReportFilterModal"
                              : "#saveReportFilterModal"
                          }
                          data-toggle={"modal"}
                          disabled={
                            customReportingState.disableSaveReportFilter ||
                            customReportingState?.isExportReportDisable
                          }
                        ></aui-button>
                      </span>
                      <span className="ml-0 ml-sm-5 d-block d-sm-inline-block mt-3 mt-sm-0">
                        <aui-button
                          id="addScheduler"
                          buttontitle={t<string>("addScheduler")}
                          aria-label={t<string>("addScheduler")}
                          data-testid="addScheduler"
                          variant="link-style-arrow"
                          size="medium"
                          onClick={handleAddScheduler}
                        ></aui-button>
                      </span>
                    </div>
                  </div>

                  <div className="radio-buttons">
                    <fieldset
                      id="group1"
                      className="d-flex align-item-center mt-4 mb-2 flex-column flex-sm-row"
                      onChange={(e) => handleReportTypeChange(e)}
                    >
                      <div className="align-items-start mb-3">
                        <input
                          type="radio"
                          id={CONSTANTS.errorActivities}
                          aria-label="error activity"
                          value={CONSTANTS.errorActivities}
                          name={CONSTANTS.errorActivities}
                          data-testid="ErrorActivities"
                          checked={customReportingState.isErrorActivity}
                          className="cursor-pointer"
                        />
                        <label
                          htmlFor={CONSTANTS.errorActivities}
                          className="pl-2 mb-0 text-left radio-label cursor-pointer"
                        >
                          {t<string>("errorList")}
                        </label>
                      </div>
                      <div className="ml-0 ml-sm-5">
                        <input
                          type="radio"
                          id={CONSTANTS.successActivities}
                          aria-label="success activity"
                          value={CONSTANTS.successActivities}
                          name={CONSTANTS.successActivities}
                          data-testid="SuccessActivities"
                          checked={customReportingState.isSuccessActivity}
                          className="cursor-pointer"
                        />
                        <label
                          htmlFor={CONSTANTS.successActivities}
                          className="pl-2 mb-0 text-left radio-label cursor-pointer"
                        >
                          {t<string>("activities")}
                        </label>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12"
                  data-testid="category_field"
                >
                  <Select
                    id="Category"
                    className="multi text-box"
                    classNamePrefix="list"
                    aria-label="selectCategory"
                    value={getCategoriesValue()}
                    placeholder={t<string>("selectCategory")}
                    getOptionValue={(category: any) => {
                      return category.id;
                    }}
                    getOptionLabel={(category: any) => {
                      return category.code;
                    }}
                    options={getCategoryOptions()}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    isOptionDisabled={isCategoryOptionDisabled}
                    onChange={(selected: any, actionMeta: ActionMeta<any>) => {
                      const latestCategory = selected[selected.length - 1];
                      handleCategoryChange(
                        latestCategory,
                        selected,
                        actionMeta
                      );

                      // If we remove any category than corresponding state should also get cleared
                      let currentCategories =
                        customReportingState.reportDetails?.categories.map(
                          (item: CategoryOption) => item.code
                        );
                      let currentSelected = selected.map(
                        (item: CategoryOption) => item.code
                      );

                      if (currentCategories.length > selected.length) {
                        currentCategories.forEach((item: any) => {
                          if (!currentSelected.includes(item)) {
                            dispatch(
                              setReportDetails({
                                ...customReportingState,
                                key: _.lowerCase(item),
                                value: "",
                              })
                            );
                          }
                        });
                      }

                      if (
                        latestCategory &&
                        latestCategory.code === CONSTANTS.selectAll
                      ) {
                        dispatch(
                          setCategory({
                            ...customReportingState,
                            categories: activityCategories.filter(
                              (item: CategoryOption) =>
                                item.code === CONSTANTS.Vendors ||
                                item.code === CONSTANTS.Accreditations ||
                                item.code === CONSTANTS.Certificates ||
                                item.code === CONSTANTS.Classifications ||
                                item.code === CONSTANTS.Programs
                            ),
                          })
                        );
                      } else {
                        dispatch(
                          setCategory({
                            ...customReportingState,
                            categories: selected,
                          })
                        );
                      }
                    }}
                    // @ts-ignore
                    allowSelectAll={true}
                    styles={customStyles}
                  />
                </div>

                <div
                  className="col-lg-4 col-md-6 col-sm-12 px-10 my-20"
                  data-testid="dateTypeField"
                >
                  <RenderInputSelect
                    elementObject={{
                      id: "dateType_field",
                      name: "selectDateType",
                      placeholder: t<string>("selectDateType"),
                      data: activityDateTypeFields,
                      required: false,
                      className: "multi text-box non-react-select-field",
                      selected: customReportingState.reportDetails?.dateType,
                    }}
                    handleData={(e) => handleDateTypeChange(e)}
                  />
                </div>

                <div
                  className="col-lg-4 col-md-6 col-sm-12 px-10 my-20"
                  data-testid="frequency_field"
                >
                  <RenderInputSelect
                    elementObject={{
                      id: "selectFrequency",
                      name: "selectFrequency",
                      placeholder: t<string>("selectFrequency"),
                      data: reportFrequency,
                      required: true,
                      className: classNames(
                        "multi text-box non-react-select-field",
                        {
                          "required-field": frequencyErrorClass,
                        }
                      ),
                      selected: customReportingState.reportDetails?.frequency,
                    }}
                    handleData={(e) => handleFrequencyChange(e)}
                  />
                </div>

                <div
                  className="col-lg-4 col-md-6 col-sm-12 px-10 row-margin"
                  data-testid="dateRange_field"
                >
                  {customReportingState.reportDetails?.frequency ===
                    "custom_range" ? (
                    <DateRangePicker
                      appearance="default"
                      placeholder="Select Date Range"
                      format="yyyy-MM-dd"
                      data-testid="daterange"
                      className={`w-100 text-truncate form-control inputs text-box non-react-select-field ${dateRangeErrorClass ? "required-field" : ""
                        }`}
                      style={{ color: "Red", display: "block" }}
                      onChange={(dates, ev) => {
                        dateChangeHanler(formatDate(dates));
                      }}
                      disabled={
                        customReportingState.reportDetails?.frequency !==
                        "custom_range"
                      }
                      // @ts-ignore
                      value={
                        customReportingState.reportDetails?.startDate !== "" &&
                        customReportingState.reportDetails?.endDate !== "" &&
                        customReportingState.reportDetails?.frequency ===
                        "custom_range" && [
                          new Date(
                            customReportingState.reportDetails?.startDate
                          ),
                          new Date(customReportingState.reportDetails?.endDate),
                        ]
                      }
                      // @ts-ignore
                      placement="bottomStart"
                    />
                  ) : (
                    <CustDateRangePicker
                      changeHanler={(value: string) => {
                        dateRangeChange(value);
                      }}
                      disabled={
                        customReportingState.reportDetails?.frequency !==
                        "custom_range"
                      }
                      className="w-100 text-truncate form-control inputs text-box non-react-select-field"
                    />
                  )}
                </div>

                {isSelectedCategory(CONSTANTS.Vendors) && (
                  <div
                    key={CONSTANTS.Vendors}
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id={CONSTANTS.Vendors}
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="multi-select"
                      value={filterSelectedMultiSelect(
                        OptionsData?.vendorList,
                        convertStringToList(
                          customReportingState.reportDetails?.vendors,
                          ","
                        ),
                        CONSTANTS.Vendors
                      )}
                      placeholder={`All Vendor Selected`}
                      getOptionValue={(vendor: any) => {
                        return vendor.id;
                      }}
                      getOptionLabel={(vendor: any) => {
                        return vendor.name;
                      }}
                      options={OptionsData?.vendorList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Vendors)
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>
                )}
                {isSelectedCategory(CONSTANTS.Accreditations) && (
                  <div
                    key={CONSTANTS.Accreditations}
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id={CONSTANTS.Accreditations}
                      name="selectAccreditation"
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectAccreditation"
                      value={filterSelectedMultiSelect(
                        OptionsData?.accreditationList,
                        convertStringToList(
                          customReportingState.reportDetails?.accreditations,
                          ","
                        ),
                        CONSTANTS.Accreditations
                      )}
                      placeholder={`All Accreditation Selected`}
                      getOptionValue={(accreditation: any) => {
                        return accreditation.id;
                      }}
                      getOptionLabel={(accreditation: any) => {
                        return accreditation.code;
                      }}
                      options={OptionsData?.accreditationList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Accreditations)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.classifications,
                          customReportingState.reportDetails?.certificates,
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs
                          )
                        );
                        getCertificates(
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          [],
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Certificates
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      //isDisabled={customReportState.isErrorTypeSelected}
                      styles={customStyles}
                    />
                  </div>
                )}
                {isSelectedCategory(CONSTANTS.Certificates) && (
                  <div
                    key={CONSTANTS.Certificates}
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id={CONSTANTS.Certificates}
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectCertificates"
                      value={filterSelectedMultiSelect(
                        OptionsData?.certificateList,
                        convertStringToList(
                          customReportingState.reportDetails?.certificates,
                          ","
                        ),
                        CONSTANTS.Certificates
                      )}
                      placeholder={`All Certificate Selected`}
                      getOptionValue={(certificate: any) => {
                        return certificate.id;
                      }}
                      getOptionLabel={(certificate: any) => {
                        return certificate.name;
                      }}
                      options={OptionsData?.certificateList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Certificates)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          customReportingState.reportDetails?.accreditations,
                          customReportingState.reportDetails?.classifications,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs
                          )
                        );

                        getClassifications(
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Classifications
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>
                )}
                {isSelectedCategory(CONSTANTS.Classifications) && (
                  <div
                    key={CONSTANTS.Classifications}
                    className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin"
                    data-testid="custom-field"
                  >
                    <Select
                      id={CONSTANTS.Classifications}
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectClassifications"
                      value={filterSelectedMultiSelect(
                        OptionsData?.classificationList,
                        convertStringToList(
                          customReportingState.reportDetails?.classifications,
                          ","
                        ),
                        CONSTANTS.Classifications
                      )}
                      placeholder={`All Classification Selected`}
                      getOptionValue={(classification: any) => {
                        return classification.id;
                      }}
                      getOptionLabel={(classification: any) => {
                        return classification.name;
                      }}
                      options={OptionsData?.classificationList}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={true}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Classifications)
                        );

                        getPrograms(
                          customReportingState.reportDetails?.vendors,
                          customReportingState.reportDetails?.accreditations,
                          convertListToString(
                            selected.map((item: any) => item.code)
                          ),
                          customReportingState.reportDetails?.certificates,
                          customReportingState.reportDetails?.startDate,
                          customReportingState.reportDetails?.endDate,
                          customReportingState.reportDetails?.programs,
                          customReportingState.reportDetails?.categories.some(
                            (category: CategoryOption) =>
                              category.code === CONSTANTS.Programs
                          )
                        );
                      }}
                      // @ts-ignore
                      allowSelectAll={true}
                      styles={customStyles}
                    />
                  </div>
                )}
                {isSelectedCategory(CONSTANTS.Programs) && (
                  <div
                    key={CONSTANTS.Programs}
                    className="col-md-8 col-sm-12 px-10 row-margin"
                    data-testid="custom-field"
                  >
                    <AsyncPaginate
                      id={CONSTANTS.Programs}
                      className="multi text-box"
                      classNamePrefix="list"
                      aria-label="selectPrograms"
                      value={filterSelectedMultiSelect(
                        OptionsData?.programList,
                        convertStringToList(
                          customReportingState.reportDetails?.programs,
                          ","
                        ),
                        CONSTANTS.Programs
                      )}
                      loadOptions={programLoadOptions}
                      getOptionValue={(program: any) => {
                        return program.code;
                      }}
                      getOptionLabel={(program: any) => {
                        return `${program.name} (${program.code})`;
                      }}
                      onChange={(selected: any) => {
                        handleMultiSelect(
                          selected,
                          _.lowerCase(CONSTANTS.Programs)
                        );
                      }}
                      isSearchable={true}
                      placeholder={`All Program Selected`}
                      additional={{
                        page: 1,
                      }}
                      isMulti
                      closeMenuOnSelect={false}
                      //isDisabled={customReportState.isErrorTypeSelected}
                      styles={customStyles}
                      debounceTimeout={500}
                      shouldLoadMore={shouldLoadMore}
                      cacheUniqs={[
                        customReportingState.reportDetails
                          ?.accreditations,
                        customReportingState.reportDetails
                          ?.certificates,
                        customReportingState.reportDetails
                          ?.classifications,
                        customReportingState.reportDetails?.frequency,
                        customReportingState.reportDetails?.startDate,
                      ]}
                    />
                  </div>
                )}

                {customReportingState.isErrorActivity && (
                  <React.Fragment key="item.id">
                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin">
                      <Select
                        id={"mismatched"}
                        className="multi text-box"
                        aria-label="multi-select"
                        classNamePrefix="list"
                        value={getErrorTypeValue(
                          convertStringToList(customReportingState.reportDetails
                            ?.mismatchedErrors, ","),
                          MismatchErrorTags
                        )}
                        placeholder="Select Mismatch Errors"
                        getOptionValue={(mismatchError: any) => {
                          return mismatchError.code;
                        }}
                        getOptionLabel={(mismatchError: any) => {
                          return mismatchError.name;
                        }}
                        options={MismatchErrorTags}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        onChange={(selected: any) => {
                          handleMultiSelect(
                            selected,
                            _.lowerCase(CONSTANTS.mismatchErrors)
                          );
                          dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when error filter is updated
                        }}
                        // @ts-ignore
                        allowSelectAll={true}
                        styles={customStyles}
                        isDisabled={
                          convertStringToList(
                            customReportingState.reportDetails?.rejectedErrors,
                            ","
                          ) &&
                          convertStringToList(
                            customReportingState.reportDetails?.rejectedErrors,
                            ","
                          )?.length > 0
                        }
                      />
                    </div>

                    <div className="col-lg-4 col-xl-4 col-md-6 col-sm-12 col-xs-12 row-margin">
                      <Select
                        id={"rejected"}
                        className="multi text-box"
                        classNamePrefix="list"
                        aria-label="multi-select"
                        value={getErrorTypeValue(
                          convertStringToList(
                            customReportingState.reportDetails?.rejectedErrors,
                            ","
                          ),
                          RejectedErrorTags
                        )}
                        placeholder="Select Rejected Errors"
                        getOptionValue={(rejectedError: any) => {
                          return rejectedError.code;
                        }}
                        getOptionLabel={(rejectedError: any) => {
                          return rejectedError.name;
                        }}
                        options={RejectedErrorTags}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        onChange={(selected: any) => {
                          handleMultiSelect(
                            selected,
                            _.lowerCase(CONSTANTS.rejectedErrors)
                          );
                          dispatch(setDisableSaveReportFilter(true)); //Disable Save Report Filter Button when error filter is updated
                        }}
                        // @ts-ignore
                        allowSelectAll={true}
                        styles={customStyles}
                        isDisabled={
                          convertStringToList(
                            customReportingState.reportDetails
                              ?.mismatchedErrors,
                            ","
                          ) &&
                          convertStringToList(
                            customReportingState.reportDetails
                              ?.mismatchedErrors,
                            ","
                          )?.length > 0
                        }
                      />
                    </div>
                  </React.Fragment>
                )}

                <div className="bdr-btm w-90"></div>

                <div className="button-div-block">
                  <div className="aui-btn-wrapper">
                    <aui-button
                      buttonclass="w-100"
                      data-testid="btnSelectFields"
                      variant="secondary"
                      buttontitle={t<string>("selectReportFields")}
                      onClick={selectFieldsPage}
                      size="medium"
                      disabled={isDisabledSelectReportFields()}
                    ></aui-button>
                  </div>
                  <div className="aui-btn-wrapper">
                    <aui-button
                      buttonclass="w-100"
                      data-testid="btnViewReport"
                      variant="primary"
                      buttontitle={t<string>("viewReport")}
                      onClick={handleViewReport}
                      size="medium"
                      disabled={isDisabledViewReport()}
                    ></aui-button>
                  </div>
                </div>
              </div>
              <ModalPopupComp
                id={"saveReportFilterModal"}
                childrenHeader={
                  <ModalHeader
                    component="CustomReport"
                    setSavedReportName={setSavedReportName}
                    setShowError={setShowError}
                    setReportNameErrorClass={setReportNameErrorClass}
                  >
                    Save Report Filter
                  </ModalHeader>
                }
                childrenBody={
                  <SaveReportBody
                    setSavedReportName={setSavedReportName}
                    setShowError={setShowError}
                    setReportNameErrorClass={setReportNameErrorClass}
                    savedReportName={savedReportName}
                    reportNameErrorClass={reportNameErrorClass}
                    showError={showError}
                    validateDateRange={validateDateRangeField}
                  />
                }
                modalSize="modal-sm"
                childrenBodyClass="save-report-modal-body"
                popupDiscardFunc={modalPopupProps.popupDiscardFunc}
              />

              <ModalPopupComp
                id={"changeReportFilterModal"}
                childrenHeader={
                  <ModalHeader
                    component="CustomReport"
                    saveReportNameRef={saveReportNameRef}
                    setShowError={setShowError}
                    setReportNameErrorClass={setReportNameErrorClass}
                  >
                    Save Report Filter
                  </ModalHeader>
                }
                childrenBody={
                  <ChangeReportModalBody
                    validateDateRangeField={validateDateRangeField}
                    fetchFilters={fetchFilter}
                    reportName={customReportingState.reportName}
                    saveReportNameRef={saveReportNameRef}
                    reportNameErrorClass={reportNameErrorClass}
                    showError={showError}
                    setShowError={setShowError}
                    setReportNameErrorClass={setReportNameErrorClass}
                  />
                }
                modalSize="modal-sm"
                childrenBodyClass="save-report-modal-body"
                popupDiscardFunc={modalPopupProps.popupDiscardFunc}
              />
            </GenerateReportWrapper>
          </div>
        </div>
        <ViewReport
          data={customReportingState?.previewData?.data?.customReport}
          columns={customReportingState?.tableColumns}
        />
      </CustomReportsWrapper>
    </CustomReportContextProvider>
  );
};

export default ActivityReport;

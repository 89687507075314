import React, { useEffect, useRef, useState } from "react";
import RenderInputSelect from "components/InputFields/RenderInputSelect";
import { exportType } from "common/constants";
import { useTranslation } from "react-i18next";
import {
  CustomReportStateConstants,
  useCustomReportContext,
} from "./CustomReportContext/CustomReportContext";
import CustomisedTable from "components/CustomisedTable";
import CONSTANTS from "common/constants/constants";
import _, { isEmpty } from "lodash";
import {
  onBlurPageSize,
  onChangePageSize,
  toggleNoOfRecordTableFilter,
} from "common/utils/commonFunctionality";
import Pagination from "components/Pagination";
import { ViewReportWrapper } from "./Styled";
import { setPageLoadingStatus } from "components/Loader/loader.slice";
import {
  ExportCustomReport,
  ListCustomReportByFilters,
} from "services/api/report.api";
import store from "app/store";
import { showToast } from "components/Toast/toast.slice";
import { useDispatch, useSelector } from "react-redux";
import { ExportCustomReportApiReqBody } from "./CustomReportConfig";
import ModalPopupComp from "components/ModalPopup";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as constants from "common/constants";
import { handleAPIError } from "common/ErrorHandler";
import { setShowMyReports } from "components/AdminLeftPanel/LeftPanelSlice";
import CheckBox from "components/InputFields/RanderInputCheckBox";
import {
  setNotifyMe,
  setPaginationData,
  setPreviewData,
} from "./CustomReportSlice";
import { ModalHeader } from "components/ModalPopup/ModalHeader";
import AuiIcon from "components/IconComponent/AuiIcon";

const DownloadPopupBody = ({
  notifyMe,
  downloadPopupClose,
  savedReportName,
  handleReportName,
  showError,
  handleCustomReportLogRedirect,
}: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [reportNameErrorClass, setReportNameErrorClass] =
    useState<boolean>(false);

  const location = useLocation();
  return (
    <div className="downloadwrapper text-left">
      {notifyMe ?? false ? (
        <p
          className="aui-confirmationtext text-left mb-2 pb-2"
          data-testid="downloadMsg"
        >
          {t<string>("customReportExportTriggeredWithNotify")}
        </p>
      ) : (
        <p
          className="aui-confirmationtext text-left mb-2 pb-2"
          data-testid="downloadMsg"
        >
          {t<string>("customReportExportTriggeredWithoutNotify")}
        </p>
      )}

      <p
        className="aui-confirmationtext text-left mb-2 pb-2"
        data-testid="alert_msg"
      >
        {t<string>("alertMsg")}
      </p>

      {!location?.state?.fromSavedReport && !location?.state?.isSavedFilter && (
        <div className="form-group input-section">
          <label className="input-section-label" htmlFor="name">
            {t<string>("reportname")}
          </label>
          <input
            className={`input-section-text form-control ${
              reportNameErrorClass ? "date-range-error" : ""
            }`}
            type="text"
            id="name"
            value={savedReportName}
            onChange={(e: any) => handleReportName(e.target.value)}
            required
          />
          <span
            className={`danger-text ${
              showError?.length > 0 ? "block" : "d-none"
            }`}
            data-testid="error_msg"
          >
            {showError.charAt(0).toUpperCase() + showError.slice(1)}
          </span>
        </div>
      )}

      <div className="mt-3 text-center d-sm-flex d-block justify-content-between">
        <aui-button
          buttonclass="w-100 mb-2"
          data-testid="btnDownloadReport"
          variant="primary"
          buttontitle={t<string>("ok")}
          onClick={downloadPopupClose}
          size="medium"
        ></aui-button>
        <aui-button
          buttonclass="w-100 mb-2"
          data-testid="btnReportLog"
          variant="primary"
          buttontitle={t<string>("customReportLog")}
          onClick={handleCustomReportLogRedirect}
          size="medium"
        ></aui-button>
      </div>
    </div>
  );
};

const ViewReport = ({ data, columns }: any) => {
  const [showError, setShowError] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const [savedReportName, setSavedReportName] = useState("");

  const dispatch = useDispatch();
  const routeParams: any = useParams();
  const customReportingState = useSelector((state: any) => {
    return state.customReport;
  });

  const { customReportState, dispatch: customReportDispatch } =
    useCustomReportContext();

  const [paginations, setPaginations] = useState({
    pageNumber: 1,
    pageSize: 25,
  });

  const [exportTypeErrorClass, setExportTypeErrorClass] =
    useState<boolean>(false);

  const [selectedExportType, setSelectedExportType] = useState<
    "csv" | "xlsx" | ""
  >("");

  const [showDownloadPopup, setShowDownloadPopup] = useState(false);
  const saveReportNameRef = useRef<HTMLInputElement>(null);

  const countRef = useRef(0);

  const generateReportData = async () => {
    dispatch(setPageLoadingStatus({ isPageLoading: true }));

    try {
      const response = await ListCustomReportByFilters(
        customReportingState.customReportApiReqBody,
        paginations
      );

      if (response?.data?.status === 200) {
        dispatch(
          setPreviewData({
            ...customReportingState,
            previewData: response?.data,
          })
        );

        customReportDispatch({
          type: CustomReportStateConstants.setViewReportData,
          payload: {
            viewReportData: response?.data?.data?.customReport,
          },
        });

        dispatch(
          setPaginationData({
            ...customReportingState,
            pagination: response?.data?._pagination,
          })
        );

        customReportDispatch({
          type: CustomReportStateConstants.setPagination,
          payload: {
            pagination: response?.data?._pagination,
          },
        });
      }
    } catch (err: any) {
      store.dispatch(
        showToast({
          title: "CustomReport",
          message: err.response?.data?.error?.details[0]?.message,
        })
      );
    }

    dispatch(setPageLoadingStatus({ isPageLoading: false }));
  };

  useEffect(() => {
    countRef.current !== 0 && generateReportData();

    countRef.current++;
  }, [paginations]);

  const onChangePageNumber = (pageNumber: number) => {
    setPaginations({ ...paginations, pageNumber: pageNumber });
    window.scrollTo(0, 0);
  };

  const setPageSizeHandler = (size: number) => {
    setPaginations({ ...paginations, pageSize: size });
  };

  const handleCheckBox = (event: any) => {
    const { checked } = event.target;

    dispatch(setNotifyMe({ ...customReportingState, notifyMe: checked }));
    customReportDispatch({
      type: CustomReportStateConstants.setNotifyMe,
      payload: {
        notifyMe: checked,
      },
    });
  };

  const [cme_pageID] = useState(CONSTANTS.CUSTOM_REPORT_PAGEID);

  // ------ Export Custom Report --------
  const validateExportType = (value: any) => {
    if (_.isEmpty(value)) {
      setExportTypeErrorClass(true);
      return false;
    } else {
      setExportTypeErrorClass(false);
      return true;
    }
  };

  const exportClickHandler = () => {
    if (validateExportType(selectedExportType)) {
      setShowDownloadPopup(true);
      setShowError("");
      setSavedReportName("");
    }
  };
  const exportReport = async (redirect: boolean = false) => {
    try {
      if (validateExportType(selectedExportType)) {
        const filterObj: ExportCustomReportApiReqBody = {
          filters: {
            ...customReportingState.customReportApiReqBody.filters,
            exportType: selectedExportType,
            notifyMe: customReportingState.notificationDetails?.notifyMe,
            schedulerID: 1,
            triggeredBy: "CUSTOM_REPORT_MANUAL",
          },
          reportName: savedReportName.trim(),
          customField: {
            ...customReportingState.customReportApiReqBody.customField,
          },
        };

        if (routeParams?.id) {
          filterObj["reportId"] = parseInt(routeParams.id);
        }

        const response = await ExportCustomReport(filterObj);

        if (response?.data?.status === 202) {
          setSavedReportName("");
          setShowError("");
          setShowDownloadPopup(false);
          if (redirect) {
            history.push(constants.Routes.CUSTOM_REPORT_LOG_PAGE);
          }
          saveReportNameRef.current?.click();
          return true;
        }
      }
    } catch (error: any) {
      if (
        String(error?.message).startsWith("Request failed with status code 400")
      ) {
        setShowDownloadPopup(true);
        setShowError("Report Name already exists");
      }
      if (
        !String(error?.message).startsWith(
          "Request failed with status code 400"
        )
      ) {
        handleAPIError(error, history, dispatch);
      }
    }
  };

  const downloadPopupClose = () => {
    exportReport(false);
  };

  const handleCustomReportLogRedirect = () => {
    dispatch(setShowMyReports(false));
    exportReport(true);
  };

  const handleReportName = (reportName: any) => {
    setSavedReportName(reportName);
  };

  return (
    <ViewReportWrapper>
      {customReportingState.showViewReport && (
        <div className="view-report-wrapper w-100">
          <div className="view-report-container ">
            <div className="view-report w-100 justify-content-between align-items-center">
              <h5
                className="view-report-title pr-lg-3 pr-0 mb-3 mb-lg-0"
                data-testid="viewReportTitle"
              >
                {t<string>("viewReport")}
              </h5>
              <div className="d-lg-flex d-md-flex d-sm-flex align-items-center justify-content-lg-end">
                {customReportingState.showViewReport && (
                  <div className="d-flex align-items-center mr-3 pr-3 checkbox-border">
                    <label
                      className="mb-0 pr-3 d-inline-block pb-0"
                      htmlFor="notifyMe"
                    >
                      {t<string>("notifyMe")}
                    </label>
                    <div className="position-relative">
                      <CheckBox
                        elementObject={{
                          type: "checkbox",
                          id: "notifyMe",
                          name: "notifyMe",
                          defaultChecked:
                            customReportingState.notificationDetails?.notifyMe,
                          handleData: handleCheckBox,
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center mb-3 mb-sm-0">
                  <p className="export-text text-nowrap">
                    {t<string>("exportTo")}
                  </p>
                  <div
                    className="aui-search-dropdown"
                    data-testid="exportTypeDropdown"
                  >
                    <RenderInputSelect
                      elementObject={{
                        id: "exportType",
                        name: "exportType",
                        data: exportType,
                        className: exportTypeErrorClass ? "required-field" : "",
                      }}
                      handleData={(event: any) =>
                        setSelectedExportType(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="ml-lg-3 ml-md-3 ml-sm-3 exportReport">
                  <aui-button
                    variant="primary"
                    buttonclass="w-100"
                    data-target="#modal_backbtn"
                    buttontitle={t<string>("export")}
                    data-testid="exportButton"
                    data-toggle="modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    onClick={() => {
                      if (!customReportingState?.isExportReportDisable) {
                        exportClickHandler();
                      }
                    }}
                    disabled={customReportingState?.isExportReportDisable}
                    size="medium"
                  ></aui-button>
                </div>
              </div>
            </div>

            <div
              className="table-responsive aui-table table-Container custom-report-table"
              data-testid="customisedTable"
            >
              <CustomisedTable
                columnFields={columns || {}}
                viewContent={() => {
                  /* do nothing */
                }}
                editContent={() => {
                  /* do nothing */
                }}
                tableLoader={false}
                contents={data || []}
                //actions={actionRef.current}
                isActiveToggle={() => {
                  /* do nothing */
                }}
                cme_pageID={cme_pageID}
              >
                <div className="filerHeader filter-nav d-flex w-100 cp-mrgnb-20 align-items-end cp-flex-direction align-items-center">
                  <div
                    className="mr-auto page-number filterHeaderChildElement"
                    onBlur={onBlurPageSize}
                    data-testid="table-length-text"
                  >
                    {t<string>("showing")}{" "}
                    {`${
                      customReportingState?.pagination &&
                      customReportingState?.pagination?.pageOffset | 0
                    } - ${
                      customReportingState?.pagination?.totalPages !==
                      customReportingState.pagination.pageNumber
                        ? customReportingState.pagination.pageNumber *
                          customReportingState.pagination.pageSize
                        : customReportingState?.pagination?.totalCount
                    }`}
                    <div className="svg-parent position-relative">
                      <label
                        htmlFor="pagenumberitems1"
                        className="position-absolute invisible-cls"
                      >
                        {t<string>("pagenumberitems")}
                      </label>
                      <div
                        className="keydown-recordfilter d-inline-block"
                        tabIndex={0}
                        onClick={toggleNoOfRecordTableFilter}
                        aria-label="Please choose number of items should be displayed in one page"
                        role="combobox"
                        aria-controls="pagenumberitems1"
                        aria-owns="pagenumberitems1"
                        aria-expanded="true"
                        aria-haspopup="listbox"
                      >
                        <input
                          type="text"
                          id="pagenumberitems1"
                          aria-label="pagenumberitems1"
                          data-testid="pagenumberitems1"
                          className="position-absolute invisible-cls"
                          tabIndex={-1}
                        />
                        <AuiIcon
                          elementObject={{
                            svgclass: "aui-icon-arrowdown",
                            pathclass: "aui-path-arrowdown",
                            icon: "arrowdown",
                            svgwidth: "16",
                            svgheight: "16",
                          }}
                        ></AuiIcon>
                      </div>
                      <div
                        className="page-sort position-absolute m-0 d-none pagecontent-filter"
                        id="pagelistitems_filter"
                        role="listbox"
                        aria-label={t<string>("filter")}
                      >
                        {CONSTANTS.TableRecordSizeList.map((page) => (
                          <div
                            tabIndex={0}
                            role="option"
                            data-testid="tableRecordSizeElement"
                            className={
                              paginations?.pageSize === page ? "active" : ""
                            }
                            key={page}
                            onClick={(e) => {
                              setPageSizeHandler(page);
                              onChangePageSize(e);
                            }}
                          >
                            {page}
                          </div>
                        ))}
                      </div>
                    </div>
                    {`of ${
                      customReportingState?.pagination?.totalCount
                    } ${t<string>("items")}`}
                  </div>

                  <div className="sort-by filterHeaderChildElement text-right">
                    {t<string>("sortByLatest")}
                    <div className="svg-parent position-relative">
                      <div
                        className="d-inline-block"
                        onKeyDown={() => {
                          /* do nothing */
                        }}
                        tabIndex={0}
                        onClick={() => {
                          /* do nothing */
                        }}
                        aria-label={t<string>(
                          "pleaseChooseNumberOfItemsShouldBeDisplayedInOnePage"
                        )}
                        role="combobox"
                        aria-owns="pagenumberitems"
                        aria-controls="pagenumberitems"
                        aria-expanded="true"
                        aria-haspopup="listbox"
                      >
                        <input
                          type="text"
                          id="pagenumberitems"
                          aria-label="pagenumberitems"
                          className="position-absolute invisible-cls"
                          tabIndex={-1}
                        />
                        {"Latest"}
                      </div>
                    </div>
                  </div>

                  {customReportingState?.previewData &&
                    customReportingState?.previewData?.data?.customReport
                      ?.length > 0 && (
                      <div className="nav-pagination-div">
                        <Pagination
                          {...customReportingState.pagination}
                          setPageNumber={onChangePageNumber}
                          paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                        ></Pagination>
                      </div>
                    )}
                </div>
              </CustomisedTable>
            </div>
            {customReportingState?.previewData &&
              customReportingState?.previewData?.data?.customReport?.length >
                0 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                  <Pagination
                    {...customReportingState.pagination}
                    setPageNumber={onChangePageNumber}
                    paginationSetLimit={CONSTANTS.PAGINATION_LIMIT}
                  ></Pagination>
                </div>
              )}
          </div>
        </div>
      )}
      {showDownloadPopup && (
        <div data-testid="modal_div">
          <ModalPopupComp
            id={"modal_backbtn"}
            childrenHeader={
              <ModalHeader
                component="ReportFilter"
                saveReportNameRef={saveReportNameRef}
                closePopUpFn={() => {
                  setShowDownloadPopup(false);
                }}
              >
                Export
              </ModalHeader>
            }
            childrenBody={
              <DownloadPopupBody
                notifyMe={customReportingState.notificationDetails?.notifyMe}
                downloadPopupClose={downloadPopupClose}
                exportReport={exportReport}
                handleReportName={handleReportName}
                savedReportName={savedReportName}
                showError={showError}
                setShowError={setShowError}
                handleCustomReportLogRedirect={handleCustomReportLogRedirect}
              />
            }
            childrenBodyClass="mt-3"
          />
        </div>
      )}
    </ViewReportWrapper>
  );
};

export default ViewReport;

import styled from "styled-components";

const AdminWrapper = styled.div`
  height: 100%;

  @media (max-width: 991px) {
    .left-panel {
      .main {
        margin: 0px !important;
      }

      .left-nav-bar,
      .sidenav.left-nav-bar-show .expandButton button {
        right: -12px !important;
      }

      .sidenav.left-nav-bar-show {
        display: block;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        transform: translateX(0%);
        transition: transform 0.5s;
      }

      .left-panel-logo {
        visibility: hidden;
      }

      .aui-sidenav {
        margin-bottom: 0px;
      }

      .logo {
        position: relative;
        display: none;

        .close {
          display: block !important;
          position: absolute;
          right: 1.5rem;
          color: #8e8e8e;
          font-size: 25px;
        }
      }

      .main-content {
        width: 100%;
        clear: both;

        .main-content-logo {
          // visibility: visible !important;
          display: block;
        }
      }

      .main-content-hide {
        overflow: hidden;
        max-height: 100vh;
      }
    }
  }

  .left-panel {
    display: flex;
    width: 100%;
    height: 100%;

    .sidenav {
      height: 100%;
      position: fixed;
      z-index: 99;
      top: 0;
      left: 0;
      background-color: #f8f8f8;
      padding-top: 0px;
      transition: width 0.5s;
      /* overflow-y: auto; */
      width: 20%;

      @media screen and (min-width: 992px) {
        /*  overflow:visible; */
      }

      @media screen and (max-width: 991px) {
        width: 80%;
        left: 0;
        transform: translateX(-110%);
        background: rgba(0, 0, 0, 0.6);
        transition: transform 0.5s;
      }
    }

    .main {
      margin-left: 20%; /* Same as the width of the sidenav */
      padding: 0px 0px;
      min-height: 100vh;
    }

    .logo {
      .close {
        display: none;
      }
    }

    .main-content {
      display: flex;
      flex-direction: column;

      .main-content-logo {
        // visibility: hidden;
      }
    }

    .aui-sidenav ul {
      padding-top: 0px;

      li .btn {
        line-height: 1.5;
        justify-content: flex-start;
      }
    }
    ul.main-nav-acc {
      height: calc(100vh - 135px);
      overflow-y: auto;
      overflow-x: hidden;
      @media screen and (max-width: 991px) {
        height: calc(100vh - 230px);
      }
    }
    .mbl-header-nav {
      height: 200px;
      overflow: hidden;
    }
    .aui-sidenav-acc-header {
      color: #343a40;
      border-radius: 0;
    }

    .aui-sidenav-acc-header.parentHighlight {
      span {
        svg {
          path,
          g,
          ellipse {
            stroke: #c10e21;
          }
        }
        aui-icon::part(icon) {
          color: rgb(193, 14, 33);
        }
      }
      span.fill-icon {
        svg {
          path,
          g,
          ellipse {
            fill: #c10e21;
            stroke: initial;
          }
        }
      }
      .nav-item-text,
      .acc-btn-arrow {
        color: #c10e21;
      }
    }

    // .active {
    //   background-color: #fff;
    //   color: black;
    // }

    .aui-sidenav-acc-body li a {
      color: #222328;
      border-left: 3px solid transparent;
      padding: 0.2rem 0.475rem 0.2rem 3.875rem;
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding-left: 2.775rem;
      }
      @media screen and (max-width: 767px) {
        padding-left: 3.2rem;
      }
      @media screen and (max-width: 575px) {
        padding-left: 2.9rem;
      }
    }

    .aui-sidenav {
      width: 100%;

      .navbar {
        height: 135px;
        overflow: hidden;
      }
    }
    .aui-sidenav.left-panel-contents {
      @media screen and (max-width: 991px) {
        width: 80%;
        padding-top: 4rem;
      }
    }
  }
  .left-panel .aui-sidenav ul li .btn {
    &:hover span svg g {
      stroke: #c10e21;
    }
    &:hover aui-icon::part(icon) {
      color: rgb(193, 14, 33);
    }
  }
  .left-panel .aui-sidenav ul li .btn {
    &:hover span svg path {
      stroke: #c10e21;
    }
  }
  .left-panel .aui-sidenav ul li .btn {
    &:hover span.fill-icon svg path {
      fill: #c10e21;
      stroke: initial;
    }
  }
  .left-panel .aui-sidenav ul li .btn {
    &:hover span svg ellipse {
      stroke: #c10e21;
    }
  }
  .left-panel .aui-sidenav ul li .btn {
    &:hover span.fill-icon svg g {
      fill: #c10e21;
      stroke: initial;
    }
  }
  .aui-sidenav ul li a:hover,
  .aui-sidenav ul li .btn:hover {
    color: #c10e21;
  }
  .aui-sidenav ul li a,
  .aui-sidenav ul li .btn {
    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 1rem;
    }
  }

  .left-panel .aui-sidenav-acc-body li a:focus,
  .left-panel .aui-sidenav-acc-header:focus {
    box-shadow: none !important;
    outline: 0 !important;
  }

  ul.aui-sidenav-acc-body.collapse li a.active,
  .aui-sidenav ul li a.active,
  .aui-sidenav ul li .btn.active {
    background: transparent;
    color: #c10e21;
    border-left: 3px solid #c10e21;
  }

  .aui-custom-switch
    .custom-control-input:checked
    ~ .custom-control-label:before {
    border-color: #0d8200;
  }

  .aui-custom-switch
    .custom-control-input:checked
    ~ .custom-control-label:after {
    background-color: #0d8200;
  }

  .aui-sidenav .aui-sidenav-acc-body {
    transform: translateY(-8px);
  }

  #customizetabletablelist {
    text-align: left !important;
  }
`;
export default AdminWrapper;

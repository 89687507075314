import { diff } from "json-diff";
import getByDot from "lodash/get";

const programAccreditationslist = [
  "accreditations",
  "certificates",
  "classifications",
  "programs",
];

export const jsonDiff = ({
  oldData = {},
  newData = {},
  delta = false,
}: {
  oldData?: any;
  newData?: any;
  delta?: boolean;
}) => {
  if (
    oldData?.filters?.filters?.categories == "successActivities" ||
    oldData?.filters?.filters?.categories == "successActivities"
  ) {
    oldData?.filters?.filters?.subCategories
      ?.split(",")
      ?.forEach((item: any) => {
        if (item === "programAccreditations") {
          programAccreditationslist.forEach((dropdownItem) => {
            if (!oldData?.filters?.filters[`${dropdownItem}`]) {
              oldData = {
                ...oldData,
                filters: {
                  ...oldData?.filters,
                  filters: {
                    ...oldData?.filters?.filters,
                    [dropdownItem]: `All ${
                      dropdownItem.charAt(0).toUpperCase() +
                      dropdownItem.slice(1, dropdownItem.length - 1)
                    } Selected`,
                  },
                },
              };
            }
          });
        } else if (!oldData?.filters?.filters[`${item}`]) {
          oldData = {
            ...oldData,
            filters: {
              ...oldData?.filters,
              filters: {
                ...oldData?.filters?.filters,
                [item]: `All ${
                  item.charAt(0).toUpperCase() + item.slice(1, item.length - 1)
                } Selected`,
              },
            },
          };
        }
      });

    newData?.filters?.filters?.subCategories
      ?.split(",")
      ?.forEach((item: any) => {
        if (item === "programAccreditations") {
          programAccreditationslist.forEach((dropdownItem) => {
            if (!newData?.filters?.filters[`${dropdownItem}`]) {
              newData = {
                ...newData,
                filters: {
                  ...newData?.filters,
                  filters: {
                    ...newData?.filters?.filters,
                    [dropdownItem]: `All ${
                      dropdownItem.charAt(0).toUpperCase() +
                      dropdownItem.slice(1, dropdownItem.length - 1)
                    } Selected`,
                  },
                },
              };
            }
          });
        } else if (!newData?.filters?.filters[`${item}`]) {
          newData = {
            ...newData,
            filters: {
              ...newData?.filters,
              filters: {
                ...newData?.filters?.filters,
                [item]: `All ${
                  item.charAt(0).toUpperCase() + item.slice(1, item.length - 1)
                } Selected`,
              },
            },
          };
        }
      });
  } else {
    oldData?.filters?.filters?.categories?.split(",")?.forEach((item: any) => {
      if (item === "programAccreditations") {
        programAccreditationslist.forEach((dropdownItem) => {
          if (!oldData?.filters?.filters[`${dropdownItem}`]) {
            oldData = {
              ...oldData,
              filters: {
                ...oldData?.filters,
                filters: {
                  ...oldData?.filters?.filters,
                  [dropdownItem]: `All ${
                    dropdownItem.charAt(0).toUpperCase() +
                    dropdownItem.slice(1, dropdownItem.length - 1)
                  } Selected`,
                },
              },
            };
          }
        });
      } else if (!oldData?.filters?.filters[`${item}`]) {
        oldData = {
          ...oldData,
          filters: {
            ...oldData?.filters,
            filters: {
              ...oldData?.filters?.filters,
              [item]: `All ${
                item.charAt(0).toUpperCase() + item.slice(1, item.length - 1)
              } Selected`,
            },
          },
        };
      }
    });

    newData?.filters?.filters?.categories?.split(",")?.forEach((item: any) => {
      if (item === "programAccreditations") {
        programAccreditationslist.forEach((dropdownItem) => {
          if (!newData?.filters?.filters[`${dropdownItem}`]) {
            newData = {
              ...newData,
              filters: {
                ...newData?.filters,
                filters: {
                  ...newData?.filters?.filters,
                  [dropdownItem]: `All ${
                    dropdownItem.charAt(0).toUpperCase() +
                    dropdownItem.slice(1, dropdownItem.length - 1)
                  } Selected`,
                },
              },
            };
          }
        });
      } else if (!newData?.filters?.filters[`${item}`]) {
        newData = {
          ...newData,
          filters: {
            ...newData?.filters,
            filters: {
              ...newData?.filters?.filters,
              [item]: `All ${
                item.charAt(0).toUpperCase() + item.slice(1, item.length - 1)
              } Selected`,
            },
          },
        };
      }
    });
  }

  const diffObj = diff(oldData || {}, newData || {}, {
    full: !delta,
  });

  return diffObj;
};

export const getByDottedDiff = (diffData: any, path: string) => {
  const pathParts = path.split(".");

  let __old: any;
  let __new: any;

  for (
    let idx = 0, partialPath = pathParts[idx];
    idx < pathParts.length && __old === undefined && __new === undefined;
    idx += 1
  ) {
    const currPartialPath = pathParts[idx + 1];

    const laterPaths = pathParts.slice(idx + 1);

    const normalPath = [partialPath, ...laterPaths].join(".");
    let foundValue = getByDot(diffData, normalPath);
    if (foundValue !== undefined) {
      __old = foundValue?.__old;
      __new = foundValue?.__new;
    }

    const addedPath = [`${partialPath}__added`, ...laterPaths].join(".");
    foundValue = getByDot(diffData, addedPath);
    if (foundValue !== undefined) {
      __new = foundValue?.__new ?? foundValue;
    }

    const deletedPath = [`${partialPath}__deleted`, ...laterPaths].join(".");
    foundValue = getByDot(diffData, deletedPath);
    if (foundValue !== undefined) {
      __old = foundValue?.__old ?? foundValue;
    }

    partialPath = [partialPath, currPartialPath].join(".");
  }

  return {
    __old,
    __new,
  };
};

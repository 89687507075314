import Home from "pages/Home/Home";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import Dashboard from "pages/Dashboard/dashboard";
import { Redirect, Switch, useLocation } from "react-router-dom";
import PageNotFound from "pages/PageNotFound/index";
import Route from "./Route";
import * as constants from "../common/constants";
import Landing from "pages/SSOLogin/Landing";
import InvalidUser from "pages/InvalidUser";
import { Permission, useRBACContext } from "@impelsysinc/react-rbac";

// import ErrorList from "pages/ErrorList/LearnerActivityList";

import { useTranslation } from "react-i18next";
import ErrorBoundary from "../common/ErrorBoundary";
import ScrollToTop from "common/ScrollToTop";
import { CONSTANTS } from "../common/constants";
import PreviewReport from "pages/CustomReportScheduler/PreviewReport";
import { clearCustomReportState } from "pages/Report/CustomReport/CustomReportSlice";
import { useDispatch } from "react-redux";
import { excludedCustomRoutes } from "common/constants/customReports";
import ClearCustomReportState from "pages/CustomReportScheduler/ClearCustomReportState";
import EditCustomReportScheduler from "pages/CustomReportScheduler/EditCustomReportScheduler/EditCustomReportScheduler";
import useFeatures from "common/utils/useFeatures";
import ActivityReport from "pages/Report/CustomReport/ActivityReport";
import { CustomReportContextProvider } from "pages/Report/CustomReport/CustomReportContext/CustomReportContext";
import AdminCustomReport from "pages/Report/CustomReport/AdminCustomReport";

/* Lazy loading components */

//Vendor
const VendorPage = React.lazy(() => import("pages/Vendors/VendorList"));
const AddVendor = React.lazy(() => import("pages/AddVendor/AddVendor"));
const EditVendor = React.lazy(() => import("pages/EditVendor/EditVendor"));
const ViewVendor = React.lazy(() => import("pages/ViewVendor/ViewVendor"));

//Accreditations
const Accreditation = React.lazy(
  () => import("pages/Accreditations/AccreditationList")
);
const AddAccreditation = React.lazy(
  () => import("pages/AddAccreditation/AddAccreditation")
);
const EditAccreditation = React.lazy(
  () => import("pages/EditAccreditation/EditAccreditation")
);
const ViewAccreditation = React.lazy(
  () => import("pages/ViewAccreditation/ViewAccreditation")
);

//Certifications
const Certification = React.lazy(
  () => import("pages/Certifications/CertificationList")
);
const AddCertificate = React.lazy(
  () => import("pages/AddCertificate/AddCertificate")
);
const EditCertificate = React.lazy(
  () => import("pages/EditCertification/EditCertification")
);
const ViewCertificate = React.lazy(
  () => import("pages/ViewCertificate/ViewCertificate")
);

//Programs
const Programs = React.lazy(() => import("pages/Programs/ProgramList"));
const AddProgram = React.lazy(() => import("pages/AddProgram/AddProgram"));
const EditProgram = React.lazy(() => import("pages/EditProgram/EditProgram"));
const ViewProgram = React.lazy(() => import("pages/ViewProgram/ViewProgram"));

//Classifications
const ClassficationManagement = React.lazy(
  () => import("pages/Classifications/ClassificationList")
);
const AddClassfication = React.lazy(
  () => import("pages/AddClassification/AddClassification")
);
const EditClassification = React.lazy(
  () => import("pages/EditClassification/EditClassification")
);
const ViewClassification = React.lazy(
  () => import("pages/ViewClassification/ViewClassification")
);

//User Management
const User = React.lazy(() => import("pages/Users/UserList"));
const AddUser = React.lazy(() => import("pages/AddUser/AddUser"));
const EditUser = React.lazy(() => import("pages/EditUser/EditUser"));
const ViewUser = React.lazy(() => import("pages/ViewUser/ViewUser"));

//Audit Log
const AuditLog = React.lazy(() => import("pages/AuditLog/AuditLogs"));
const BatchLog = React.lazy(() => import("pages/BatchLog/BatchLogs"));
const ActivityLogs = React.lazy(() => import("pages/ActivityLog/ActivityLogs"));
const ReportLog = React.lazy(() => import("pages/ReportLog/ReportLogs"));
const CustomReportLog = React.lazy(
  () => import("pages/CustomReportLog/CustomReportLogs")
);
const VendorLog = React.lazy(() => import("pages/VendorLog/VendorLogs"));
const ViewActivityLog = React.lazy(
  () => import("pages/ViewActivityLog/ViewActivityLog")
);

//Learner
const ViewLearnerDetails = React.lazy(
  () => import("pages/ViewLearner/ViewLearner")
);
const Learner = React.lazy(() => import("pages/Learners/LearnerList"));
const AddLearner = React.lazy(() => import("pages/AddLearner/AddLearner"));
const EditLearner = React.lazy(() => import("pages/EditLearner/EditLearner"));
const ViewEditActivity = React.lazy(
  () => import("pages/ViewEditActivity/ViewEditActivity")
);

//ActivityList
const ActivityList = React.lazy(() => import("pages/ActivityList/TableView"));

//Error List
const ActivityErrorList = React.lazy(() => import("pages/ErrorList/ErrorList"));

//Report
const Report = React.lazy(() => import("pages/Report/PredefinedReportFilter"));

//Custom-Report: Select fields
//const SelectFields = React.lazy(() => import("pages/Report/CustomReport/SelectFields/SelectFields"));

//Report
const ReportSchedules = React.lazy(
  () => import("pages/ReportScheduler/SchedulerList")
);
const AddReportScheduler = React.lazy(
  () => import("pages/AddReportScheduler/AddReportScheduler")
);
const EditReportScheduler = React.lazy(
  () => import("pages/EditReportScheduler/EditReportScheduler")
);
const ViewScheduler = React.lazy(
  () => import("pages/ViewScheduler/ViewScheduler")
);

//Custom Report
const CustomReports = React.lazy(
  () => import("pages/Report/CustomReport/CustomReports")
);

// Custom Report Scheduler
const AddCustomReportScheduler = React.lazy(
  () =>
    import(
      "pages/CustomReportScheduler/AddCustomReportScheduler/AddCustomReportScheduler"
    )
);
const ViewCustomScheduler = React.lazy(
  () => import("pages/ViewScheduler/ViewCustomScheduler")
);

//ArchiveActivityList
const ArchiveActivityList = React.lazy(
  () => import("pages/ArchiveActivityList/TableView")
);
const ArchiveErrorActivityList = React.lazy(
  () => import("pages/ArchiveErrorActivityList/ArchiveErrorActivityList")
);

// Archive Learner List
const ArchiveLearnerList = React.lazy(
  () => import("pages/ArchiveLearnerList/ArchiveLearnerList")
);

const SelectFields = React.lazy(
  () => import("pages/Report/CustomReport/SelectFields/SelectFields")
);

const ViewSavedCustomReport = React.lazy(
  () => import("pages/Report/CustomReport/ViewSavedCustomReport")
);

export type RedirectWithPermissionProps = {
  children: ReactNode;
} & Permission;

export const RedirectWithPermission: FunctionComponent<
  RedirectWithPermissionProps
> = ({ children, ...permissions }) => {
  const { canAccess, permissions: existingPerms } = useRBACContext();
  if (!existingPerms) {
    return <Redirect to="/" />;
  }
  if (!canAccess(permissions)) {
    return <PageNotFound />;
  }
  return <>{children}</>;
};

export default function Routes() {
  const { t } = useTranslation();
  const features = useFeatures();

  return (
    <>
      <ScrollToTop />
      <ClearCustomReportState />
      <Switch>
        <Route
          path="/landing"
          exact
          title={"Landing Page"}
          component={Landing as any}
        />
        <Route
          path="/invalidUser"
          exact
          title={"Invalid User Page"}
          component={InvalidUser as any}
        />
        <Route
          path={constants.Routes.ACTIVITY_VIEW_EDIT_PAGE}
          exact
          title={t<string>("viewEditActivity")}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="successActivities">
                <ViewEditActivity {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path={constants.Routes.ACTIVITY_ERROR_VIEW_EDIT_PAGE}
          exact
          title={t<string>("viewEditActivity")}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="errorActivities">
                <ViewEditActivity {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/accreditations"
          exact
          title={"Accreditation"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="accreditations"
              >
                <Accreditation {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/accreditations/add"
          exact
          title={"Add Accreditation"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="accreditations">
                <AddAccreditation {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/accreditations/edit/:id"
          exact
          title={"Edit Accreditation"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="accreditations">
                <EditAccreditation {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/accreditations/:id"
          exact
          title={"View Accreditation"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="accreditations">
                <ViewAccreditation {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path={constants.Routes.VENDOR_LIST_PAGE}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="vendors">
                <VendorPage {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          exact
          // hideHeader
          adminRoute
        />
        <Route
          path={constants.Routes.VENDOR_ADD_PAGE}
          exact
          title={"Add Vendor"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="vendors">
                <AddVendor {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path={constants.Routes.VENDOR_EDIT_PAGE}
          exact
          title={"Edit Vendor"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="vendors">
                <EditVendor {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path={constants.Routes.VENDOR_VIEW_PAGE}
          exact
          title={"View Vendor"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="vendors">
                <ViewVendor {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/certificates"
          exact
          title={"Certificates"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="certificates">
                <Certification {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/certificates/add"
          exact
          title={"Add Certificate"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="certificates">
                <AddCertificate {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/certificates/edit/:id"
          exact
          title={"Edit Certificate"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="certificates">
                <EditCertificate {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/certificates/:id"
          exact
          title={"View Certificate"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="certificates">
                <ViewCertificate {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/classifications"
          exact
          title={"Classification"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="classifications"
              >
                <ClassficationManagement {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/classifications/add"
          exact
          title={"Add Classification"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="classifications">
                <AddClassfication {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/classifications/edit/:id"
          exact
          title={"Edit Classification"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="update"
                resource="classifications"
              >
                <EditClassification {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/classifications/:id"
          exact
          title={"View Classification"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="classifications">
                <ViewClassification {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/programs/add"
          exact
          title={"Add Programs"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="programs">
                <AddProgram {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/programs/:id"
          exact
          title={"View Program"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="programs">
                <ViewProgram {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/programs"
          exact
          title={"Programs"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="programs">
                <Programs {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/programs/edit/:id"
          exact
          title={"Edit Program"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="programs">
                <EditProgram {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/dashboard"
          exact
          title={"Dashboard"}
          component={Dashboard}
          adminRoute
        />
        <Route
          path="/learners"
          exact
          title={"Learner Details"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="learnerDetails"
              >
                <Learner {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/learners/add"
          exact
          title={"Add Learner"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="learnerDetails">
                <AddLearner {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/learners/edit/:id"
          exact
          title={"Edit Learner"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="learnerDetails">
                <EditLearner {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        {/*  Learner */}
        <Route
          path="/learners/:id"
          exact
          title={"Learner Details"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="learnerDetails">
                <ViewLearnerDetails {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/activity-errors"
          exact
          title={t<string>("errorList")}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="errorActivities"
              >
                <ActivityErrorList {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/users"
          exact
          title={"User"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="userManagement"
              >
                <User {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/users/add"
          exact
          title={"Add User"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="userManagement">
                <AddUser {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/users/edit/:id"
          exact
          title={"Edit User"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="userManagement">
                <EditUser {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/users/:id"
          exact
          title={"View User"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="userManagement">
                <ViewUser {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.AUDIT_LOG_PAGE}
          exact
          title={"AuditLog"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="auditLogs">
                <AuditLog {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/phdLogs"
          exact
          title={"phdLogs"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="phdLogs">
                <BatchLog {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        {features?.reports ? (
          <Route
            path={constants.Routes.REPORT_LOG_PAGE}
            exact
            title={"ReportLog"}
            component={(props) => (
              <ErrorBoundary>
                {/* TODO: Add Permission for Report log and uncomment */}
                {/* <RedirectWithPermission action="get.all" resource="reportLogs"> */}
                <ReportLog {...props} />
                {/* </RedirectWithPermission> */}
              </ErrorBoundary>
            )}
            adminRoute
          />
        ) : null}
        {features?.customReport ? (
          <Route
            path={constants.Routes.CUSTOM_REPORT_LOG_PAGE}
            exact
            title={"customReportLog"}
            component={(props) => (
              <ErrorBoundary>
                <RedirectWithPermission action="get.all" resource="reportLogs">
                  <CustomReportLog {...props} />
                </RedirectWithPermission>
              </ErrorBoundary>
            )}
            adminRoute
          />
        ) : null}
        <Route
          path={constants.Routes.VENDOR_ACTIVITY_LOG_PAGE}
          exact
          title={"VendorActivityLog"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="vendorActivityLogs"
              >
                <VendorLog {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/activities"
          exact
          title={"List Activity"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="successActivities"
              >
                <ActivityList {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.ACTIVITY_LOG_LIST_PAGE}
          exact
          title={"ActivityLogs"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="activityLogs">
                <ActivityLogs {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        {/* <Route
          path={constants.Routes.ACTIVITY_LOG_VIEW_PAGE}
          exact
          title={"View Activity Detail"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="activityLogs">
                <ViewActivityLog {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        /> */}

        {features?.reports ? (
          <Route
            path="/reports"
            exact
            title={"Reports"}
            component={(props) => (
              <ErrorBoundary>
                <RedirectWithPermission action="get.all" resource="reports">
                  <Report {...props} />
                </RedirectWithPermission>
              </ErrorBoundary>
            )}
            adminRoute
          />
        ) : null}

        {features?.reports ? (
          <Route
            path="/schedulers"
            exact
            title={"SchedulerList"}
            component={(props) => (
              <ErrorBoundary>
                <RedirectWithPermission
                  action="get.all"
                  resource="schedulerList"
                >
                  <ReportSchedules {...props} />
                </RedirectWithPermission>
              </ErrorBoundary>
            )}
            adminRoute
          />
        ) : null}
        <Route
          path="/schedulers/add"
          exact
          title={"Add Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="schedulerList">
                <AddReportScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/schedulers/edit/:id"
          exact
          title={"Edit Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="update" resource="schedulerList">
                <EditReportScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/schedulers/:id"
          exact
          title={"View Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="schedulerList">
                <ViewScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/archived-activities"
          exact
          title={"List Archive Activity"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="archivedActivities"
              >
                <ArchiveActivityList {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/archived-activity-errors"
          exact
          title={"List Archive Activity Errors"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="archivedErrorActivities"
              >
                <ArchiveErrorActivityList {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.ARCHIVE_LEARNER_LIST_PAGE}
          exact
          title="List Archived Learners"
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission
                action="get.all"
                resource="archivedLearnerDetails"
              >
                <ArchiveLearnerList {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.CUSTOM_REPORTS_SELECT_FIELDS}
          exact
          title="Custom Reports - Select Fields"
          component={(props: any) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="reports">
                <SelectFields {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />
        <Route
          path="/custom-reports/:id"
          exact
          title="Custom Reports - Filter"
          component={(props: any) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="reports">
                <ViewSavedCustomReport />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.ADMIN_CUSTOM_REPORT}
          exact
          title="Custom Reports Activity"
          component={(props: any) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="reports">
                <CustomReportContextProvider>
                  <AdminCustomReport />
                </CustomReportContextProvider>
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.ACTIVITY_CUSTOM_REPORT}
          exact
          title="Custom Reports Activity"
          component={(props: any) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get.all" resource="reports">
                <CustomReportContextProvider>
                  <ActivityReport />
                </CustomReportContextProvider>
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.CUSTOM_REPORT_ADD_SCHEDULER}
          exact
          title={"Add Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="schedulerList">
                <AddCustomReportScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.CUSTOM_REPORT_PREVIEW}
          exact
          title={"Report Preview"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="schedulerList">
                <PreviewReport {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path="/schedulers/custom/:id"
          exact
          title={"View Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="get" resource="schedulerList">
                <ViewCustomScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route
          path={constants.Routes.CUSTOM_REPORT_EDIT_SCHEDULER}
          exact
          title={"Edit Scheduler"}
          component={(props) => (
            <ErrorBoundary>
              <RedirectWithPermission action="post" resource="schedulerList">
                <EditCustomReportScheduler {...props} />
              </RedirectWithPermission>
            </ErrorBoundary>
          )}
          adminRoute
        />

        <Route path="/" exact component={Home} hideHeader />

        <Route component={PageNotFound} adminRoute />
      </Switch>
    </>
  );
}

import React, { FunctionComponent, ReactNode, useContext } from "react";
import classNames from "classnames";
import { mapToCssModules } from "./utils";
import { AccordionContext } from "./AccordionContext";
import { CSSModule, InnerRef, TagPropType } from "./types";

export interface AccordionHeaderPropTypes {
  children?: ReactNode;
  /** Add custom class */
  className?: string;
  /** Change existing base class name with a new class name */
  cssModule?: CSSModule;
  innerRef?: InnerRef;
  /** Set a custom element for this component */
  tag?: TagPropType;
  /** Unique key used to control item's collapse/expand */
  targetId: string;

  testId?: string;

  [key: string]: any;
}

export const AccordionHeader: FunctionComponent<AccordionHeaderPropTypes> = (
  props
) => {
  const {
    className,
    cssModule,
    tag: Tag = "button",
    innerRef,
    children,
    targetId,
    testId = props['data-testid'] || props.id,
    ...attributes
  } = props;
  const { open, toggle } = useContext(AccordionContext);

  const classes = mapToCssModules(
    classNames(className, "accordion-header"),
    cssModule
  );

  const buttonClasses = mapToCssModules(
    classNames("accordion-button", {
      collapsed: !(Array.isArray(open)
        ? open.includes(targetId)
        : open === targetId),
    }),
    cssModule
  );

  return (
    <>
      {Tag == "button" ? (
        <button
          {...attributes}
          className={classNames(classes, buttonClasses)}
          data-testid={testId}
          onClick={() => {
            props.onClick?.();
            toggle?.(targetId);
          }}
        >
          {children}
        </button>
      ) : (
        <div
          {...attributes}
          className={classNames(classes, buttonClasses)}
          data-testid={testId}
          onClick={() => {
            props.onClick?.();
            toggle?.(targetId);
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

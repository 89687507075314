/** @format */

import { MismatchErrorTags, RejectedErrorTags } from "common/constants";
import _ from "lodash";

export function getErrorTypeName(errorTypesArr: any, parentType: any) {
  let errorTags: any = parentType === "Rejected" ? RejectedErrorTags : MismatchErrorTags;
  const filteredObjects = errorTags?.filter((obj: any) => errorTypesArr?.includes(obj.code));
  const names = filteredObjects?.map((obj: any) => obj.name);

  return names;
}

const findLabel = (field: any, fieldObj: any, parentLabel = ""): [string, string] => {
  let code = "";

  if (!field || !fieldObj) {
    return ["", ""];
  }
  if (typeof fieldObj === "object") {
    for (let childObj of fieldObj) {
      if (childObj?.code === field) {
        code = childObj.label;
        return [code, parentLabel];
      } else if (childObj.children && childObj.children.length > 0) {
        const result = findLabel(field, childObj.children, childObj.code);

        if (result[0]) {
          return result;
        }
      }
    }
  } else {
    return ["", ""];
  }
  return [code, parentLabel];
};

export function transformFields(
  code: any,
  fields: any,
  customReportFields: any,
  transformObj1: any
) {
  switch (code) {
    case "successActivities": {
      transformObj1.successActivities = {};
      let fieldObj = _.find(customReportFields, { code: code });
      if (Array.isArray(fields)) {
        fields?.map((field: any, key: number) => {
          let [label, parentLabel] = findLabel(field, fieldObj?.children, code);

          if (key == 0) {
            transformObj1.successActivities[parentLabel] = "";
          }
          transformObj1.successActivities[parentLabel] = transformObj1.successActivities[
            parentLabel
          ]
            ? transformObj1.successActivities[parentLabel] + "," + label
            : label;
        });
      }
      break;
    }
    case "errorActivities": {
      transformObj1.errorActivities = {};
      let fieldObj = _.find(customReportFields, { code: code });
      if (Array.isArray(fields)) {
        fields?.map((field: any, key: number) => {
          let [label, parentLabel] = findLabel(field, fieldObj?.children, code);

          if (key == 0) {
            transformObj1.errorActivities[parentLabel] = "";
          }
          transformObj1.errorActivities[parentLabel] = transformObj1.errorActivities[parentLabel]
            ? transformObj1.errorActivities[parentLabel] + "," + label
            : label;
        });
      }
      break;
    }
    case "accreditations":
    case "certificates":
    case "classifications":
    case "learnerDetails":
    case "programs":
    case "vendors": {
      let fieldObj = _.find(customReportFields, { code: code });
      if (Array.isArray(fields)) {
        fields.map((field: any, key: number) => {
          let [label, parentLabel] = findLabel(field, fieldObj?.children, code);
          if (key == 0) {
            transformObj1[parentLabel] = "";
          }
          transformObj1[parentLabel] = transformObj1[parentLabel]
            ? transformObj1[parentLabel] + "," + label
            : label;
        });
      }
      break;
    }
    case "programAccreditations":
      let fieldObj = _.find(customReportFields, { code: "accreditationsUnderPrograms" });
      if (Array.isArray(fields)) {
        fields?.map((field: any, key: number) => {
          let [label, parentLabel] = findLabel(field, fieldObj?.children, code);

          if (key == 0) {
            transformObj1[parentLabel] = "";
          }

          transformObj1[parentLabel] = transformObj1[parentLabel]
            ? transformObj1[parentLabel] + "," + label
            : label;
        });
      }
      break;
  }
}

export const mapCustomReportFields = (
  record: any = {},
  customReportColumns: any,
  component: string
) => {
  let currentCustomField: any;
  let previousCustomField: any = {};
  if (component == "MANUAL") {
    currentCustomField = record?.currentData?.filters?.customField;
    previousCustomField = record?.previousData?.filters?.customField;
  } else {
    currentCustomField = record?.currentData?.reportDetails?.customField;
    previousCustomField = record?.previousData?.reportDetails?.customField;
  }

  if (!currentCustomField && !previousCustomField) {
    return record;
  }

  if (currentCustomField) {
    Object.keys(currentCustomField).forEach((code: any) => {
      if (currentCustomField.hasOwnProperty(code)) {
        const fields = currentCustomField[code];
        if (Array.isArray(fields) && fields?.length > 0) {
          transformFields(code, fields, customReportColumns, currentCustomField);
        } else {
          if (code == "successActivities" || code == "errorActivities") {
            currentCustomField[code] = {};
          } else {
            currentCustomField[code] = "";
          }
        }
      }
    });
  }

  if (previousCustomField) {
    Object.keys(previousCustomField).forEach((code: any) => {
      if (previousCustomField.hasOwnProperty(code)) {
        const fields = previousCustomField[code];

        if (fields?.length > 0) {
          transformFields(code, fields, customReportColumns, previousCustomField);
        } else {
          if (code == "successActivities" || code == "errorActivities") {
            previousCustomField[code] = {};
          } else {
            previousCustomField[code] = "";
          }
        }
      }
    });
  } else {
    if (currentCustomField) {
      previousCustomField = JSON.parse(JSON.stringify(currentCustomField));

      // Set all values in the copied object to an empty string
      const setEmptyString = (obj: any) => {
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            setEmptyString(obj[key]);
          } else {
            obj[key] = "";
          }
        }
      };
      setEmptyString(previousCustomField);
    }
  }

  // Update errorActivities in currentCustomField with missing keys from previousCustomField
  for (const key in previousCustomField?.errorActivities) {
    if(currentCustomField?.errorActivities){
      if (!(key in currentCustomField?.errorActivities)) {
        currentCustomField.errorActivities[key] = "";
      }
    }
  }

  // Update successActivities in currentCustomField with missing keys from previousCustomField
  for (const key in previousCustomField?.successActivities) {
    if(currentCustomField?.successActivities){
      if (!currentCustomField?.successActivities?.hasOwnProperty(key)) {
        currentCustomField.successActivities[key] = "";
      }
    }
  }

  // Update errorActivities in previousCustomField with missing keys from currentCustomField
  for (const key in currentCustomField?.errorActivities) {
    if(previousCustomField?.errorActivities){
      if (!(key in previousCustomField?.errorActivities)) {
        previousCustomField.errorActivities[key] = "";
      }
    }
  }

  // Update successActivities in previousCustomField with missing keys from currentCustomField
  for (const key in currentCustomField?.successActivities) {
    if(previousCustomField?.successActivities){
      if (!(key in previousCustomField?.successActivities)) {
        previousCustomField.successActivities[key] = "";
      }
    }
  }

  if (component == "SCHEDULER") {
    //For new schedulers, previousData can be undefined
    if (!record.previousData) {
      record.previousData = {};
      if (!record.previousData.reportDetails) {
        record.previousData.reportDetails = {};
      }
      record.previousData.reportDetails.customField = previousCustomField;
    }
  }

  return record;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ManagementState {
  pageNumber: number;
  search: string;
  startDate: string;
  endDate:string,
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ManagementState = {
  pageNumber: 1,
  search: "",
  startDate: "",
  endDate: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const managementSlice = createSlice({
  name: "vendorLog",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchVendorLog: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;

      state.search = action.payload;
    },
    searchDate: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setPageSize: (state, action: PayloadAction<ManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearVendorLogState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = managementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchVendorLog,
  searchDate,
  setPageSize,
  clearVendorLogState,
} = actions;
export default reducer;

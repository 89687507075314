import React, { FunctionComponent } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TableColumnsInterface } from "./index";
import ModalPopupComp from "../../components/ModalPopup/index";
import { CONSTANTS } from "common/constants";
import AuiIcon from "components/IconComponent/AuiIcon";

export type ActionPredicate<Content = any> = (content: Content) => boolean;

export interface Actions<Content = any> {
  add?: boolean;
  edit?: boolean;
  view?: boolean;
  delete?: boolean;
  restore?: boolean;
  download?: boolean | ActionPredicate<Content>;
}

export type ActionFn = (id: number) => any;

export interface ActionProps<Content = any> {
  id?: string;
  actions?: Actions<Content>;
  content: Content;
  iconTextOnly?: boolean;
  editContent?: ActionFn;
  viewContent?: ActionFn;
  downloadContent?: ActionFn;
  index: number;
  isVisibleDelBtn?: boolean;
  onStatusToggle: (event: any, index: number, name: string) => any;
  columns: TableColumnsInterface[];
  deleteRecords?: Function;
  MsgBody?: Function;
  cme_pageID?: string;
  isToggleAllowed?: boolean;
  customClass?: string;
}

export const Action: FunctionComponent<ActionProps> = (props) => {
  const { t } = useTranslation();

  const {
    actions,
    id,
    content,
    iconTextOnly,
    editContent,
    onStatusToggle,
    downloadContent,
    columns,
    viewContent,
    index,
    isVisibleDelBtn = false,
    deleteRecords,
    MsgBody = () => {
      return null;
    },
    cme_pageID,
    isToggleAllowed,
    customClass = "aui-icon-editsquare text-primary",
  } = props;

  const UID =
    id ??
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "");

  const isDownload =
    typeof actions?.download === "function"
      ? actions?.download(content)
      : actions?.download;

  return actions?.add || actions?.edit || actions?.view || isDownload ? (
    <td data-title="Action" className="action_column">
      <div className="aui-td">
        <ul className="cpaction-icons pr-3 pl-0 mb-0 d-flex justify-content-start">
          {/* Download Icon */}
          {isDownload ? (
            <li
              className={classNames("position-relative", {
                "text-only-icon-item": iconTextOnly,
              })}
            >
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={t<string>("download")}
              >
                <a
                  href="#"
                  data-testid="download"
                  role="button"
                  aria-label={t<string>("downloadPage")}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadContent?.(
                      typeof cme_pageID !== "undefined" &&
                        (cme_pageID === CONSTANTS.REPORTLOG_PAGEID ||
                          cme_pageID === CONSTANTS.CUSTOMREPORTLOG_PAGEID)
                        ? content.targetId
                        : content.id
                    );
                  }}
                >
                  {iconTextOnly ? (
                    t<string>("download")
                  ) : (
                    <AuiIcon
                      elementObject={{
                        svgclass: "aui-icon-download text-primary",
                        pathclass: "aui-path-download",
                        icon: "download",
                        svgwidth: "20",
                        svgheight: "20",
                      }}
                    ></AuiIcon>
                  )}
                </a>
              </aui-tooltip>
            </li>
          ) : null}

          {/* View Icon */}
          {actions?.view && (
            <li
              className={classNames("position-relative", {
                "text-only-icon-item": iconTextOnly,
              })}
            >
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={t<string>("view")}
              >
                <a
                  aria-label={t<string>("viewPage")}
                  role="button"
                  onClick={() => {
                    viewContent?.(content.id);
                  }}
                >
                  {iconTextOnly ? (
                    t<string>("view")
                  ) : (
                    <AuiIcon
                      elementObject={{
                        svgclass: "aui-icon-view text-primary",
                        pathclass: "aui-path-view",
                        icon: "view",
                        svgwidth: "20",
                        svgheight: "20",
                      }}
                    ></AuiIcon>
                  )}
                </a>
              </aui-tooltip>
            </li>
          )}

          {/* Edit Icon */}
          {actions?.edit && (
            <li className="position-relative">
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={t<string>("edit/Modify")}
              >
                <a
                  aria-label={t<string>("editOrModifyPage")}
                  role="button"
                  onClick={() => {
                    editContent?.(content.id);
                  }}
                >
                  <AuiIcon
                    elementObject={{
                      svgclass: customClass,
                      pathclass: "aui-path-editsquare",
                      icon: "editsquare",
                      svgwidth: "20",
                      svgheight: "20",
                    }}
                  ></AuiIcon>
                </a>
              </aui-tooltip>
            </li>
          )}

          {/* Toggle Icon */}
          {actions?.edit && (
            <li className="position-relative">
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={
                  content.isActive ? t<string>("active") : t<string>("inactive")
                }
              >
                <div className="custom-control custom-switch aui-custom-switch">
                  <input
                    type="checkbox"
                    disabled={isToggleAllowed ? !actions?.edit : true}
                    onChange={(event) => {
                      onStatusToggle(
                        event,
                        index,
                        content["name"] || content["title"]
                          ? content["name"] || content["title"]
                          : content[columns[0].propertyName]
                      );
                    }}
                    className="custom-control-input"
                    role="button"
                    aria-label={
                      content.isActive
                        ? t<string>("deactivate")
                        : t<string>("activate")
                    }
                    checked={content.isActive}
                    id={content.id}
                    data-testid={content.id}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={content.id}
                  />
                </div>
              </aui-tooltip>
            </li>
          )}

          {/* Delete Icon */}
          {actions?.delete && (
            <li
              className={isVisibleDelBtn ? "position-relative del" : "d-none"}
              id={"del_" + UID}
            >
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={t<string>("delete")}
              >
                <a
                  href="#"
                  aria-label={t<string>("delete")}
                  role="button"
                  data-target={`#del_modal_${UID}`}
                  data-backdrop="static"
                  data-keyboard="false"
                  data-toggle="modal"
                >
                  <AuiIcon
                    elementObject={{
                      svgclass: "aui-icon-trash text-primary",
                      pathclass: "aui-path-trash",
                      icon: "trash",
                      svgwidth: "20",
                      svgheight: "20",
                    }}
                  ></AuiIcon>
                </a>
              </aui-tooltip>
            </li>
          )}

          {/* Restore Icon */}
          {actions?.restore && (
            <li
              className={isVisibleDelBtn ? "position-relative del" : "d-none"}
              id={"restore_" + index}
            >
              <aui-tooltip
                position="top"
                tooltipid="top"
                arrow={false}
                content={t<string>("restore")}
              >
                <a
                  href="#"
                  aria-label={t<string>("restore")}
                  role="button"
                  data-toggle={"modal"}
                  data-target={`#del_modal_${UID}`}
                  className="restore_icon"
                >
                  <img src="../images/restore.svg" alt={t<string>("restore")} />
                </a>
              </aui-tooltip>
            </li>
          )}
        </ul>
        {actions?.delete && (
          <ModalPopupComp
            childrenBody={<MsgBody />}
            displayButtons={true}
            id={`del_modal_${UID}`}
            popupDiscardFunc={(e: React.MouseEvent) => {
              if (deleteRecords) {
                deleteRecords(e, [content?.id]);
              }
            }}
          />
        )}
      </div>
    </td>
  ) : (
    <td data-title="Action" className="action_column">
      <div className="aui-td no-data-display-class">N/A</div>
    </td>
  );
};

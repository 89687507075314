import * as constants from "common/constants"

export const breadcrumbRoutes = {
  viewVendor: [
    { title: "Vendors", url: "/vendors" },
    { title: "View Vendor", url: "" },
  ],
  addVendor: [
    { title: "Vendors", url: "/vendors" },
    { title: "Add Vendor", url: "" },
  ],
  editVendor: [
    { title: "Vendors", url: "/vendors" },
    { title: "Edit Vendor", url: "" },
  ],
  viewAccreditation: [
    { title: "Accreditations", url: "/accreditations" },
    { title: "View Accreditation", url: "" },
  ],
  addAccreditation: [
    { title: "Accreditations", url: "/accreditations" },
    { title: "Add Accreditation", url: "" },
  ],
  editAccreditation: [
    { title: "Accreditations", url: "/accreditations" },
    { title: "Edit Accreditation", url: "" },
  ],
  viewCertificate: [
    { title: "Certificates", url: "/certificates" },
    { title: "View Certificates", url: "" },
  ],
  addCertificate: [
    { title: "Certificates", url: "/certificates" },
    { title: "Add Certificates", url: "" },
  ],
  editCertificate: [
    { title: "Certificates", url: "/certificates" },
    { title: "Edit Certificates", url: "" },
  ],
  viewClassification: [
    { title: "Classifications", url: "/classifications" },
    { title: "View Classification", url: "" },
  ],
  addClassification: [
    { title: "Classifications", url: "/classifications" },
    { title: "Add Classification", url: "" },
  ],
  editClassification: [
    { title: "Classifications", url: "/classifications" },
    { title: "Edit Classification", url: "" },
  ],
  editProgram: [
    { title: "Programs", url: "/programs" },
    { title: "Edit Program", url: "" },
  ],
  viewProgram: [
    { title: "Programs", url: "/programs" },
    { title: "View Program", url: "" },
  ],
  addProgram: [
    { title: "Programs", url: "/programs" },
    { title: "Add Program", url: "" },
  ],
  editUser: [
    { title: "User Management", url: "/users" },
    { title: "Edit User", url: "" },
  ],
  viewUser: [
    { title: "User Management", url: "/users" },
    { title: "View User", url: "" },
  ],
  addUser: [
    { title: "User Management", url: "/users" },
    { title: "Add User", url: "" },
  ],
  addLearner: [
    { title: "Learners", url: "/learners" },
    { title: "Add Learner", url: "" },
  ],
  editLearner: [
    { title: "Learners", url: "/learners" },
    { title: "Edit Learner", url: "" },
  ],
  viewLearner: [
    { title: "Learners", url: "/learners" },
    { title: "View Learner", url: "" },
  ],
  viewActivity: [{ title: "View Activity", url: "" }],
  viewActivityLog: [
    { title: "Activity Log", url: constants.Routes.ACTIVITY_LOG_LIST_PAGE },
    { title: "View Activity Log", url: "" },
  ],
  addScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "Add Report Scheduler", url: "" },
  ],
  editScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "Edit Report Scheduler", url: "" },
  ],
  viewScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "View Report Scheduler", url: "" },
  ],
  viewCustomReportScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "View Custom Report Scheduler", url: "" },
  ],
  addCustomScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "Add Custom Report Scheduler", url: "" },
  ],
  editCustomScheduler: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "Edit Custom Report Scheduler", url: "" },
  ],
  reportPreview: [
    { title: "Scheduler List", url: "/schedulers" },
    { title: "Report Preview", url: "" },
  ]
};

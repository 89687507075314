import styled from "styled-components";

export const ViewReportWrapper = styled.div`
  .view-report-container {
    width: 95%;
    padding: 0;
    margin: 1.5rem auto;
  }

  .sub-heads-pr {
    border-bottom: 2px solid #c10e21;
  }

  .btn-div {
    margin-bottom: 1.5rem;
    button {
      width: 141px;
    }
  }

  .mob_btnLayout {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      color: #c10e21;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      min-width: 6vw;

      > img {
        padding-right: 5px;
        margin-top: -1px;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .mob_btnLayout {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      > button {
        width: 95%;
        margin-left: 0px !important;
      }

      div {
        margin-bottom: 6%;
      }
    }
  }

  .aui-responsive-status-table thead th {
    width: 1%;
  }

  h2 {
    font-size: 1.5rem;
  }

  .date-range-error {
    border: 2px solid #e17509 !important;
  }

  .modal-header.downloadwrapper {
    background-color: #fff !important;
    border-bottom-color: #fff;
  }

  .css-1pahdxg-control {
    box-shadow: 0 0 4px 4px #e17509 !important;
    outline: 2px solid #e17509 !important;
    border-color: #e17509 !important;
  }

  .button-div-block {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .add-report-btn,
    .view-report-btn {
      margin-right: 1rem;
    }

    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      .add-report-btn,
      .view-report-btn {
        width: 92%;
        margin-bottom: 10px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }

  .aui-responsive-status-table.noStyle-list {
    margin: 0;
  }
  body {
    overflow-x: hidden;
  }

  .aha-breadcrumb .breadcrumb-item {
    color: #c10e21;
  }
  .aha-breadcrumb .breadcrumb-item:last-child {
    color: #707070;
  }
  .aha-breadcrumb .breadcrumb-item:last-child:after {
    content: " ";
  }

  .cp-edit-close {
    cursor: pointer;
    list-style-type: none;
    right: 0px;
    padding: 0px;
    margin: 0px;
    bottom: 24px;
    li:nth-child(1) {
      margin-right: 0px;
      svg {
        height: 30px;
        width: 30px;
      }
    }
    li {
      cursor: pointer;
      font-size: 26px;
      float: left;
      height: 30px;
      line-height: 30px;
    }
  }
  .spantwo.cp-hidden-close {
    display: none;
    color: #ffffff;
  }
  .cp-viewcontenttwo {
    width: 50%;
  }
  .cp-viewcontenttwo:nth-of-type(even) {
    padding-left: 30px;
  }
  .cp-viewcontenttwo {
    padding-top: 15px;
    margin-bottom: 15px;
    float: left;
    width: 100%;
    .cp-viewcont-ul:last-of-type {
      margin-bottom: 0px;
    }
  }
  .margin-last-space {
    margin-bottom: 20%;
  }
  .toggle-show {
    color: #c10e21;
    float: left;
  }
  .star:after {
    content: "*";
    color: #ff0000;
    margin-left: 0.125rem;
  }
  .show-input {
    height: 31px;
    font-size: 16px;
    width: 252px;
    margin-left: 20px;
  }
  .border-btm {
    border-bottom: 1px solid #e3e3e3;
  }
  .h4-border-btm {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    width: 90%;
    font-size: 16px;
  }
  .cp-viewcont-ul.cp-viewcont-ul2 {
    margin-bottom: 15px;
  }
  .cp-viewcont-ul {
    list-style-type: none;
    font-size: 16px;
    padding: 0px;
    width: 100%;
    margin-bottom: 22px;
    float: left;
    li,
    div {
      float: left;
      span.browselink-cp {
        background: url(../images/url-icon.svg) no-repeat;
        height: 15px;
        width: 15px;
        display: inline-block;
        background-size: 100%;
        right: 0px;
        top: 4px;
      }
    }
    li:nth-child(1),
    div:nth-of-type(1) {
      width: 30%;
      padding-right: 15px;
      @media screen and (max-width: 575px) {
        width: 50%;
      }
    }
    li:nth-child(2),
    div:nth-of-type(2) {
      width: calc(100% - 30%);
      padding-right: 15px;
      word-break: break-word;
      @media screen and (max-width: 575px) {
        width: calc(100% - 50%);
      }
    }
  }
  .address-cls {
    width: 50%;
  }
  .learner-address {
    background: #fcfcfc 0% 0% no-repeat padding-box;
    border: 1px solid #e3e3e3;
    opacity: 1;

    address {
      padding: 22px 35px 19px 16px;
      text-align: left;
      font: normal normal normal 16px/25px Montserrat;
      letter-spacing: 0px;
      color: #222328;
      opacity: 1;

      span {
        display: inline-block;
      }

      .street {
      }

      .postal-code {
        padding-top: 16px;
      }
    }
  }
  @media screen and (max-width: 1199.5px) {
    .cp-viewcont-ul {
      width: 100%;
    }
    .cp-viewcontenttwo {
      width: 100%;
    }
    .cp-viewcont-ul .address-cls {
      width: 80%;
    }
  }
  @media screen and (max-width: 991.5px) {
    .cp-viewcontenttwo,
    .viewcontenttwo:nth-of-type(even) {
      width: 100%;
      padding-left: 0px !important;
    }
  }
  @media screen and (max-width: 767.5px) {
    .spantwo.cp-hidden-close {
      display: block;
    }
  }
  @media screen and (max-width: 575.5px) {
    .spantwo.cp-hidden-close {
      font-size: 18px;
    }
    .toggle-input {
      width: 100% !important;
      padding-right: 0px !important;
      input {
        margin-left: 0px;
        width: 90%;
        margin-top: 10px;
      }
    }
  }

  body {
    overflow-x: hidden;
  }
  .keydown-recordfilter:focus,
  .ul-filter-fixed .form-group:focus,
  label.cp-switch:focus,
  .svg-parent:focus,
  .cp-searchinput:focus,
  .cp-mrgnn2:focus,
  .selected-items:focus,
  .reset-cls-btn:focus,
  ul.ul-filter-fixed li:focus,
  .reset-p:focus {
    outline: 2px solid #f7983a !important;
    -webkit-box-shadow: 0 0 4px 4px #f7983a !important;
    box-shadow: 0 0 4px 4px #f7983a !important;
  }
  .aui-custom-switch
    .custom-control-input:checked
    ~ .custom-control-label:before {
    background: #ffffff;
  }
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: rgba(193, 14, 33, 0.5);
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .custom-switch
    .custom-control-input:disabled:checked
    ~ .custom-control-label::after {
    cursor: not-allowed;
    pointer-events: all !important;
  }
  .aha-heart-logo.aha-contentone {
    background: url(../images/aha-logo.svg) no-repeat;
    background-size: 200px 109px;
    background-position: -10px 0px;
    width: 200px;
    height: 109px;
  }
  .cp-bdr-resp {
    border-bottom: 1px solid #e3e3e3;
  }
  .dropdown-item.dropdown-noclr {
    color: #222328 !important;
  }
  .l-padding-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .cp-spl-lione {
    display: none;
  }
  a.li-active:before {
    visibility: visible !important;
    -webkit-transform: translateY(0px) !important;
    transform: translateY(0px) !important;
    opacity: 1 !important;
  }
  @media only screen and (max-width: 991.5px) {
    .aui-main-header .aui-header-b > * {
      width: 100%;
    }
    .cp-spl-lione {
      display: block;
    }
    .cp-bdr-resp {
      border-bottom-style: none;
    }
    .aui-main-header .dropdown-toggle:after {
      right: 5px;
      top: 45%;
      position: absolute;
    }
  }
  .view-report-wrapper {
    .cp-mrgnn2 {
      input {
        opacity: 0;
        right: 100px;
        position: absolute;
      }
    }
    .selected-items .reset-cls-btn {
      margin: 0px 19px 0px 10px;
    }
    .li-one-ul li {
      margin: 5px 10px 5px 15px !important;
    }
    .table-responsive {
      overflow-x: visible;
    }
    .pagination-block {
      margin-top: 45px;
      margin-bottom: 45px;
    }
    th.code-cls {
      width: 9% !important;
    }
    th.type-cls {
      width: 11% !important;
    }
    th.sequence-cls {
      width: 13% !important;
    }
    th.filesize-cls {
      width: 14% !important;
    }
    th.createdate-cls {
      width: 13% !important;
    }
    th.updatedate-cls {
      width: 13% !important;
    }
    th.lastreview-cls {
      width: 14% !important;
    }
    // th{padding:21px 15px;}
    .unselected-cls {
      background-color: #fcfcfc !important;
    }
    th.action-cls {
      width: 140px !important;
    }
    .visible_cls {
      display: block !important;
    }
    .visible_cls {
      display: block !important;
      z-index: 3 !important;
      width: max-content !important;
      text-align: center !important;
    }
    .visible_cls.visible_cls-two {
      z-index: 2 !important;
    }
    .svg-parent .keydown-recordfilter {
      padding: 0px 4px;
      margin: 0px 4px;
    }
    .svg-parent {
      display: inline;
      aui-icon::part(icon) {
        margin-top: -3px;
        @media screen and (max-width: 767px) {
          width: 12px;
        }
      }
    }
    .cp-searchinput:nth-of-type(1) {
      width: 28%;
    }
    .common-dropdown {
      width: 22%;
    }
    input.form-control {
      height: 40px !important;
    }
    .cp-searchinput {
      float: left;
      max-width: 350px;

      @media screen and (min-width: 992px) {
        max-width: 100%;
      }
    }
    h1.cp-h2 {
      font-size: 30px;
      margin: 40px 0px 35px 0px;
    }
    .search-icon-cp {
      position: absolute;
      top: 11px;
      right: 11px;
    }
    .cp-mrgnb-20 {
      padding-top: 0px;
      padding-right: 2px;
      box-sizing: border-box;
    }
    .cp-mrgnr-44 {
      margin-right: 3.93%;
    }
    .sub-heads {
      display: none;
    }
    .page-item .page-link {
      padding: 0.625rem 17px;
    }
    .cp-mrgnn1 {
      cursor: pointer;
      padding: 0px;
      font-size: 0px;
      margin-top: 4px;
      span.spanone {
        text-align: center;
        display: none;
        top: -45px;
        left: -115px;
        background: #333;
        display: none;
        padding: 7px 12px;
        width: 135px;
        color: #fff;
        font-size: 12px;
        border-radius: 4px;
      }
    }
    .cp-mrgnn2:hover span.spanone {
      display: block !important;
    }
    .ul-filter-fixed,
    .ul-filter-fixed {
      display: none;
      padding: 15px 12px 20px 12px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      width: 232px;
      right: 0px;
      top: 40px;
      margin-bottom: 0px;
      list-style-type: none;
      z-index: 4;
      li,
      .form-group {
        margin-bottom: 18px;
      }
      div:last-of-type,
      .form-group:last-of-type {
        margin-bottom: 0px;
      }
      label {
        font-size: 14px;
      }
      label:after {
        top: 4px;
        margin-top: 3px;
        left: 3px;
        width: 10px;
        height: 5.5px;
      }
      input,
      label:before {
        height: 16px;
        width: 16px;
        margin-top: 3px;
      }
    }
  }
  .z-index6 {
    z-index: 6;
  }
  .z-index5 {
    z-index: 5;
  }
  .z-index4 {
    z-index: 4;
  }
  .z-index3 {
    z-index: 3;
  }
  .z-index2 {
    z-index: 2;
  }
  .z-index1 {
    z-index: 1;
  }

  .selected-items {
    .selection-no {
      width: 125px;
      padding: 7px 15px;
    }
    input {
      width: 34%;
      border: none;
    }
  }
  .option-count {
    position: absolute;
    right: 45px;
    top: 10px;
    font-size: 15px;
    font-weight: 500;
  }
  .page-sort.sorted-by {
    width: 80px;
  }
  .page-sort {
    list-style-type: none;
    border: 1px solid #e3e3e3;
    padding: 0px;
    background: #ffffff;
    font-size: 12px;
    top: 19px;
    z-index: 6;
    left: -37px;
    width: 46px;
    div.active,
    div:hover {
      color: #c10e21;
    }
    div {
      padding: 7px 9px 5px 9px;
      border-bottom: 1px solid #e3e3e3;
    }
    div:last-of-type {
      border: none;
    }
  }
  h2.cp-h2 {
    font-size: 30px;
    margin: 40px 0px;
  }
  .search-icon-cp {
    position: absolute;
    top: 11px;
    right: 11px;
  }
  .cp-mrgnb-20 {
    margin-bottom: 20px;
  }
  .cp-mrgnr-20 {
    margin-right: 20px;
  }
  .cp-mrgnl-20 {
    margin-left: 20px;
  }
  .sub-heads {
    display: none;
  }
  .page-item .page-link {
    padding: 0.625rem 17px;
  }

  @media only screen and (min-width: 992px) {
    .cp-btm15 {
      padding-bottom: 15.5px;
      vertical-align: bottom !important;
    }
  }
  @media only screen and (max-width: 1199.5px) {
    .selected-items {
      input {
        width: 35%;
      }
      .reset-cls-btn {
        margin: 0px 5px 0px 5px;
      }
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1075.5px) {
    .cp-searchinput:nth-of-type(1) {
      max-width: 300px;
    }
    .selected-items input {
      width: 60px;
    }
    .cp-searchinput:nth-of-type(1) {
      width: 26% !important;
    }
    .common-dropdown {
      width: 24% !important;
      .cp-searchinput:nth-of-type(1) {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
  @media only screen and (max-width: 991.5px) {
    .cp-mrgnb-20 {
      -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
      span.page-number:nth-of-type(1) {
        order: 4;
        margin: 18px auto 10px 0px;
        width: 50%;
      }
      .cp-searchinput:nth-of-type(1) {
        order: 1;
        width: 33%;
        max-width: 33%;
        margin: 0px auto 0px 0px;
        div {
          width: 100%;
        }
      }
      .common-dropdown {
        order: 2;
        max-width: 33%;
        width: 33%;
        margin: 0px auto 0px 0px;
        .cp-searchinput:nth-of-type(1) {
          width: 100% !important;
          max-width: 100% !important;
        }
        .selected-items span.selected-span {
          width: 100% !important;
          margin: 0px;
        }
      }
      .selected-items input {
        width: 35%;
      }
      .cp-mrgnl-20.cp-clsgn {
        order: 3;
        width: 140px !important;
      }
      .cp-mrgnl-15.cp-clsgn.float-right {
        order: 5;
        margin: 18px 0px 10px auto;
        width: 50% !important;
      }
    }
    .cp-mrgnb-20 {
      margin-bottom: 0px;
    }

    .cp-overflow-custwo {
      width: 100%;
      float: left;
    }
    .cp-flex-direction {
      flex-wrap: wrap;
      .cpw-354 {
        width: 354px;
      }
      .cp-mrgnl-20.cp-clsgn {
        text-align: right;
        width: 50%;
        margin-left: 0px;
      }
    }
    .text-center.cptext-left {
      text-align: left !important;
    }
    .cp-p-cusone {
      padding: 20px;
    }

    h2.cp-h2 {
      font-size: 24px;
      margin: 36px 0px 24px 0px;
    }
    .cp-smblock {
      display: block;
      min-height: 62.8px;
      width: 100%;
      float: left;
    }
    .sub-heads {
      display: block !important;
    }
    .cp-responsive-div {
      display: none;
    }
    .cp-w-cusone {
      width: 50%;
      float: left;
    }
    .cm-paginationone {
      width: 305px;
      margin: 0px auto;
      float: none !important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 800px) {
    .cp-mrgnb-20 .selected-items input {
      width: 30%;
    }
  }
  @media only screen and (max-width: 767.5px) {
    .view-report-wrapper h1.cp-h2 {
      font-size: 24px;
      margin: 36px 0px 30px 15px;
    }
    .cp-mrgnl-20.cp-clsgn {
      margin-top: 18px !important;
    }
    .common-dropdown {
      margin-top: 18px !important;
      width: 50% !important;
      max-width: 50% !important;
    }
    .aui-search-dropdown {
      max-width: 100% !important;
      margin: 0px;
    }
    .cpw-354,
    .aui-search-dropdown,
    .cp-mrgnl-20.cp-clsgn,
    .cp-mrgnl-20.cp-clsgn button {
      width: 100% !important;
    }
    .cp-mrgnl-20.cp-clsgn {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 575.5px) {
    .common-dropdown,
    .cp-mrgnb-20 .cp-mrgnl-20.cp-clsgn,
    .cp-searchinput:nth-of-type(1) {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 360px) {
    .page-item .page-link {
      padding: 0.625rem 15px;
    }
    .cm-paginationone {
      width: 277px;
    }
  }

  .filter-nav {
    display: flex;
    flex-direction: row;
    margin: 2% 0;

    .page-number {
      width: 68%;
      @media screen and (max-width: 575px) {
        width: 100%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        width: 36% !important;
        margin-right: 0px;
      }
    }

    .sort-by {
      width: 22%;

      @media screen and (max-width: 575px) {
        width: 50%;
      }

      @media screen and (min-width: 576px) and (max-width: 767px) {
        width: 30%;
      }
    }

    .customize-tabel {
      width: 3%;
    }
  }

  @media screen and (min-width: 992px) {
    .sort-by {
      /* margin-left: 22% !important; */
      width: 27% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .sort-by {
      /* margin-left: 21% !important; */
      width: 22% !important;
    }
  }

  .table-Container {
    // padding: 1%;
    margin-top: 2rem;
  }

  .table-col1 {
    .aui-td {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      div {
        margin: 1%;
      }
    }
  }

  .noStyle-list {
    padding: 1%;
    list-style: none;
  }

  @media screen and (max-width: 991px) {
    .noStyle-list {
      .p-0 {
        padding: 0 !important;
      }
    }
  }

  .invisible-cls {
    opacity: 0;
    z-index: -5;
    left: -9999px;
  }

  .sort-by {
    width: 20%;
  }

  .customize-tabel {
    display: block;
  }

  input,
  select {
    min-height: 40px !important;
  }

  .t-middle {
    @media only screen and (min-width: 992px) {
      padding-left: 0;
      padding-right: 0;
      margin-top: 8px;
      text-align: right;
    }
  }

  .inputs {
    @media only screen and (min-width: 992px) {
      border: 1px solid #bcc3ca;
      background: #fff;
      border-radius: 0;
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: 23px;
      border: 1px solid #bcc3ca;
    }
  }

  #selectReport,
  #selectFrequency,
  #selectVendor,
  #selectAccreditation {
    @media only screen and (min-width: 992px) {
      border: 1px solid #bcc3ca;
      background: #fff;
      border-radius: 0;
    }

    @media only screen and (max-width: 991px) {
      margin-bottom: 23px;
      border: 1px solid #bcc3ca;
    }
  }

  #selectVendor,
  #selectAccreditation {
    border: 0 !important;
  }

  select {
    appearance: none;
    text-align: left;
    justify-content: left;
    padding-left: 10px;
    margin: 1px;
    border: 2px solid rgb(227, 227, 227);
    background: url(../../images/select-arrow.png) calc(100% - 10px) center
      no-repeat white !important;
    background-size: 1rem !important;
    option {
      background: #fff;
    }
  }

  @media only screen and (min-width: 991px) and (max-width: 1071px) {
    .t-middle {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 992px) {
    .selectVendorDiv {
      max-width: 33.33%;
      flex: 0 0 33.33%;
    }
    .selectAccreditationDiv {
      max-width: 33.33%;
      flex: 0 0 33.33%;
    }
    .searchDiv {
      max-width: 66.66%;
      flex: 0 0 66.66%;
    }
    .viewReportDiv {
      .btn {
        float: right;
      }
    }

    .addSchedulerDiv {
      .btn {
        float: right;
      }
    }
  }

  @media only screen and (min-width: 768px) and (min-width: 991px) {
    .viewReportDiv {
      max-width: 37.5%;
      .btn {
        width: 100%;
      }
    }

    .addSchedulerDiv {
      max-width: 37.5%;
      .btn {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .viewReportDiv {
      max-width: 50%;
      .btn {
        width: 100%;
      }
    }

    .addSchedulerDiv {
      max-width: 50%;
      .btn {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .addSchedulerDiv {
      margin-top: 1.5rem;
    }
  }

  .viewReportDiv {
    max-width: 100%;
    .btn {
      width: 100%;
    }
  }

  .addSchedulerDiv {
    max-width: 100%;
    .btn {
      width: 100%;
    }
  }

  #exportType {
    border: 1px solid #bcc3ca;
  }

  .filter-nav {
    display: flex;
    flex-direction: row;
    margin: 2% 0;

    .page-number {
      width: 68%;
      @media screen and (max-width: 575px) {
        width: 100%;
      }
      @media screen and (min-width: 992px) {
        width: 30%;
      }
    }

    .sort-by {
      width: 22%;

      @media screen and (max-width: 575px) {
        width: 50%;
      }

      @media screen and (min-width: 575px) and (max-width: 767px) {
        width: 30%;
      }
    }

    .customize-tabel {
      width: 3%;
    }
  }

  @media screen and (min-width: 992px) {
    .sort-by {
      /*  margin-left: 22% !important; */
      width: 27% !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .sort-by {
      /* margin-left: 21% !important; */
      width: 22% !important;
    }
  }

  .sort-by {
    width: 20%;
  }

  .customize-tabel {
    display: block;
  }

  @media screen and (max-width: 575px) {
    .filerHeader {
      padding: 1%;
      flex-direction: row;
      justify-content: flex-start;
    }

    .filterHeaderChildElement {
      display: flex;
      justify-content: flex-start;
      padding: 1% 0;
      width: 100%;

      > svg {
        width: 12px;
        top: 2px;
        left: 2px;
      }
    }

    .customize-tabel {
      display: flex;
      width: 55%;
      justify-content: flex-end;
    }
  }

  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    padding: 0 !important;
  }

  .multi {
    .list__control {
      &:hover {
        border-color: #777979;
      }
    }
    .list__menu {
      border: 1px solid rgb(234, 234, 234);
      background-color: rgb(255, 255, 255);
      border-radius: 0;
      box-shadow: none;
      outline: none;
      .list__menu-list {
        padding: 0;
        border: 0;
        margin: 0;
        .list__option {
          overflow: hidden;
          color: rgb(34, 35, 40);
          font-size: 14px;
          white-space: wrap;
          word-break: break-word;
          &.list__option--is-disabled {
            opacity: 0.5;
            &:hover {
              background-color: #fff;
            }
          }
          &:hover {
            background-color: rgba(34, 35, 40, 0.05);
          }
        }
      }
    }
    .list__dropdown-indicator svg {
      -webkit-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      overflow: hidden;
    }
    .list__control--menu-is-open {
      .list__dropdown-indicator svg {
        transform: rotate(-180deg);
        -webkit-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
    [class*="-menu"] {
      input[type="checkbox"] {
        display: none;

        + label {
          margin-top: 8px;
          display: inline-block;
          margin-left: 8px;
          width: 90%;
        }
      }
    }

    .list__multi-value {
      border-radius: 0.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      padding-inline: 0.5rem;
      gap: 0.25rem;
      background-color: rgba(193, 14, 33, 0.1);
    }

    .list__multi-value__label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
      color: rgb(193, 14, 33);
      padding-inline: 0.5rem;
      background: transparent;
      border: none;
    }

    .list__multi-value__remove {
      padding: 0px;
      background-color: transparent;
      border: none;
      svg {
        fill: rgb(34, 35, 40);
        stroke: #fff;
        stroke-width: 0.4px;
      }
      &:hover {
        color: #222328;
      }
    }

    input:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }

    [class*="control"] {
      [class*="placeholder"] {
        color: #222328;
      }
      [class*="indicatorContainer"] {
        color: #6b6b6b;
        position: sticky;
        top: 19px;
        bottom: 18px;
        > svg {
          width: 25px;
          height: 25px;
          stroke: #fff;
          stroke-width: 0.8px;
        }
      }

      [class*="indicatorSeparator"] {
        display: none;
      }
    }
  }

  input,
  select[placeholder],
  [aria-placeholder],
  span[aria-placeholder] {
    color: #6d6e71;
  }
  .selectReportFormat {
    width: 25% !important;
  }

  .view-report {
    flex-direction: row;
    display: flex;
    margin: 2% 0;
    width: 100%;
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  @media screen and (max-width: 575px) {
    .exportType-title {
      font-weight: 500;
      width: 24%;
    }
    .selectReportFormat {
      margin-left: 2%;
      width: 74% !important;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .exportType-title {
      font-weight: 500;
      width: 15%;
    }
    .selectReportFormat {
      margin-left: 0.5rem;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .exportType-title {
      font-weight: 500;
      width: 12%;
    }
    .selectReportFormat {
      margin-left: 0.5rem;
    }
  }

  @media screen and (min-width: 992px) {
    .exportType-title {
      font-weight: 500;
      width: 15%;
      margin-top: 0rem;
      margin-right: 1rem;
    }

    .filter-nav {
      height: 3rem;
    }
  }

  #customizetabletablelist {
    max-height: 15rem;
    overflow-y: auto;
  }

  .downloadModaltitle {
    text-align: left;
    padding-left: 0.5rem;
    font-size: 17px;
  }

  .downloadwrapper {
    img {
      height: 20vh;
    }
    .btn {
      @media screen and (max-width: 575px) {
        padding: 0.5rem 1rem;
        width: 90%;
      }
    }
  }

  .modal-backdrop {
    width: 100% !important;
    height: 100% !important;
  }

  .sort-by {
    pointer-events: none;
    opacity: 0.7;
  }

  .showModal {
    display: block !important;
  }

  @media only screen and (min-width: 992px) {
    .sidenav.left-nav-bar + div {
      overflow: hidden !important;
    }

    div.sort-by {
      text-align: right !important;
    }
  }
  .container {
    min-height: 100vh;
  }
  .bdr-btm {
    border-bottom: 1px solid #bcc3ca;
    margin: 1rem;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }

  .nav-pagination-div {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    .pagination-block {
      align-items: center;
      margin-bottom: 0px;
      .pb3,
      .mb-5,
      .mt-4 {
        margin-bottom: 0px !important;
      }
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      justify-content: flex-end;
    }
    @media screen and (max-width: 575px) {
      width: 100%;
      justify-content: center;
      margin-left: 0;
    }
  }

  .table-responsive,
  .scroll-bar-wrapper {
    overflow-x: scroll;
  }
  .scroll-bar-wrapper {
    overflow-y: hidden;
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    table {
      white-space: normal;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 575px) {
    .filter-nav .sort-by {
      width: 70%;
    }
  }

  .custom-report-table {
    overflow: auto;
  }
  .arrow_btn {
    position: relative;
    padding-left: 12px;
    &:before {
      position: absolute;
      content: "";
      border: solid #c10e21;
      border-width: 0 0.125rem 0.125rem 0;
      display: inline-block;
      padding: 0.1875rem;
      top: 0.5625rem;
      left: 0;
      transform: rotate(-225deg);
      -webkit-transform: rotate(-225deg);
      @media screen and (max-width: 575px) {
        top: 0.7625rem;
      }
    }
  }
`;

import styled from "styled-components";

export const PageHeadingWrapper = styled.div`
  margin-right: auto;
  .h2-class {
    margin-bottom: 12px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
    font-size: 1.7rem;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

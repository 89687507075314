export const AccreditationCodes = {
  CAPCE: "CAPCE / CECBEMS",
  AMA_PRA_CAT_1 :"AMA PRA Category 1",
  AARC: "AARC",
  AOTA: "AOTA",
  CDR: "CDR",
  AHA: "AHA",
  AAFP: "AAFP",
  ACPE: "ACPE"
} as const;

export const AccreditationCodeNumbers = {
  certificateOfCredit: "1",
  certificateOfAttendance: "2",
  CAPCE: "6",
  ANCC: "55",
  AAFP: "57",

}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccreditationCodes =
  typeof AccreditationCodes[keyof typeof AccreditationCodes];

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ActivitiesState {
  pageNumber: number;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  search: string;
  filterByVendor: string;
  filterByDate: string;
  reFetch: boolean;
}

export const initialState: ActivitiesState = {
  pageNumber: 1,
  pageSize: 25,
  loader: false,
  pageOrder: "id DESC",
  search: "",
  filterByVendor: "",
  filterByDate: "",
  reFetch: false,
};

const vendorManagementSlice = createSlice({
  name: "listActivity",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ActivitiesState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    setPageSize: (state, action: PayloadAction<ActivitiesState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    searchActivity: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload;
    },
    filterByVendor: (state, action: PayloadAction<string>) => {
      state.filterByVendor = action.payload;
    },
    filterBydate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    toggleReFetch: (state, action: PayloadAction<boolean>) => {
      state.pageNumber = 1;
      state.reFetch = !state.reFetch;
    },
    clearState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = vendorManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  setPageSize,
  clearState,
  searchActivity,
  filterByVendor,
  filterBydate,
  toggleReFetch,
} = actions;
export default reducer;

import React from "react";
import { useEffect } from "react";
import BreadcrumbsWrapper from "./styled";

const Breadcrumb = (props: any) => {
  const getValue = async (event: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    document
      .querySelector("aui-breadcrumb")
      ?.addEventListener("auiBreadcrumbChange", getValue);

    return () => {
      document
        .querySelector("aui-breadcrumb")
        ?.removeEventListener("auiBreadcrumbChange", getValue);
    };
  }, []);

  return (
    <BreadcrumbsWrapper className="pl-0 mt-3 pb-1">
      <aui-breadcrumb
        currentpage={props.currentpage}
        previouspages={props.previouspages}
      ></aui-breadcrumb>
    </BreadcrumbsWrapper>
  );
};

export default Breadcrumb;

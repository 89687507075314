import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

export const AdminFooter: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <aui-footer footertype="secondary">
      <footer className="aui-footer-secondary py-3 mt-3">
        <div className="container">
          <div className="aui-footer-sec-inner text-white text-lg-left text-center">
            <p className="aui-font-14 mb-1">
              <span>{t<string>("nationalCenter")}, </span>
              <span>{t<string>("adminFooterAddr2")} </span>
              <span> | </span>
              <span>{t<string>("customerService")}: </span>
              <span>{t<string>("adminFooterAddr3")}</span>
            </p>
            <div className="dropdown-divider d-lg-none d-block my-3"></div>
            <p className="aui-font-14  mb-0">
              &copy;2020 {t<string>("adminFooterAddr4")}
            </p>
          </div>
        </div>
      </footer>
    </aui-footer>
  );
};
export default AdminFooter;
